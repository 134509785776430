import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { IoMdClose } from 'react-icons/io'
// Images 
import box1 from '../Images/box1.png'
import box2 from '../Images/box2.png'
import box3 from '../Images/box3.png'
import box4 from '../Images/box4.png'
import box5 from '../Images/box5.png'
import box6 from '../Images/box6.png'
import box7 from '../Images/box7.png'
import box8 from '../Images/box8.png'
import '../App.css'

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectCube, Pagination } from 'swiper/core';
SwiperCore.use([EffectCube, Pagination]);

const Tute = styled.div`
   display: flex;
   position: fixed;
   justify-content: center;
   align-items: center;
   top: 0;
   height: 100%;
   width: 100%;
   background: #000000ab;
   z-index: +5;
`

const close = {
    position: 'absolute',
    right: ' 2%',
    top: '2%',
    color: 'white',
    cursor: 'pointer',
}

const swiperBox = {
    width: '22rem',
    height: '22rem',
}
const swiperBoxImg = {
    width: '100%',
    height: '100%',
}
const swiperBoxImgDiv = {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    background: 'white',
    borderRadius: '8px',
}

const closeBtn = {
    position: 'absolute',
    bottom: '23%',
    padding: '5px 20px',
    border: 'none',
    background: 'green',
    color: 'white',
    borderRadius: '3px',
    fontFamily: 'Manrope',
    fontSize: ' 16px',
    cursor: 'pointer',
}


// How to write article tutorial 
function Tutorial(props) {

    const closeTutePanel = () => {
        props.closeTutePanel(false)
    }

    const closeSwiperBox = () => {
        props.closeTutePanel(false)
    }

    return (
        <Tute className="Tute">
            <IoMdClose onClick={() => closeTutePanel()} style={close} size={24} />
            <Swiper
                effect={"cube"}
                grabCursor={true}
                cubeEffect={{
                    shadow: true,
                    slideShadows: true,
                    shadowOffset: 20,
                    shadowScale: 0.94,
                }}
                modules={[EffectCube, Pagination]}
                className="mySwiper"
                style={swiperBox}>
                <SwiperSlide>
                    <div style={swiperBoxImgDiv}>
                        <img src={box1} style={swiperBoxImg} />

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={swiperBoxImgDiv}>
                        <img src={box2} style={swiperBoxImg} />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={swiperBoxImgDiv}>
                        <img src={box3} style={swiperBoxImg} />

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={swiperBoxImgDiv}>
                        <img src={box4} style={swiperBoxImg} />

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={swiperBoxImgDiv}>
                        <img src={box5} style={swiperBoxImg} />

                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={swiperBoxImgDiv}>
                        <img src={box6} style={swiperBoxImg} />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={swiperBoxImgDiv}>
                        <img src={box7} style={swiperBoxImg} />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={swiperBoxImgDiv}>
                        <img src={box8} style={swiperBoxImg} />
                        <button style={closeBtn} onClick={() => closeSwiperBox()}>Close</button>
                    </div>
                </SwiperSlide>
            </Swiper>
        </Tute>
    )
}


export default Tutorial;