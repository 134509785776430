import React, { useEffect, useState, useRef } from "react";
import NewComments from "./NewComment";
import axios from "axios";
import date from 'date-and-time';
import styled from "styled-components";
import { keyframes } from 'styled-components'
import { Editor } from '@tinymce/tinymce-react'
import Loader from '../subComponents/Loader'
import { ScaleLoader } from "react-spinners";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { alertActive } from '../Redux'
import { alertDeactive } from '../Redux'
import removeUndefinedObjects from 'remove-undefined-objects';


const breatheAnimation = keyframes`
    0% { opacity: 0;}
    100% { opacity: 1; }
`

const CommentBox = styled.div`
    margin: 8px 0;
    padding: 16px;
    background: #f9f9f9;
    border-radius: 8px;
    overflow: hidden;
    opacity: 0;

    animation-name: ${breatheAnimation};
    animation-duration: 1s;
    animation-fill-mode: forwards;
`



const button = {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    fontSize: '15px',
    border: 'none',
    background: '#29351e',
    color: 'white',
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    cursor: 'pointer',
    margin: '8px 0',
    borderRadius: '5px',
}

function Comments(props) {
    const now = new Date();
    const editorRef = useRef()
    const [textEditor, setTextEditor] = useState(true);
    // editordata for comment 
    const [editorData, setEditorData] = useState();
    const articleId = props.articleId;
    const token = sessionStorage.getItem('token')
    const [comments, setComments] = useState()
    const [loader, setLoader] = useState('')
    const [refresh, setRefresh] = useState(Math.random())
    const dispatch = useDispatch()
    //
    const [showReplyBox, setShowReplyBox] = useState(false)
    const [showEditBox, setShowEditBox] = useState(false)

    // Submit commnet 
    const submitComment = () => {
        setLoader('comment')
        axios.post(process.env.REACT_APP_URL_API + '/comment',
            {
                id: new Date().getTime(),
                articleId: articleId,
                comment: editorData,
                date: date.format(now, 'DD MMM YYYY'),
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                setTextEditor(false)
                setEditorData('')
                setLoader('')
                setRefresh(Math.random())
                dispatch(alertActive('success', res.data.result))
                setTimeout(() => {
                    setTextEditor(true)
                }, 0)
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)
            }).catch(err => {
                setLoader('')
                dispatch(alertActive('error', err.response.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)
            })
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL_API + '/comments/' + articleId)
            .then(res => {
                const commentData = []
                res.data.result.Comments.map(val => {
                    commentData.unshift(val)
                })
                const modifiedData = removeUndefinedObjects(Object.assign({}, commentData))
                setComments(Object.assign([], modifiedData))
            }).catch(err => {
                console.log(err)
            })
    }, [refresh])

    const addReply = (commentID, replyText) => {
        axios.post(process.env.REACT_APP_URL_API + '/reply_to_comment', {
            id: new Date().getTime(),
            articleId: articleId,
            commentID: commentID,
            replyText: replyText,
            date: date.format(now, 'DD MMM YYYY'),
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            setShowReplyBox(false)
            setRefresh(Math.random())
            dispatch(alertActive('success', res.data.result))
            setTimeout(() => {
                dispatch(alertDeactive())
            }, 2000)
        }).catch(err => {
            dispatch(alertActive('error', err.response.data.result))
            setTimeout(() => {
                dispatch(alertDeactive())
            }, 3000)
        })

    }

    const Editorplugins = [' image charmap ', 'searchreplace visualblocks code fullscreen',
        'insertdatetime paste codesample code help wordcount'
    ]
    const EditorToolbar = 'undo redo | alignleft  aligncenter alignright |' +
        'bold italic forecolor  backcolor |' +
        'bullist numlist| ' +
        'removeformat |' +
        'pagebreak | charmap emoticons | fullscreen | ' +
        'insertfile image template codesample | ltr rtl'


    const removeComment = (id) => {
        setLoader('removeComment')
        axios.post(process.env.REACT_APP_URL_API + '/remove_comment', {
            articleId: props.articleId,
            commentId: id,
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setLoader('')
                setRefresh(Math.random())
                dispatch(alertActive('success', res.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 2000)
            }).catch(err => {
                setLoader('')
                dispatch(alertActive('error', err.response.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)
            })
    }

    const editComment = (data) => {
        setLoader('editComment')
        const commentText = data.commentText;
        const id = data.id;
        axios.post(process.env.REACT_APP_URL_API + '/edit_comment', {
            articleId: articleId,
            commentId: id,
            commentText: commentText
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setLoader('')
                setShowEditBox(false)
                setRefresh(Math.random())
                dispatch(alertActive('success', res.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 2000)
            }).catch(err => {
                setLoader('')
                dispatch(alertActive('error', err.response.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)
            })
    }


    return (
        <>
            {textEditor &&
                <Editor
                    apiKey="me91nhwamlrdq769kp9dcmpt4i7lrqodz6eyswsjpxevphss"
                    onInit={(evt, editor) => editorRef.current = editor}
                    init={{
                        height: '16rem',
                        menubar: false,
                        statusbar: false,
                        branding: false,
                        skin: 'fabric',
                        icons: "thin",
                        placeholder: 'Write your comment.',
                        inline_boundaries: true,
                        plugins: Editorplugins,
                        toolbar: EditorToolbar,
                        content_style: "@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');body {font - family:'Manrope', sans-serif; font-size: 15px; line-height: 0.7;}",
                        font_formats: "'Manrope', sans-serif;",
                    }}
                    onChange={() => setEditorData(editorRef.current.getContent())}
                />
            }
            <button style={button} onClick={() => submitComment()} className='btnHover'> {loader == 'comment' ? <Loader /> : <> LEAVE A COMMENT </>}</button>

            {comments && comments.map(val => (
                <CommentBox key={Math.random() * 10000}>
                    <NewComments comment={val} addReply={addReply} removeComment={removeComment} editComment={editComment} showReplyBox={showReplyBox} showEditBox={showEditBox} loader={loader} />
                </CommentBox>
            ))}
        </>
    )
}

export default Comments;