import React, { useState, useEffect } from "react";
import styled from 'styled-components'
import { Routes, Route, NavLink } from "react-router-dom";
import { SlHome } from 'react-icons/sl'
import { BsBookmarkStar } from 'react-icons/bs'
import { BsFillRecordFill } from 'react-icons/bs'
import { FaUserCircle } from 'react-icons/fa'



const Nav = styled.div`
  display: flex;
  border: 1px solid #e1e0e0;
  font-family: Manrope;
  background: white;
  justify-content: center;
  overflow: hidden;
  overflow-x: auto;
  position: fixed;
  width: 100%;
  top: 4rem;
  z-index: +3;
  height: 56px;
  align-items: center;
  transition: 0.3s all;

  @media (max-width: 1140px){
    justify-content: flex-start;
  }
  @media (max-width: 1140px){
    justify-content: left;
    padding-left: 8px;
  }
  @media (max-width: 650px){
    justify-content: left;
    height: 48px;
    font-size: 12px;
    top: 3rem;
  }
  
`

const MenuItem = styled.span`
   font-size: 14px;

   @media (max-width: 420px){
    font-size: 12px;
  }
`

const navlink = {
    display: 'flex',
    color: 'black',
    padding: '10px 20px',
    textDecoration: 'none',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    position: 'relative',
    fontSize: '14px',
    fontWeight: 500,
}

const Dot = styled.span`
     position: absolute;
     left: 5px;
`


function NavMenu() {
    const [scrollWin, setScrollWin] = useState(0)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const username = sessionStorage.getItem('username')
    const dot = sessionStorage.getItem('activeMenu')

    const ActiveMenu = (activeMenu) => {
        if (activeMenu)
            sessionStorage.setItem('activeMenu', activeMenu)
        else
            sessionStorage.setItem('activeMenu', '')
    }
    useEffect(() => {

    }, [ActiveMenu])

    window.addEventListener('resize', () => {
        setScreenWidth(window.innerWidth)
    })

    // hiding Nav menu on scrolling page 
    window.addEventListener('scroll', () => {
        if (window.scrollY >= scrollWin) {
            setScrollWin(window.scrollY);
            document.querySelector('.Nav').style.top = '0rem';
            document.querySelector('.Nav').style.borderBottom = 'none';
        }
        if (window.scrollY < scrollWin) {
            document.querySelector('.Nav').style.borderBottom = '1px solid #e1e0e0';
            if (screenWidth < 650) {
                document.querySelector('.Nav').style.top = '3rem';
            } else {
                document.querySelector('.Nav').style.top = '4rem';
            }
        }

    })

    return (
        <Nav className="Nav">
            <>
                <NavLink to="/" onClick={() => ActiveMenu('home')} style={({ isActive }) =>
                    isActive ? { ...navlink, fontWeight: 'bold', background: '#102300', color: "white", borderRadius: '5rem' } : { ...navlink, color: "black", borderRadius: '5rem' }
                } >
                    <SlHome size={screenWidth <= 420 ? 16 : 18} />
                </NavLink>
                {username &&
                    <NavLink to="/profile" onClick={() => ActiveMenu('profile')} style={({ isActive }) =>
                        isActive ? { ...navlink, fontWeight: 'bold', borderLeft: 'none' } : { ...navlink, borderLeft: 'none' }
                    } >
                        <FaUserCircle size={20} style={dot == 'profile' && { fill: 'green' }} />
                    </NavLink>
                }
                {
                    username &&
                    <NavLink to="/fav" onClick={() => ActiveMenu('fav')} style={({ isActive }) =>
                        isActive ? { ...navlink, borderLeft: 'none' } : { ...navlink, color: 'black', borderLeft: 'none' }
                    } >
                        {dot == 'fav' && <Dot> <BsFillRecordFill size={12} color="#00b407" /></Dot>}
                        <BsBookmarkStar size={16} style={dot == 'fav' && { strokeWidth: '0.5' }} />
                    </NavLink>
                }
                <NavLink to="/tech" onClick={() => ActiveMenu('tech')} style={({ isActive }) =>
                    isActive ? { ...navlink, fontWeight: 'bold', } : { ...navlink, }
                } >
                    {dot == 'tech' && <Dot> <BsFillRecordFill size={12} color="#00b407" /></Dot>}
                    <MenuItem> Tech</MenuItem>
                </NavLink>

                <NavLink to="/web" onClick={() => ActiveMenu('web')} style={({ isActive }) =>
                    isActive ? { ...navlink, fontWeight: 'bold' } : navlink
                }>
                    {dot == 'web' && <Dot> <BsFillRecordFill size={12} color="#00b407" /></Dot>}
                    <MenuItem> Web</MenuItem>
                </NavLink>

                <NavLink to="/programming" onClick={() => ActiveMenu('programming')} style={({ isActive }) =>
                    isActive ? { ...navlink, fontWeight: 'bold' } : navlink
                } >
                    {dot == 'programming' && <Dot> <BsFillRecordFill size={12} color="#00b407" /></Dot>}
                    <MenuItem>Programming</MenuItem>
                </NavLink>

                <NavLink to="/photography" onClick={() => ActiveMenu('photography')} style={({ isActive }) =>
                    isActive ? { ...navlink, fontWeight: 'bold' } : navlink
                } >
                    {dot == 'photography' && <Dot> <BsFillRecordFill size={12} color="#00b407" /></Dot>}
                    <MenuItem>Photography</MenuItem>
                </NavLink>

                <NavLink to="/food" onClick={() => ActiveMenu('food')} style={({ isActive }) =>
                    isActive ? { ...navlink, fontWeight: 'bold' } : navlink
                } >
                    {dot == 'food' && <Dot> <BsFillRecordFill size={12} color="#00b407" /></Dot>}
                    <MenuItem>Food</MenuItem>
                </NavLink>

                <NavLink to="/fitness" onClick={() => ActiveMenu('fitness')} style={({ isActive }) =>
                    isActive ? { ...navlink, fontWeight: 'bold' } : navlink
                } >
                    {dot == 'fitness' && <Dot> <BsFillRecordFill size={12} color="#00b407" /></Dot>}
                    <MenuItem>Fitness</MenuItem>
                </NavLink>
                <NavLink to="/travel" onClick={() => ActiveMenu('travel')} style={({ isActive }) =>
                    isActive ? { ...navlink, fontWeight: 'bold' } : navlink
                } >
                    {dot == 'travel' && <Dot> <BsFillRecordFill size={12} color="#00b407" /></Dot>}
                    <MenuItem>Travel</MenuItem>
                </NavLink>
                <NavLink to="/health" onClick={() => ActiveMenu('health')} style={({ isActive }) =>
                    isActive ? { ...navlink, fontWeight: 'bold' } : navlink
                } >
                    {dot == 'health' && <Dot> <BsFillRecordFill size={12} color="#00b407" /></Dot>}
                    <MenuItem>Health</MenuItem>
                </NavLink>
                <NavLink to="/fashion" onClick={() => ActiveMenu('fashion')} style={({ isActive }) =>
                    isActive ? { ...navlink, fontWeight: 'bold' } : navlink
                } >
                    {dot == 'fashion' && <Dot> <BsFillRecordFill size={12} color="#00b407" /></Dot>}
                    <MenuItem>Fashion</MenuItem>
                </NavLink>
                <NavLink to="/sport" onClick={() => ActiveMenu('sport')} style={({ isActive }) =>
                    isActive ? { ...navlink, fontWeight: 'bold' } : navlink
                } >
                    {dot == 'sport' && <Dot> <BsFillRecordFill size={12} color="#00b407" /></Dot>}
                    <MenuItem>Sports</MenuItem>
                </NavLink>
            </>
        </Nav>
    )
}

export default NavMenu;