import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { IoMdClose } from 'react-icons/io'
import { FiChevronsRight } from 'react-icons/fi'
import profile from '../Images/profile.png'
import axios from 'axios'
import Alert from '../subComponents/Alert'
import Loader from '../subComponents/Loader'
import '../App.css'
//Redux
import { useDispatch } from "react-redux";
import { alertActive } from '../Redux'
import { alertDeactive } from '../Redux'


const breatheAnimation = keyframes`
    0% { margin-left: 10rem }
    100% {  margin-left: 0rem  }
`

const Form = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    color: #102300;
    width: 20rem;
    padding: 2rem 1rem;
    position: absolute;
    font-family: Manrope;
    border-radius: 5px;
    animation-name: ${breatheAnimation};
    animation-duration: 0.5s;
    animation-iteration-count: normal;

`
const img = {
    width: '3rem',
    borderRadius: '5rem',
    position: 'absolute',
    right: '2%',

}
const input = {
    width: '100%',
    padding: '10px',
    fontFamily: 'Manrope',
    outline: 'none',
    border: '1px solid gray',
    borderRadius: '4px',
}

const button = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    background: '#102300',
    color: 'white',
    fontSize: '15px',
    margin: '10px 0',
    cursor: 'pointer',
    fontFamily: 'Manrope',
    letterSpacing: '0.5px',
    border: 'none',
    borderRadius: '4px',
}

function ForgotPass(props) {

    const [forgotForm, setForgotForm] = useState(true);
    const [email, setEmail] = useState();
    const [otpForm, setOtpForm] = useState(false);
    const [OTP, setOTP] = useState();
    const [openReset, setOpenReset] = useState(false)
    const [newPass, setNewPass] = useState();
    const [confirmationCode, setconfirmationCode] = useState();
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch()

    const createAccount = () => {
        props.createAccount(false);
    }

  
    const sendMail = () => {
        if (email) {
            setLoader(true)
            axios.post(process.env.REACT_APP_URL_API + '/account/forgot', {
                email: email
            })
                .then(res => {
                    setLoader(false)
                    setForgotForm(false)
                    setOtpForm(true)
                    dispatch(alertActive('success', res.data.result))
                    setTimeout(() => {
                        dispatch(alertDeactive())
                    }, 3000)
                })
                .catch(err => {
                    setLoader(false)
                    dispatch(alertActive('error', err.response.data.result))
                    setTimeout(() => {
                        dispatch(alertDeactive())
                    }, 3000)
                })
        } else {
            dispatch(alertActive('error', 'Not valid email'))
            setTimeout(() => {
                dispatch(alertDeactive())
            }, 4000)
        }
    }

    const validateOTP = () => {

        if (OTP.length === 6) {
            setLoader(true)
            axios.post(process.env.REACT_APP_URL_API + '/account/validateOTP', {
                email: email,
                otp: OTP,
            })
                .then(res => {
                    setLoader(false)
                    setForgotForm(false)
                    setOtpForm(false)
                    setOpenReset(true)
                    dispatch(alertActive('success', res.data.result))
                    setTimeout(() => {
                        dispatch(alertDeactive())
                    }, 3000)
                })
                .catch((err) => {
                    setLoader(false)
                    dispatch(alertActive('error', err.response.data.result))
                    setTimeout(() => {
                        dispatch(alertDeactive())
                    }, 3000)
                })
        }
        else {
            dispatch(alertActive('error', 'OTP must be in 6 degit number'))
            setTimeout(() => {
                dispatch(alertDeactive())
            }, 4000)
        }
    }

    // Reset New Pass :

    const updateNewPass = () => {
        if (OTP.length == 6) {
            setLoader(true)
            axios.post(process.env.REACT_APP_URL_API + '/account/updatepass', {
                email: email,
                newpassword: newPass,
                confirmationcode: confirmationCode,
            })
                .then(res => {
                    setLoader(false)
                    setForgotForm(false)
                    setOtpForm(false)
                    dispatch(alertActive('success', res.data.result))
                    setTimeout(() => {
                        dispatch(alertDeactive())
                        window.location.reload();
                    }, 3000)
                })
                .catch((err) => {
                    setLoader(false)

                    dispatch(alertActive('error', err.response.data.result))
                    setTimeout(() => {
                        dispatch(alertDeactive())
                    }, 3000)
                })
        }
        else {
            dispatch(alertActive('error', 'Invalid confirmation Code.'))
            setTimeout(() => {
                dispatch(alertDeactive())
            }, 4000)
        }
    }

    // SHOW OR HIDE PASSWORD 
    const showPassword = (e) => {
        if (e.target.checked) {
            document.getElementById('N-password').type = 'text';
        } else {
            document.getElementById('N-password').type = 'password';
        }
    }

    // make space in OTP input
    const makeSpaceInCode = () => {
        document.getElementById('ccInput').style.letterSpacing = '5px'
    }


    return (
        <>
            {forgotForm ?
                <Form >
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative', }}>
                        <h1 style={{ width: '10rem', margin: 0, fontSize: '25px', lineHeight: '1' }}>Forgot Password ?</h1>
                        <img style={img} src={profile} alt="profile" />
                    </div>
                    <span style={{ color: 'gray', fontSize: '12px', margin: '10px 0' }}>Enter the email address associated with your account.</span>
                    <div style={{ display: 'flex', width: '100%', marginTop: '2rem', fontFamily: 'Manrope' }}>
                        <input style={input} name='email' type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value
                        )} />
                    </div>
                    <span style={{
                        display: 'flex',
                        margin: '10px 2px',
                        justifyContent: 'right',
                        color: 'gray',
                        fontSize: '12px',
                    }}>Click Next</span>
                    <button style={button} onClick={sendMail}>{loader ? <Loader /> : <span style={{ display: 'flex', alignItems: 'center' }}> Next <FiChevronsRight size={24} /></span>}</button>
                    <span style={{
                        textAlign: 'center',
                        color: 'gray',
                        width: ' 90%',
                        margin: '1rem',
                        fontSize: '12px',
                    }}>You will get the OTP in your email. Click on Next and enter your OTP to validate.</span>
                    <IoMdClose size={20} onClick={() => createAccount()} style={{
                        position: 'absolute',
                        right: '2%',
                        top: '2%',
                        cursor: 'pointer',
                        color: 'gray',
                    }} />
                </Form> : ''
            }
            {otpForm ?
                <Form >
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative', }}>
                        <h1 style={{ width: '10rem', margin: 0, fontSize: '25px', lineHeight: '1' }}>Forgot Password ?</h1>
                        <img style={img} src={profile} alt="profile" />
                    </div>
                    <span style={{ color: 'gray', fontSize: '12px', margin: '10px 0' }}>Enter the OTP sent to your given email address and start resetting your details.</span>
                    <div style={{ display: 'flex', width: '100%', marginTop: '2rem', fontFamily: 'Manrope' }}>
                        <input id='OTP' style={{ ...input, letterSpacing: '5px' }} type="text" placeholder="Enter OTP" onChange={(e) => setOTP(e.target.value
                        )} />
                    </div>
                    <span style={{
                        display: 'flex',
                        margin: '10px 2px',
                        justifyContent: 'right',
                        color: 'gray',
                        fontSize: '12px',
                    }}>Check your inbox</span>
                    <button style={button} onClick={validateOTP}>{loader ? <Loader /> : <span style={{ display: 'flex', alignItems: 'center' }}> Next <FiChevronsRight size={24} /></span>}</button>
                    <span style={{
                        textAlign: 'center',
                        color: 'gray',
                        width: ' 90%',
                        margin: '1rem',
                        fontSize: '12px',
                    }}> Provide the correct OTP within 5 minutes after submitting the details. </span>
                    <IoMdClose size={20} onClick={() => createAccount()} style={{
                        position: 'absolute',
                        right: '2%',
                        top: '2%',
                        cursor: 'pointer',
                        color: 'gray',
                    }} />
                </Form>
                : ''}
            {openReset ?
                <Form >
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'relative', }}>
                        <h1 style={{ width: '10rem', margin: 0, fontSize: '25px', lineHeight: '1' }}>Reset
                            Credentials.</h1>
                        <img style={img} src={profile} alt="profile" />
                    </div>
                    <span style={{ color: 'gray', fontSize: '12px', margin: '10px 0' }}>New credentials will be applied after resetting the new password.
                    </span>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '1rem', fontFamily: 'Manrope' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input id='N-password' style={{ width: '100%', margin: '10px 0', padding: '10px', outline: 'none' }} type="password" placeholder="New Password" onChange={(e) => setNewPass(e.target.value
                            )} />
                            <input type='checkbox' style={{ position: 'absolute', right: '5%' }} onChange={showPassword} />
                        </div>
                        <input id='ccInput' style={{ margin: '10px 0', padding: '10px', outline: 'none' }} type="text" placeholder="Confirmation code (cc)" onChange={(e) => setconfirmationCode(e.target.value)} onKeyDownCapture={() => makeSpaceInCode()} />
                    </div>
                    <span style={{ display: 'flex', fontSize: '10px', color: 'gray', justifyContent: 'right', }}>For cc, check your email.</span>
                    <button style={button} onClick={updateNewPass}>{loader ? <Loader /> : <>Update</>}</button>
                    <span style={{
                        textAlign: 'center',
                        color: 'gray',
                        width: ' 90%',
                        margin: '1rem',
                        fontSize: '12px'
                    }}> For increased security, use a strong password. </span>
                    <IoMdClose size={20} onClick={() => createAccount()} style={{
                        position: 'absolute',
                        right: '2%',
                        top: '2%',
                        cursor: 'pointer',
                        color: 'gray',
                    }} />
                </Form>
                : ''}
        </>
    )
}

export default ForgotPass;