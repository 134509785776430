import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import axios from 'axios';
import Alert from '../subComponents/Alert'
import date from 'date-and-time';
import Loader from '../subComponents/Loader'
import ScreenSizeDetector from 'screen-size-detector';
import profileImg from '../Images/profile.png'
import { Country, State, City } from 'country-state-city';
import '../App.css'
import { BsCircleFill } from 'react-icons/bs'
import { MdClose } from 'react-icons/md'
import { RiLockPasswordLine } from 'react-icons/ri'
import { IoArrowBackSharp } from 'react-icons/io5'
import { LuAlertTriangle } from 'react-icons/lu'


const RemoveAccountWindow = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: #00000099;
   position: fixed;
   top: 0;
   width: 100%;
   height: 100%;
   font-family: Manrope;
   z-index: +4;
`

const Options = styled.div`
   display: flex;
   margin-top: 8px;
   padding: 0;

   @media (max-width: 420px){
     flex-direction: column;
   }
   
`
const RemoveAccountWindowDiv = styled.div`
    left: 0%;
    position: relative;
    background: white;
    max-width: 24rem;
    border-radius: 5px;
    padding: 48px 40px;
    transition: 0.5s all;
    overflow: hidden;
    overflow-y: auto;

    @media (max-width: 420px){
        padding: 40px 24px; 
    }
`
const Note = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 8px
`
const Question = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px 24px
`
const Btn = {
    marginTop: '32px',
    padding: ' 8px 24px',
    fontSize: '16px',
    fontFamily: 'Manrope',
    border: 'none',
    borderRadius: '5px',
    background: '#102300',
    color: 'white',
    cursor: 'pointer',
    width: '100%',
}
const H3 = {
    margin: '8px 0',
    fontSize: '14px',
}

const textArea = {
    resize: 'none',
    height: '5rem',
    padding: '8px',
    fontFamily: 'Manrope',
    outline: 'none',
    border: '2px solid #506145',
    borderRadius: '5px',
}
const WindowHeader = styled.div`
    display: flex;
`
const CloseDiv = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 5px;
    top: 2%;
    right: 2%;
    font-size: 10px;
    color: gray;
    cursor: pointer;

`

const CheckPass = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    position: absolute;
    padding: 32px;
    border-radius: 5px;

    @media (max-width: 420px){
        padding: 32px 16px;
    }
`
const input = {
    padding: '8px 0',
    margin: '8px 0',
    fontFamily: 'Manrope',
    width: '20rem',
    fontSize: '14px',
    border: 'none',
    borderBottom: '1px solid gray',
    outline: 'none',
}

const formBtn = {
    display: 'flex',
    justifyContent: 'center',
    padding: '8px',
    marginTop: '16px',
    fontFamily: 'Manrope',
    border: 'none',
    background: '#29351e',
    color: 'white',
    fontWeight: 'bold',
    cursor: 'pointer',
    border: '1px solid #29351e',
}

function RemoveAccount(props) {

    const username = sessionStorage.getItem('username')
    const token = sessionStorage.getItem('token')

    const [alertCall, setAlertCall] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('');

    const [rmArticlesOpt, setRmArticlesOpt] = useState(false)
    const [unsubscribe, setUnsubscribe] = useState(false)
    const [opinion, setOpinion] = useState('')
    const [agree, setAgree] = useState(false)

    const [checkPass, setCheckPass] = useState(false)

    const [loader, setLoader] = useState(false)

    const closeRemoveAccountDiv = () => {
        props.RemoveAccountDiv(false)
    }

    const proceedRemove = () => {
        const now = new Date();
        let password = document.getElementById('password').value;
        let cc = document.getElementById('cc').value

        setLoader(true)

        axios.post(process.env.REACT_APP_URL_API + '/account/close_account/', {
            password: password,
            cc: cc,
            rmArticlesOpt: rmArticlesOpt,
            unsubscribe: unsubscribe,
            opinion: opinion,
            date: date.format(now, 'DD MMM YYYY'),
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            setLoader(false)
            setAlertCall(true)
            setAlertMsg(response.data.result)
            setAlertType('success')
            setTimeout(() => {
                sessionStorage.removeItem('userName')
                window.location.href = '/';
            }, 4000)
        }).catch(error => {
            setLoader(false)
            setAlertCall(true)
            setAlertMsg(error.response.data.result)
            setAlertType('error')
            setTimeout(() => {
                setAlertCall(false)
            }, 4000)
        })

    }

    const Proceed = () => {
        if (agree) {
            document.getElementById('removeAccountDiv').style.left = '-100%';
            setCheckPass(true)
            axios.post(process.env.REACT_APP_URL_API + '/account/set_cc/', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                setAlertCall(true)
                setAlertMsg(response.data.result)
                setAlertType('success')
                setTimeout(() => {
                    setAlertCall(false)
                }, 4000)
            }).catch(error => {
                setAlertCall(true)
                setAlertMsg(error.response.data.result)
                setAlertType('error')
                setTimeout(() => {
                    setAlertCall(false)
                }, 4000)
            })
        } else {
            setAlertCall(true)
            setAlertMsg('You should agree to the terms and conditions!')
            setAlertType('error')
            setTimeout(() => {
                setAlertCall(false)
            }, 4000)
        }
    }


    // Alert Message 
    const AlertMsg = (AlertMsg) => {
        setAlertCall(AlertMsg)
    }

    const ResetRemoveAccountDiv = () => {
        document.getElementById('removeAccountDiv').style.left = '0%';
        setCheckPass(false)
    }

    const CheckPasswordWindow = () => {
        return (
            <CheckPass>
                <h3 style={{ display: 'flex', alignItems: "center", margin: '0' }}>Confirm your password  &nbsp; <RiLockPasswordLine /></h3>
                <input type='password' placeholder='Password' style={input} id='password' />
                <input type='text' placeholder='Confirmation Code (CC)' style={input} id='cc' />
                <button style={formBtn} onClick={() => proceedRemove()}>{loader ? <Loader /> : <>Proceed</>}</button>
                <span style={{
                    fontSize: '10px',
                    margin: '0 auto',
                    marginTop: '1rem',
                }}>A confirmation code has been sent to your email. </span>
                <IoArrowBackSharp onClick={() => ResetRemoveAccountDiv()} size={24} style={{
                    position: 'absolute',
                    left: '45%',
                    bottom: '2%',
                    cursor: 'pointer',
                    color: '#29351ecc',
                }} />
            </CheckPass>
        )

    }

    return (
        <>
            <RemoveAccountWindow>
                {checkPass && <CheckPasswordWindow />}
                <RemoveAccountWindowDiv id='removeAccountDiv'>
                    <CloseDiv onClick={() => closeRemoveAccountDiv()}>
                        <MdClose size={18} />
                    </CloseDiv>
                    <WindowHeader>
                        <h2 style={{ margin: '0', display: 'flex', alignItems: 'center' }}> <LuAlertTriangle color='#ff8400' style={{ marginRight: '8px' }} /> CONFIRM YOUR ACTION</h2>
                        {/* <span style={{ fontSize: '10px' }}>Choose the following option to proceed</span> */}
                    </WindowHeader>
                    <Options>
                        <div style={{ display: 'flex', alignItems: 'flex-start', marginRight: '8px', marginTop: '24px' }}>
                            <input type='checkbox' style={{ margin: '4px', marginRight: '8px' }} onChange={(e) => setRmArticlesOpt(e.target.checked)} />
                            <span style={{ fontSize: '12px' }}>Remove all my articles that I previously published on TechBitLife.</span>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start', margin: '24px 0' }}>
                            <input type='checkbox' style={{ margin: '4px', marginRight: '8px' }} onChange={(e) => setUnsubscribe(e.target.checked)} />
                            <span style={{ fontSize: '12px' }}>I'd like to unsubscribe from TechBitLife.</span>
                        </div>
                    </Options>
                    <Note>
                        <h3 style={H3}>Note:</h3>
                        <div style={{ display: 'flex', padding: '8px 0' }}>
                            <BsCircleFill size={10} style={{ margin: '2px', marginRight: '8px', }} />
                            <span style={{ fontSize: '12px' }}>
                                If you chose the <span style={{ fontWeight: 'bold' }}>"Remove all my articles"</span> option, then all your articles on TechBitLife will be
                                removed and this process can't be undone!
                            </span>
                        </div>
                        <div style={{ display: 'flex', padding: '8px 0' }}>
                            <BsCircleFill size={10} style={{ margin: '2px', marginRight: '8px', }} />
                            <span style={{ fontSize: '12px' }}>
                                If you <span style={{ fontWeight: 'bold' }}>do not  </span>select the "Remove my all articles" option, all of your articles will remain in place.
                                accessible to you when you open your account again!
                            </span>
                        </div>
                    </Note>
                    <Question>
                        <h3 style={H3}>What could be the best?</h3>
                        <textarea style={textArea} placeholder='Your opinion' onChange={(e) => setOpinion(e.target.value)}></textarea>
                    </Question>
                    <div style={{ display: 'flex', alignItems: 'center', padding: '0 24px' }}>
                        <input type='checkbox' style={{ margin: '0', marginRight: '8px' }} onChange={(e) => setAgree(e.target.checked)} />
                        <span style={{ fontSize: '12px' }}>I agree with all the terms and conditions.</span>
                    </div>
                    <button style={Btn} onClick={() => Proceed()} className='btnHover'>Proceed</button>
                </RemoveAccountWindowDiv>
                {/* Alert Message  */}
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'fixed', left: '0', bottom: '0' }}>
                    {alertCall && <Alert alertMsg={alertMsg} alertType={alertType} AlertMsg={AlertMsg} />}
                </div>
            </RemoveAccountWindow>
        </>
    )
}

export default RemoveAccount;