import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ScreenSizeDetector from 'screen-size-detector';
import axios from 'axios'
import { NavLink } from "react-router-dom";
import Loader from '../subComponents/Loader'
import UpdateArticle from "../components/UpdateArticle";
import '../App.css'
import { useDispatch } from "react-redux";
import { alertActive } from '../Redux'
import { alertDeactive } from '../Redux'

// Images 
import errorImg from '../Images/error.png'
import Preview from '../Images/PreviewImg.png'
import oops from '../Images/oops.png'

// React Icons 
import { FiEdit } from 'react-icons/fi'
import { IoRemoveCircleOutline } from 'react-icons/io5'
import { AiFillTags } from 'react-icons/ai'
import { RiKeyboardLine } from 'react-icons/ri'
import { AiOutlineHeart } from 'react-icons/ai'
import { VscTypeHierarchySub } from 'react-icons/vsc'
import { MdDateRange } from 'react-icons/md'
import { MdOutlineArticle } from 'react-icons/md'
import { AiOutlineTags } from 'react-icons/ai'
import { RiSettings5Line } from 'react-icons/ri'
import { AiOutlineFundView } from 'react-icons/ai'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { GoDotFill } from 'react-icons/go'
import { TbListDetails } from 'react-icons/tb'





const Grid = styled.div`
   display: grid;
   grid-gap: 10px;
   padding: 10px;
   margin: 2rem;
   font-family: Manrope;
   grid-template-areas: 'left middle middle right';
 


   @media (max-width: 950px){
    grid-template-areas: 'left left left left'
                         ' middle middle right right';
   }
   @media (max-width: 650px){
    grid-template-areas: 'left left left left'
                         'middle middle middle middle'
                         'right right right right';
   }

`

const Thumbnail = styled.img`
   width: 252px;
   height: 160px;
   border-radius: 5px;
`

const span = {
    display: 'flex',
    alignItems: 'flex-start',
    margin: '5px 0',
    fontSize: '13px',
}

const button = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    padding: '10px',
    background: '#29351e',
    border: '1px solid',
    borderRadius: '5px',
    color: 'white',
    cursor: 'pointer',
    textTransform: 'uppercase',
}
const Container = {
    display: 'flex',
    position: 'fixed',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#00000073',
    top: '0',
    zIndex: +4,
    fontFamily: 'Manrope',
}

const warningButtons = {
    padding: '5px 20px',
    border: 'none',
    margin: '0 5px',
    outline: 'none',
    fontSize: '15px',
    cursor: 'pointer',
}

const TagsEditor = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   background: #00000087;
   position: fixed;
   top: 0;
   z-index: +4;
   height: 100%;
   width: 100%;
 `
const AllTags = styled.div`
   display: flex;
   width: 22rem;
   flex-wrap: wrap;
 `

const ExistingTag = styled.span`
   display: flex;
   align-items: center;
   background: gainsboro;
   font-weight: 500;
   font-size: 14px;
   margin: 4px;
   border-radius: 4px;
   padding: 2px 4px;
 `

const TagInput = styled.input`
   padding: 8px 2px;
   border: none;
   font-family: Roboto;
   margin: 0 12px;
   border-bottom: 1px solid #cdcdcd;
   font-size: 14px;
   outline: none;
   width: 65%;
 `

const EditSeriesPanel = styled.div`
   display: flex;
   position: relative;
   flex-direction: column;
   justify-content: center;
   padding: 16px;
   border: none;
   font-family: Roboto;
   margin: 0 12px;
   border-bottom: 1px solid gray;
   font-size: 14px;
   outline: none;
   background: white;
   width: 30rem;
   border-radius: 8px;
   height: 14rem;
   z-index: +1;
   border: 4px solid #28351d;
 `

const AvailableSeriesbox = styled.div`
   display: flex;
   flex-direction: column;
   background: white;
   border-radius: 16px;
   margin: 4px;
   width: 24rem;
   height: 24rem;
   z-index: +1;
   box-shadow: 0 12px 14px #2a2a2a8c;
   border: 4px solid #112300;
   font-family: 'Manrope';
   overflow: hidden;
   
`

const SeriesBox = styled.div`
   display: flex;
   flex-direction: column;
   height: 100%;
   overflow-y: auto;
   padding: 16px;

`


const SeriesInput = styled.input`
    width: 60%;
    border: 1px solid gray;
    border-radius: 4px;
    outline: none;
    padding: 10px;
    font-size: 14px;
    font-family: Manrope;
`

const NewSeriesAlert = styled.span`
    display: flex;
    align-items: center;
    position: absolute;
    font-family: Manrope;
    font-size: 12px;
    margin: 8px 2px;
    transition: 0.5s all;
    bottom: 8%;
    
`




function PrvPost(props) {
    const dispatch = useDispatch()
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [Delete, setDelete] = useState(false)
    const [updateArticle, setUpdateArticle] = useState('')
    const [articleName, setArticleName] = useState('')
    const [articleId, setArticleId] = useState('')
    const [tags, setTags] = useState([]);
    const [editTags, setEditTags] = useState(false)
    const [loader, setLoader] = useState(false)
    const username = sessionStorage.getItem('username')
    const token = sessionStorage.getItem('token')
    const editorRef = useRef()

    // Series 
    const [editSeriesPanel, setEditSeriesPanel] = useState(false);
    const [chooseSeriesWindow, setChooseSeriesWindow] = useState(false);
    const [availableSeries, setAvailableSeries] = useState([])
    const [selectedSeries, setSelectedSeries] = useState('');
    const [newSeriesName, setNewSeriesName] = useState('');

    // fetch Series 
    useEffect(() => {
        axios.get(process.env.REACT_APP_URL_API + '/available_series/', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setAvailableSeries(res.data.result.SeriesNames)
                setSelectedSeries(res.data.result.SeriesNames[0])
            }).catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        const handleResize = () => {
            const screen = new ScreenSizeDetector();
            setScreenSize(screen.width)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        };

    }, [])

    // DELETE ARTICLE 

    const proceedDelete = () => {
        axios.delete(process.env.REACT_APP_URL_API + '/remove_article/' + articleId, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setDelete(false)
                dispatch(alertActive('success', res.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                    props.refresh(Math.random())
                }, 1000)
            })
            .catch(err => {
                dispatch(alertActive('error', err.response.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                    setDelete(false)
                }, 3000)
            })
    }

    // Delete article popup
    const DeleteWarning = () => {
        return (
            <>
                <div style={Container}>
                    <div style={{
                        display: 'flex', flexDirection: 'column', background: 'white',
                        padding: '5px 10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxWidth: '24rem',
                        textAlign: 'center',
                        boxShadow: '0 15px 15px #00000070',
                        borderRadius: '5px',
                        margin: '0 8px',
                    }}>
                        <img src={errorImg} style={{ width: '4rem', margin: '10px' }} />
                        <span style={{
                            textTransform: 'uppercase',
                            fontWeight: 'bold',
                            color: 'red',
                            margin: '5px'
                        }}>Warning</span>
                        <span style={{
                            fontSize: '15px',
                            fontWeight: 'bold',
                            margin: '5px'
                        }}> Do you want to remove article "{articleName}" permantly ?</span>
                        <div style={{ margin: '1rem' }}>
                            <button style={{
                                ...warningButtons, background: 'none',
                                border: '2px solid', borderRadius: '4px',
                            }} onClick={() => proceedDelete()}>Yes, remove</button>
                            <button style={{
                                ...warningButtons, background: '#284f08',
                                color: 'white',
                                border: '2px solid #284f08', borderRadius: '4px',
                            }} onClick={() => setDelete(false)}>No</button>

                        </div>
                    </div>
                </div>
            </>
        )
    }

    const DeleteArticle = (title, id) => {
        setDelete(true)
        setArticleName(title)
        setArticleId(id)
    }

    // Edit Tags work ** START

    const EditTags = (ExistTags) => {
        setEditTags(true);
        setTags(JSON.parse(ExistTags))
    }
    const updateTags = () => {
        setLoader(true)
        axios.post(process.env.REACT_APP_URL_API + '/update_tags', {
            articleId: articleId,
            tags: JSON.stringify(tags)
        }).then(res => {
            setLoader(false)
            setEditTags(false);
            dispatch(alertActive('success', res.data.result))
            setTimeout(() => {
                dispatch(alertDeactive())
                props.refresh(Math.random())
            }, 1000)
        })
            .catch(err => {
                setLoader(false)
                dispatch(alertActive('error', err.response.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 2000)
            })
    }

    // ADDING TAGS 
    const addTag = (e) => {
        if (e.key == 'Enter') {
            if (tags.includes(e.target.value)) {
                dispatch(alertActive('error', 'Tag already exist!'))
                setTimeout(() => {
                    dispatch(alertDeactive())
                    setDelete(false)
                }, 2000)
            } else {
                setTags([...tags, e.target.value])
                e.target.value = ''
            }
        }
    }

    const removeTag = (tag) => {
        const newTags = tags.filter((tags) => {
            return tags !== tag
        })
        setTags(newTags)
    }

    const TypeTags = () => {
        return (
            <TagsEditor>
                <div style={{ fontFamily: 'Manrope', fontWeight: 600, margin: '10px 4px', background: 'white', borderRadius: '8px', overflow: 'hidden', width: '24rem', border: '3px solid #28351d' }}>
                    <h2 style={{
                        margin: 0,
                        background: '#29351e',
                        color: 'white',
                        border: 'none',
                        textAlign: 'center',
                        padding: '10px',
                        fontSize: '16px',
                    }}>Add New Tags</h2>
                    <div style={{ margin: '8px 0' }}>
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            padding: '8px',
                        }}>
                            {tags.map(tag => (
                                <ExistingTag key={tag}>{tag} &nbsp; <IoIosCloseCircleOutline size={14} onClick={() => removeTag(tag)} /> </ExistingTag>
                            ))}
                        </div>
                        <TagInput type="text" placeholder="add tag" onKeyDown={(e) => addTag(e)} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '2rem' }}>
                        <button className="btnHover" style={{ ...button, border: 'none', margin: '4px', borderRadius: '5px' }} onClick={() => updateTags()}>{loader ? <Loader /> : <> UPDATE </>}</button>
                        <button style={{ ...button, background: 'red', border: 'none', margin: '4px', borderRadius: '5px' }} onClick={() => setEditTags(false)}>CANCLE</button>
                    </div>
                </div>
            </TagsEditor>
        )
    }
    // Edit Tags work ** END
    const editPanel = (value) => {
        setUpdateArticle(value)
    }

    const editSeries = (id, name) => {
        setEditSeriesPanel(true)
        setArticleId(id)
        setSelectedSeries(name ? name : `No Series`)
    }


    const ChooseSeriesBox = () => {
        return (
            <>
                {chooseSeriesWindow &&
                    <div style={Container}>
                        <AvailableSeriesbox>
                            <span style={{ fontSize: '18px', fontWeight: 600, margin: '0', padding: '16px', background: '#102300', color: 'white' }}>Active series</span>
                            <SeriesBox>
                                {availableSeries && availableSeries.map(val => (
                                    <span key={Math.random() * 1000} style={{
                                        fontWeight: 600,
                                        margin: '8px 0',
                                        color: '#313730',
                                        cursor: 'pointer'
                                    }} onClick={() => setNewSeriesName(val)
                                        + setChooseSeriesWindow(false)}>{val}</span>
                                ))}
                            </SeriesBox>
                        </AvailableSeriesbox>
                        {/* Clicking outside the box-> Then close it  */}
                        <div style={{ position: 'absolute', width: '100%', height: '100%' }}
                            onClick={() => setChooseSeriesWindow(false)}>
                        </div>
                    </div >
                }
            </>
        )
    }

    // Update Series 
    const updateSeries = () => {
        setLoader(true)
        axios.post(process.env.REACT_APP_URL_API + '/update_series/', {
            newSeries: newSeriesName,
            articleId: articleId,
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            setLoader(false)
            dispatch(alertActive('success', res.data.result))
            setTimeout(() => {
                dispatch(alertDeactive())
                setEditSeriesPanel(false)
            }, 3000)

        }).catch(err => {
            setLoader(false)

            dispatch(alertActive('error', err.response.data.result))
            setTimeout(() => {
                dispatch(alertDeactive())
            }, 3000)
        })
    }

    return (
        <>
            {Delete && <DeleteWarning />}
            {updateArticle && <UpdateArticle articleId={updateArticle} editPanel={editPanel} />}
            {editTags && <TypeTags />}
            {editSeriesPanel &&
                <div style={Container}>
                    <EditSeriesPanel>
                        <span style={{
                            fontWeight: 500, color: '#323b30',
                            fontSize: '18px',
                            margin: ' 12px 0',
                            fontFamily: 'Manrope',
                        }}>Current series is - <strong> {selectedSeries} </strong></span>
                        <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Manrope' }}>
                            <SeriesInput type="text" placeholder="New series name." onChange={(e) => setNewSeriesName(e.target.value)} value={newSeriesName} />
                            &nbsp;&nbsp;<strong> Or</strong> &nbsp;&nbsp;
                            <button style={{ ...button, width: '6rem' }} onClick={() => setChooseSeriesWindow(true)}>Choose</button>
                        </div>
                        <button onClick={() => updateSeries()} style={{ ...button, width: '7rem', margin: '8px 0' }}>{loader ? <Loader /> : <> Update </>} </button>
                        {/* New Series Alert Message */}
                        <NewSeriesAlert style={newSeriesName == '' ? { opacity: 0 } : { opacity: 1 }}><GoDotFill />Current series will replace with - <strong>{newSeriesName}</strong></NewSeriesAlert>
                    </EditSeriesPanel>
                    {/* Clicking outside the box-> Then close it  */}
                    <div style={{ position: 'absolute', width: '100%', height: '100%' }}
                        onClick={() => setEditSeriesPanel(false) + setSelectedSeries('') + setNewSeriesName('')}>
                    </div>
                </div>
            }
            {chooseSeriesWindow && <ChooseSeriesBox />}
            {props.userArticles != '' ?
                (
                    <div style={{ marginTop: '1rem' }}>
                        {
                            props.userArticles.map(val => (
                                <React.Fragment key={val._id}>
                                    <Grid >
                                        <div style={screenSize < 950 ? { display: 'flex', alignItems: 'flex-start', justifyContent: 'left', gridArea: 'left', padding: '8px 0' } : { display: 'flex', alignItems: 'flex-start', gridArea: 'left', padding: '8px 0', justifyContent: 'center' }}>
                                            <NavLink to={"/article/" + val.Type + '/' + val.Keyword + '/' + val.BlogNumber}>
                                                <Thumbnail src={val.Thumbnail ? process.env.REACT_APP_URL_API + '/Thumbnails/' + val.Thumbnail : Preview} />
                                            </NavLink>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '24rem', gridArea: 'middle' }}>
                                            <span style={{ ...span, fontWeight: 'bold' }}><MdOutlineArticle style={{ marginRight: '10px' }} size={18} />
                                                <span style={screenSize < 420 ? { width: '16rem' } : { width: '24rem' }}> {val.Title} </span></span>
                                            <span style={span}><MdDateRange style={{ marginRight: '10px' }} size={18} /> {val.Date}</span>
                                            <span style={span}><VscTypeHierarchySub style={{ marginRight: '10px' }} size={18} /> {val.Type}</span>
                                            <span style={span}><RiKeyboardLine style={{ marginRight: '10px' }} size={18} />
                                                <span style={screenSize < 420 ? { width: '16rem' } : { width: '24rem' }}> {val.Keyword}</span></span>
                                            <span style={span}><AiOutlineFundView style={{ marginRight: '10px' }} size={18} /> {val.TotalViews}</span>
                                            <span style={span}> <AiOutlineHeart style={{ marginRight: '10px' }} size={18} /> {val.TotalLikes.length} </span>
                                            <span style={span} ><AiOutlineTags style={{ display: 'flex', alignItems: 'flex-start', marginRight: '10px' }} size={18} />
                                                <AllTags>
                                                    {val.Tags && JSON.parse(val.Tags).map(tag => (
                                                        <span key={tag} style={{ display: 'flex', background: '#183300', color: 'white', padding: '2px 5px', margin: '0px 2px', marginBottom: '2px', borderRadius: '4px', whiteSpace: 'nowrap', fontSize: '12px', }}>{tag}</span>
                                                    ))}
                                                </AllTags>
                                            </span>
                                        </div>
                                        <div style={{ gridArea: 'right' }}>
                                            <h3 style={{ display: 'flex', alignItems: 'center', margin: '10px 4px' }}> <RiSettings5Line size={22} style={{ marginRight: '4px' }} />Actions</h3>
                                            <div style={{ display: 'flex', flexDirection: 'column', width: '16rem' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <button className="btnHover" style={button} onClick={() => setUpdateArticle(val._id)}><FiEdit size={20} style={{ margin: '0 10px' }} /></button>
                                                    <button className="btnHover" style={button} onClick={() => DeleteArticle(val.Title, val._id)}> <IoRemoveCircleOutline size={20} style={{ margin: '0 10px' }} /> </button>

                                                    <button className="btnHover" style={{ ...button, }} onClick={() => setArticleId(val._id) + EditTags(val.Tags)}><AiOutlineTags size={20} style={{ margin: '0 2px' }} />
                                                    </button>
                                                    <button style={button} onClick={() => editSeries(val._id, val.Series)}><TbListDetails size={20} /></button>
                                                </div>

                                            </div>
                                        </div>
                                    </Grid>
                                </React.Fragment>

                            ))
                        }
                    </div >
                ) :
                <div>
                    <img src={oops} style={{ display: 'flex', margin: '0 auto', width: '16rem' }} />
                    <h2 style={{
                        textTransform: 'uppercase',
                        fontFamily: 'Manrope',
                        margin: '32px auto',
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#29351e',
                        textAlign: 'center',
                        fontSize: '16px',
                    }}>
                        You haven't posted anything yet...!</h2>
                </div>
            }
        </>
    )
}

export default PrvPost;