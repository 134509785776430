import { BLOG_REQUEST } from "./blogsType";
import { BLOG_SUCCESS } from "./blogsType";
import { BLOG_ERROR } from "./blogsType";


const initialState = {
    loading: false,
    blogs: [],
    error: '',
}

export const blogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case BLOG_REQUEST: return {
            ...state,
            loading: true
        }
        case BLOG_SUCCESS: return {
            loading: false,
            blogs: action.payload,
            error: '',
        }
        case BLOG_ERROR: return {
            loading: false,
            blogs: [],
            error: action.payload,
        }
        default: return state;
    }
}