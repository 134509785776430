import React, { useState, useEffect } from "react";
import styled from "styled-components";
import NavMenu from "./NavMenu";
import ScreenSizeDetector from 'screen-size-detector';
import { NavLink } from "react-router-dom";
import Alert from './Alert'
import axios from 'axios'
import date from 'date-and-time';
import { useSelector } from 'react-redux';
import shortNumber from 'short-number'
// icons 
import { ImLocation2 } from 'react-icons/im'
import { BsBookmarkPlusFill } from 'react-icons/bs'
import { BiShareAlt } from 'react-icons/bi'
import { BsFillArrowRightCircleFill } from 'react-icons/bs'
import { RxEyeOpen } from 'react-icons/rx'
import { BsHeartFill } from 'react-icons/bs'



const ArticleContainer = styled.div`
   display: flex;
   flex-direction: column; 
   justify-content: center;
   width: 320px;
   margin: 12px;
   background: #f6f8f6;
   border-radius: 8px;
   overflow: hidden;
   transition: 0.3s all;
   &:hover {
        box-shadow: 0 10px 10px #bebebe;
   }

   @media (max-width: 330px){
    width: 300px;
   }

   @media (max-width: 1025px){
    width: 300px;
   }

`

const Img = styled.img`
    height: 100%;
    margin: 0 auto;
    position: absolute;
    width: 100%;
    transition: 0.3s all;

    &:hover {
        height: 105%;
        margin: 0 auto;
        width: 105%;
   }

`
const h3 = {
    display: 'flex',
    margin: '0 0',
    padding: '15px 0',
    alignItems: 'flex-start',
    zIndex: +1,
    fontSize: '13px',
    fontWeight: '300',
    background: 'white',
    justifyContent: 'center',
    top: '45px',
    color: '#092900',
}
const Date_Location = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
`
const Authour = styled.div`
 display: flex;
 align-items: center;
 position: relative;
 width: 100%;
 justify-content: left;
 margin: 10px 0;

`
const Profile = {
    width: '30px',
    height: '30px',
    borderRadius: '3rem',
    marginLeft: '5px',
    background: '#e0e0e0',
    padding: '3px'
}

const Media = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 32px;
  align-items: center;
  background: #29351e;
  color: white; 
`
const country = {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    margin: '0 5px',
}

const CollectionData = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1450px;
  margin: 0rem auto;
  justify-content: center;

`
const P = styled.div`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Manrope; 
  font-size: 14px; 
  padding: 3px;
  font-weight: bold; 
  height: 38px;
  margin: 10px 5px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

`

function Collection(props) {
    const [articles, setArticles] = useState([])
    const [totalResults, setTotalResults] = useState('')
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [alertCall, setAlertCall] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('');
    const Username = sessionStorage.getItem('username')
    const token = sessionStorage.getItem('token')

    useEffect(() => {
        const handleResize = () => {
            const screen = new ScreenSizeDetector();
            setScreenSize(screen.width)
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        };
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(process.env.REACT_APP_URL_API + '/articles/type/' + props.type)
            .then(response => {
                setArticles(response.data.result)
                let arr = [];
                response.data.result.map(val => {
                    if (val.Type === props.type) {
                        arr.push(val)
                    }
                    return true;
                })
                setTotalResults(arr.length)
            })
            .catch(error => {
                console.log(error.response.data.result)
            })
    }, [props.type])


    // **Bookmark
    const Bookmark = (id) => {
        const now = new Date();
        axios.post(process.env.REACT_APP_URL_API + '/favourites', {
            articleId: id,
            date: date.format(now, 'DD MMM YYYY'),
        },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {
                setAlertCall(true)
                setAlertMsg(response.data.result)
                setAlertType('success')
                setTimeout(() => {
                    setAlertCall(false)
                }, 5000)
            })
            .catch(error => {
                setAlertCall(true)
                setAlertMsg(error.response.data.result)
                setAlertType('error')
                setTimeout(() => {
                    setAlertCall(false)
                }, 5000)
            })
    }

    // ALERT CALL MESSAGE PROPS 
    const AlertMsg = (AlertMsg) => {
        setAlertCall(AlertMsg)
    }

    // COPY LINK OF ARTICLE 
    const copyLink = (Type, keyword, BlogNumber) => {
        let url = document.location.host + "/article/" + Type + '/' + keyword + '/' + BlogNumber
        navigator.clipboard.writeText(url).then(function () {
            setAlertCall(true)
            setAlertType('success')
            setAlertMsg('Link copied to clipboard !')
            setTimeout(() => {
                setAlertCall(false)
            }, 5000)
        }, function () {
            setAlertCall(true)
            setAlertMsg('Please try again !')
            setAlertType('error')
            setTimeout(() => {
                setAlertCall(false)
            }, 5000)
        });
    }

    return (
        <>
            <NavMenu />
            <div style={screenSize < 650 ? { position: 'relative', fontFamily: 'Manrope', marginTop: '5rem' } : { position: 'relative', fontFamily: 'Manrope', marginTop: '7rem', minHeight: '40rem' }}>

                <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center', marginBottom: '5rem' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem' }}>
                        <h3 style={h3}> <BsFillArrowRightCircleFill size={18} /><span style={{ margin: '0 10px' }}>Start reading your favourite&nbsp;<span style={{ fontWeight: 'bold', margin: '0 2px' }}>{props.category}</span></span>  </h3>
                        <span style={{ fontSize: '15px', color: '#092900' }}> <span style={{ fontWeight: 'bold' }}> {totalResults} </span> results are found</span>
                    </div>

                    <CollectionData>
                        {articles && articles.map(val => (
                            (val.Type === props.type) ?
                                <React.Fragment key={val._id}>
                                    <ArticleContainer >
                                        <NavLink to={"/article/" + props.type + '/' + val.Keyword + '/' + val.BlogNumber} style={{ display: 'flex', height: '204px', width: "100%", overflow: 'hidden', position: 'relative', justifyContent: 'center', alignItems: 'center' }}>
                                            <Img src={process.env.REACT_APP_URL_API + '/Thumbnails/' + val.Thumbnail} alt='thumbnail' />
                                        </NavLink>
                                        <P>{val.About}</P>
                                        <Date_Location>
                                            <div style={country}>
                                                <ImLocation2 />
                                                <span style={{ margin: '0 10px' }}>From <span style={{ fontWeight: 'bold' }}>{val.CountryOrigin}</span></span>
                                            </div>
                                            <div style={{ position: 'absolute', right: '10px', fontWeight: 'bold', fontSize: '13px' }}>
                                                {val.Date}
                                            </div>
                                        </Date_Location>
                                        <Authour>
                                            <img style={Profile} src={process.env.REACT_APP_URL_API + '/Profile/' + val.AuthorProfile} alt="authour" />
                                            <span style={{ margin: '0 10px', fontSize: '13px' }}><span>By</span><span style={{ fontWeight: 'bold', margin: '0 5px' }}>{val.AuthorName}</span></span>
                                        </Authour>
                                        <Media>
                                            <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', left: '12px' }}>
                                                <span style={{ fontSize: '14px' }}>{shortNumber(parseInt(val.TotalViews))}</span>
                                                <RxEyeOpen size={18} style={{ margin: '10px', cursor: 'pointer' }} title='Views' />
                                                &nbsp;
                                                &nbsp;
                                                <span style={{ fontSize: '14px' }}>{val.TotalLikes.length}</span>
                                                <BsHeartFill size={12} style={{ margin: '10px', cursor: 'pointer' }} title='Likes' />
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: '0' }}>
                                                <BsBookmarkPlusFill style={{ margin: '10px', cursor: 'pointer' }} title='Add to Favourites' onClick={() => Bookmark(val._id)} />
                                                <BiShareAlt style={{ margin: '10px', cursor: 'pointer' }} title='Share' onClick={() => copyLink(val.Type, val.Keyword, val.BlogNumber)} />
                                            </div>
                                        </Media>
                                    </ArticleContainer>
                                </React.Fragment> : ''
                        ))}

                    </CollectionData>
                </div>
                {/* Alert Window  */}
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'fixed', left: '0', bottom: '0' }}>
                    {alertCall && <Alert alertMsg={alertMsg} alertType={alertType} AlertMsg={AlertMsg} />}
                </div>
            </div>
        </>
    )
}

export default Collection;