import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ScreenSizeDetector from 'screen-size-detector';
import sanket from '../Images/sanket.jpg'
import comma1 from '../Images/comma1.png'
import comma2 from '../Images/comma2.png'
import Brand_illustration from '../Images/Brand_illustration.png'
import { RxDotFilled } from 'react-icons/rx'
import NavMenu from '../subComponents/NavMenu'
import '../App.css'



const AboutPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 35rem;
  margin-top: 7rem;
  font-family: Manrope;
  text-align: center;
  color: #102300;

  @media (max-width: 650px){
    text-align: justify;
    margin-top: 5rem;
  }
`
const BrandTagline = styled.span`
   font-size: 12px;
   letter-spacing: 3.5px;
   font-family: 'Manrope';
   font-weight: 600;
   text-align: center;

   @media (max-width: 450px){
    font-size: 10px;
    letter-spacing: 2.5px;
  }

`
const Brand = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0e2d0c;
    width: 800px;
    height: 329px;
    margin-top: 24px;
    border-radius: 16px;
    overflow: hidden;

    @media (max-width: 900px){
        width: 600px;
        height: 247px;
    }
    @media (max-width: 700px){
        width: 500px;
        height: 205px;
    }
    @media (max-width: 600px){
        width: 400px;
        height: 164px;
    }
    @media (max-width: 420px){
        width: 350px;
        height: 144px;
    }
    @media (max-width: 350px){
        width: 310px;
        height: 127px;
    }
`
const profile_img = {
    width: '6rem',
    margin: '16px auto',
    borderRadius: '10rem',
}

const TC = {
    background: '#102300',
    color: 'white',
    margin: '2rem 8px',
    padding: '24px',
    borderRadius: '8px',
    maxWidth: '50rem',
}

const TC_mainDiv = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '1rem 0',
}
const TC_P = {
    display: 'flex',
    textAlign: 'initial',
    fontSize: '10px',
    letterSpacing: '0.5px',
    marginLeft: '28px',
}
const TC_Span = {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '0 10px',
    textAlign: 'left',
}


function About(props) {
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            const screen = new ScreenSizeDetector();
            setScreenSize(screen.width)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        };

    }, [])

    return (
        <>
            <NavMenu />
            <AboutPage>
                <h1 style={{
                    marginTop: '24px',
                    marginBottom: '4px',
                    fontFamily: 'Manrope',
                }}>About</h1>
                <BrandTagline>Unleash Your Words, Explore Limitless Topics</BrandTagline>
                <Brand><img src={Brand_illustration} style={screenSize < 650 ? { width: '100%' } : { width: '100%' }} /></Brand>
                <span style={{ fontSize: '14px', margin: '2rem 16px', maxWidth: '70rem' }}>Welcome to the TechBitLife a blogging website. <span style={{ fontWeight: 'bold' }}> Sanket Mhaiske</span> founded it in 2022 with the goal of providing readers with the best, most unique, and most relevant articles. Here you can also upload your articles by creating your profile in a few steps. If you are interested in writing and sharing, then why are you waiting? Just start your work and reach thousands of people.
                </span>
                <h2 style={screenSize < 420 ? { fontSize: '20px', textAlign: 'center' } : { fontSize: '25px' }}>Designed and developed by</h2>
                <img src={sanket} style={profile_img} />
                <h3 style={{ margin: '0px' }}>Sanket Mhaiske</h3>
                <span style={{ fontSize: '12px' }}>Full stack developer | Freelancer </span>
                <div style={{ margin: '2rem 16px', maxWidth: '70rem' }}>
                    <img src={comma1} style={{ width: '1rem', margin: '0 5px' }} />
                    <span style={{ fontSize: '14px' }}> <span style={{ fontWeight: 'bold' }}>Sanket Mhaiske</span> is the founder of TechBitLife and a professional full-stack developer and web designer. He loves to design the ideas, develop the platform, and share the unique information with people. As an owner, he is trying his best to improve this platform day by day. His dedication, creativity, and quick decision-making skills make him an excellent designer and developer, and he will always try to introduce the best features to other bloggers on this platform. </span>
                    <img src={comma2} style={{ width: '1rem', margin: '0 5px' }} />
                </div>
                <div style={TC}>
                    <h2>Terms and conditions</h2>
                    <div style={TC_mainDiv}>
                        <span style={TC_Span}> <RxDotFilled size={22} style={{ color: '#6be912', margin: '3px 2px' }} /> How to use this platform</span>
                        <p style={TC_P}>If you are sharing information on this platform, make sure it does not contain abusive or inappropriate language for the user, incorrect information, or that your content does not violate any laws or the legal rights of another party.</p>
                    </div>
                    <div style={TC_mainDiv}>
                        <span style={TC_Span}> <RxDotFilled size={22} style={{ color: '#6be912', margin: '3px 2px' }} /> You can share the content from TechBitLife elsewhere.</span>
                        <p style={TC_P}> You can share your content on other platforms. Use of the TechBitLife attribute will be considered a contribution to us.</p>
                    </div>
                    <div style={TC_mainDiv}>
                        <span style={TC_Span}> <RxDotFilled size={22} style={{ color: '#6be912', margin: '3px 2px' }} />Your post may be removed.</span>
                        <p style={TC_P}> If you upload content that violates our policies, then your post may be removed or temporarily hidden on this platform. </p>
                    </div>
                    <div style={TC_mainDiv}>
                        <span style={TC_Span}><RxDotFilled size={22} style={{ color: '#6be912', margin: '3px 2px' }} /> Where you can submit your query</span>
                        <p style={TC_P}>You can submit your query by reporting it to us. We will try to reach you as soon as possible.</p>
                    </div>
                </div>
            </AboutPage>
        </>
    )
}

export default About;