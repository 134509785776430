import React, { useState } from "react";
import styled from "styled-components";
import date from 'date-and-time';
import axios from 'axios'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { AiOutlineMail } from 'react-icons/ai'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import Loader from "../subComponents/Loader";
import { useDispatch } from "react-redux";
import { alertActive } from '../Redux'
import { alertDeactive } from '../Redux'


const ConnectWithUsPanel = styled.div`
   display: flex;
   position: fixed;
   height: 100%;
   width: 100%;
   top: 0;
   background: #000000c4;
   justify-content: center;
   align-items: center;
   z-index: +4;
   overflow: hidden;
   overflow-y: auto;
   font-family: Manrope;
   color: #2a3b28;
`
const Form = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 40rem;
    background: white;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 8px;
`

const button = {
    padding: '10px',
    background: '#102300',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
    border: 'none',
    fontFamily: 'Manrope',
}

const ConnectConfirmationPanel = styled.div`
    display: flex;
    position: fixed;
    background: #000000c4;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: +4;
    top: 0;
    font-family: Manrope;
`

const form2 = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#2a3b28',
    width: '20rem',
    height: '28rem',
    textAlign: 'center',
    background: 'white',
    padding: '16px',
    borderRadius: '16px',
    borderRadius: '8px',
    background: 'white',
}

const button2 = {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem',
    border: 'none',
    color: 'white',
    fontWeight: 'bold',
    padding: '10px',
    width: '40%',
    fontSize: '15px',
    cursor: 'pointer',
    background: '#3b7d03',
    borderRadius: '8px',
}


const inputLine = {
    padding: '10px 8px',
    border: '1px solid lightgray',
    outline: 'none',
    width: '19rem',
    margin: '1rem 0',
    fontSize: '15px',
    fontFamily: 'Manrope',
    background: 'none',
    border: 'none',
    borderBottom: '1px solid #2a3b28',
}

const Textarea = styled.textarea`
    display: flex;
    resize: none;
    width: -webkit-fill-available;
    height: 164px;
    outline: none;
    border: 1px solid #a6a6a6;
    border-radius: 8px;
    padding: 16px 10px;
    font-size: 14px;
    font-family: 'Manrope';
    letter-spacing: 0.5px;
`



function Connect(props) {
    const closeConnectPanel = () => {
        props.closeConnectPanel(false)
    }
    const dispatch = useDispatch()
    const [confirmEmail, setConfirmEmail] = useState(false);
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [loader, setLoader] = useState(false);

    const Connect = (e) => {
        e.preventDefault();
        if (message) {
            setConfirmEmail(true)
        } else {
            dispatch(alertActive('error', 'Invalid Message'))
            setTimeout(() => {
                dispatch(alertDeactive())
            }, 4000)
        }
    }

    const proceedConnect = () => {
        setLoader(true)
        const now = new Date();
        axios.post(process.env.REACT_APP_URL_API + "/sources/write_to_us", {
            name: name,
            email: email,
            message: message,
            date: date.format(now, 'DD MMM YYYY')
        })
            .then(res => {
                setLoader(false)
                setConfirmEmail(false)
                dispatch(alertActive('success', res.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 4000)
            })
            .catch(err => {
                setLoader(false)
                dispatch(alertActive('error', err.response.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)
            })

    }

    return (
        <>
            {confirmEmail ? '' :
                <ConnectWithUsPanel>
                    <Form>
                        <h1 style={{
                            margin: '10px', fontSize: '25px',
                            marginBottom: '0px',
                            marginTop: '24px',
                        }}>Write to us</h1>
                        <span style={{
                            margin: '0 12px',
                            fontSize: '12px',
                        }}>You will receive a response to your message at your email address.</span>
                        <div style={{ padding: '10px' }}>
                            <Textarea placeholder="Write you message." onChange={(e) => setMessage(e.target.value)}></Textarea>
                        </div>
                        <button style={button} onClick={Connect} className='btnHover'>Continue</button>
                        <div style={{
                            position: 'absolute',
                            right: '1%',
                            top: '2%',
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            color: '#112300',
                        }} onClick={() => closeConnectPanel()}>
                            <IoIosCloseCircleOutline size={24} />
                        </div>
                    </Form>

                </ConnectWithUsPanel>}

            {confirmEmail ?
                <ConnectConfirmationPanel>
                    <div style={form2}>
                        <AiOutlineMail size={100} style={{ margin: '1rem' }} />
                        <input name="name" style={inputLine} type='text' placeholder='Name' onChange={(e) => setName(e.target.value)} />
                        <input name="email" style={inputLine} type='email' placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
                        <button style={button2} onClick={proceedConnect}>{loader ? <Loader style={{ background: 'red' }} /> : <>Proceed</>}</button>
                        <span style={{ fontSize: '10px', margin: '1rem 0' }}>
                            You will get response from our side within three working days if not please contact to web@techbitlife.com
                        </span>
                        <BsFillArrowLeftCircleFill size={25} style={{ cursor: 'pointer' }} onClick={() => setConfirmEmail(false)} />
                    </div>
                </ConnectConfirmationPanel>

                : ''}
        </>
    )
}

export default Connect;