import React, { useState, useEffect } from 'react'
import styled from "styled-components";
import axios from 'axios';
import Alert from '../subComponents/Alert'
import Loader from '../subComponents/Loader'
import ScreenSizeDetector from 'screen-size-detector';
import profileImg from '../Images/profile.png'
import { Country, State, City } from 'country-state-city';
import NavMenu from '../subComponents/NavMenu';
import '../App.css'
// imgs 
import noData from '../Images/noData.png'
// icons 
import { FaRegEye } from 'react-icons/fa'
import { BsHeartFill } from 'react-icons/bs'
import { BsHeart } from 'react-icons/bs'
import { SiGoogleanalytics } from 'react-icons/si'

const Container = styled.div`
   display: flex;
   flex-direction: column;
   width: 90%;
   margin: 16px auto;
`

const H2 = styled.h2`
   display: flex;
   align-items: center;
   margin: 0;
`
const Top10 = styled.div`
   display: flex;
   flex-direction: column;
   font-family: Manrope;
`
const Post = styled.div`
   display: flex;
   flex-direction: column;
   width: 280px;
   margin-right: 16px;
   margin-bottom: 16px;
`
const Thumbnail = styled.img`
   width: 280px;
   height: 170px;
   border-radius: 8px;
   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;
`
const Count = styled.div`
   display: flex;
   align-items: center;
   font-size: 12px;
   background: #3c7b06;
   color: white;
   padding: 4px 8px;
   border-radius: 8px;
   margin: 0;
   border-top-left-radius: 0;
   border-top-right-radius: 0;
`
const Span = styled.span`
   display: flex;
   align-items: center;
`

const TotalCount = styled.div`
   display: flex; 
   align-items: center;
   margin: 48px 0;

   @media (max-width: 450px){
    align-items: flex-start;
    flex-direction: column;
  }
`
const totalCount = {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
}

const Num = styled.span`
    font-weight: bold;
    letter-spacing: 0.5px;
`
const NoDataImg = styled.img`
    display: flex;
    margin: 0 auto;
    width: 300px;
`

function Analytics() {
    const token = sessionStorage.getItem('token');
    const [topTen, setTopTen] = useState([])
    const [totalViews, setTotalViews] = useState(0)
    const [totalLikes, setTotalLikes] = useState(0)
    useEffect(() => {
        // Top 10 posts 
        axios.get(process.env.REACT_APP_URL_API + '/top_ten_articles/', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            setTopTen(res.data.result)
        }).catch(err => {
            console.log(err)
        })
        // Total views and likes 
        axios.get(process.env.REACT_APP_URL_API + '/total_views_and_likes/', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            // ---> Views 
            let totalViewsofBlogs = 0;
            res.data.result.map(val => {
                totalViewsofBlogs = totalViewsofBlogs + parseInt(val.TotalViews);
            })
            setTotalViews(totalViewsofBlogs)
            // ---> Likes 
            const collectAllLikes = [];
            res.data.result.map(val => {
                val.TotalLikes.map(likes => {
                    collectAllLikes.push(likes)
                })
            })
            setTotalLikes(collectAllLikes.length)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <Container>
            <Top10>
                <H2><SiGoogleanalytics size={24} /> &nbsp; Your Top 10 Blogs.</H2>
                <span style={{ fontSize: '12px' }}>These are your top ten most-viewed articles.</span>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '32px' }}>
                    {topTen != '' ? topTen.map(val => (
                        <Post key={val._id}>
                            <Thumbnail src={process.env.REACT_APP_URL_API + '/Thumbnails/' + val.Thumbnail} />
                            <Count>
                                <Span><span style={{ fontSize: '13px' }}> {val.TotalViews} </span> &nbsp; &nbsp; <FaRegEye /> </Span> &nbsp; &nbsp; &nbsp;
                                <Span><span style={{ fontSize: '13px' }}>{val.TotalLikes.length} </span> &nbsp; &nbsp; <BsHeartFill size={10} /> </Span>
                            </Count>
                        </Post>
                    )) :
                        <NoDataImg src={noData} />
                    }
                </div>
                <TotalCount>
                    <div style={totalCount}>
                        <FaRegEye size={14}/>  &nbsp;
                        <span style={{ fontWeight: 400 }}>Total Views :</span> &nbsp;
                        <Num>{totalViews}</Num>
                    </div>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <div style={totalCount}>
                        <BsHeart size={12} />  &nbsp;
                        <span style={{ fontWeight: 400 }}>Total Likes :</span> &nbsp;
                        <Num>{totalLikes}</Num>
                    </div>
                </TotalCount>
            </Top10>
        </Container>
    )
}

export default Analytics;