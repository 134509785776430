import { BLOG_REQUEST } from "./blogsType";
import { BLOG_SUCCESS } from "./blogsType";
import { BLOG_ERROR } from "./blogsType";


export const blogRequest = () => {
    return {
        type: BLOG_REQUEST
    }
}
export const blogSuccess = (blogs) => {
    return {
        type: BLOG_SUCCESS,
        payload: blogs,
    }
}
export const blogError = (error) => {
    return {
        type: BLOG_ERROR,
        payload: error,
    }
}