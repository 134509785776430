import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import ScreenSizeDetector from 'screen-size-detector';
import { NavLink } from "react-router-dom";
import axios from 'axios';
import logo from '../Images/logo.png'
import E404 from '../Images/404.png'
import Oops from '../Images/oops.png'
import '../App.css'
// **react icons 
import { GiHamburgerMenu } from 'react-icons/gi'
import { AiOutlineSearch } from 'react-icons/ai'
import { MdOutlineLogout } from 'react-icons/md'
import { RiAccountPinCircleLine } from 'react-icons/ri'
import { IoCloseSharp } from 'react-icons/io5'
import { ImProfile } from 'react-icons/im'
import { IoCloseCircleSharp } from 'react-icons/io5'

// Redux 
import { useDispatch, useSelector } from "react-redux";
import { blogRequest } from '../Redux'
import { blogSuccess } from '../Redux'
import { blogError } from '../Redux'

// ---------------------------------------------------------------------------------


const Headers = styled.div`
   display: flex;
   height: 4rem;
   width: 100%;
   align-items: center;
   border-bottom: 1px solid #e1e0e0;
   transition: 0.5s all;
   position: fixed;
   background: white;
   top: 0rem;
   z-index: +4;
   color: #092900;
  

   @media (max-width: 650px) {
    height: 3rem;
  }
   
`
const Left = styled.div`
   display: flex;
   align-items: center;
   height: 100%;
   padding: 0 20px;
   border-right: 1px solid #e1e0e0;
   cursor: pointer;
   font-family: Manrope;
   z-index: +3;
   background: white;

   @media (max-width: 650px) {
    padding: 0 5px;
  }
`
const Middle = styled.div`
   display: flex;
   position: relative;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
   font-family: Manrope;
   z-index: +3;
   background: white;

   @media (max-width: 650px) {
    flex-direction: row-reverse;
  }

`
const Right = styled.div`
   display: flex;
   background: white;
   z-index: +3;

   @media (max-width: 650px) {
    display: none;
  }
`
const searchIcon = {
    padding: '10px',
    cursor: 'pointer',
    zIndex: '+1',
}

const input = {
    position: 'relative',
    height: '40%',
    width: '100%',
    padding: '7px 30px',
    fontFamily: 'Manrope',
    fontSize: '16px',
    outline: 'none',
    fontWeight: '300',
    border: 'none',
    borderBottom: '1px solid #295800'

}
const closeInputDiv = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#295800',
    fontSize: '13px',
    padding: '3px 8px',
    cursor: 'pointer',
    position: 'absolute',
    right: '0'
}

const activeSearchIcon = {
    cursor: 'pointer',
    zIndex: 1,
    border: '1px solid #295800',
    borderRadius: '5rem',
    margin: '-20px',
    color: '#295800'
}

// SearchResults
const SearchResults = styled.div`
   display: flex;
   align-items: center;
   background: white;
   position: fixed;
   width: 100%;
   height: 19rem;
   bottom: -20rem;
   border-top: 5px solid #373737;
   overflow: hidden;
   overflow-x: auto;
   transition: 0.5s all;
`

const P = styled.div`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Manrope; 
  font-size: 13px; 
  font-weight: bold; 
  margin: 10px 0px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

`
const SearchError = styled.div`
   width: 100%;
   text-align: center;
   color: white;
   font-family: Manrope;
   padding: 5px;
`

function Header(props) {

    const [searchData, setSearchData] = useState([])
    const [resultBox, setResultBox] = useState(false)
    const [Input, setInput] = useState(false)
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [articles, setArticles] = useState([])
    //token
    const token = sessionStorage.getItem('token');
    const dispatch = useDispatch()

    // Only checking for user session 
    useEffect(() => {
        axios.get(process.env.REACT_APP_URL_API + '/account/me', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {

        }).catch(err => {
            sessionStorage.removeItem('username')
            sessionStorage.removeItem('token')
            if (sessionStorage.getItem('session') === 'active') {
                sessionStorage.setItem('session', 'inactive')
                window.location.href = '/'
            }
        })
    }, [])

    useEffect(() => {
        dispatch(blogRequest())
        axios.get(process.env.REACT_APP_URL_API + '/articles/search', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setArticles(res.data.result)
                props.articles(res.data.result)
                dispatch(blogSuccess(res.data.result))
            }).catch(error => {
                dispatch(blogError(error))
                console.log(error)
            })
    }, [])

    useEffect(() => {
        const handleResize = () => {
            const screen = new ScreenSizeDetector();
            setScreenSize(screen.width)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        };

    }, [])

    const open = () => {
        props.fun(true)

    }
    const openInput = () => {
        setInput(true)
        document.getElementById('Middle').style.flexDirection = 'row';
    }
    const closeInput = () => {
        setInput(false)
        setResultBox(false)

        if (screenSize < 650) {
            document.getElementById('Middle').style.flexDirection = 'row-reverse';
        }
        else {
            document.getElementById('Middle').style.flexDirection = 'row';
        }

    }
    const createAccount = () => {
        props.createAccount(true);
    }

    const userName = sessionStorage.getItem('username')

    const logout = () => {
        sessionStorage.removeItem('username')
        sessionStorage.removeItem('token')
        window.location.href = '/';
    }

    // SEARCH ARTICLES
    const Search = (inputString) => {
        let arr = [];
        articles.map(val => {
            if (val.Keyword.replace(/-/gi, "").match(inputString.toLowerCase().replace(/\s/g, '').replace(/[^a-zA-Z ]/g, '')) && inputString) {
                arr.push(val)
            }
        })
        setResultBox(true)
        setSearchData(arr);
    }

    return (
        <Headers>
            <Left onClick={() => open()}>
                <GiHamburgerMenu style={{ margin: '0 10px' }} />
                <span style={screenSize < 650 ? { display: 'none' } : { display: 'flex' }}>Menu</span>
            </Left>
            <Middle id="Middle">
                {Input ? <AiOutlineSearch size={screenSize < 650 ? 20 : 24} style={screenSize < 650 ? { ...activeSearchIcon, padding: '7px' } : { ...activeSearchIcon, padding: '5px' }} /> :
                    <AiOutlineSearch size={screenSize < 650 ? 22 : 25} style={searchIcon} onClick={() => openInput()} />}
                {Input ?
                    <div style={screenSize < 650 ? { display: 'flex', alignItems: 'center', position: 'relative', minWidth: '80%', color: '#295800' } : { display: 'flex', alignItems: 'center', position: 'relative', minWidth: '90%', color: '#295800' }}>
                        <input type='text' placeholder="Search here...." style={input} onKeyUp={(e) => Search(e.target.value)} />
                        <div style={closeInputDiv} onClick={() => closeInput()} >
                            <IoCloseSharp size={screenSize < 650 ? 20 : 20} style={{ color: '#295800' }} />
                            <span style={{ fontSize: '10px' }}>ESC</span>
                        </div>
                    </div>

                    : ''}
                {Input ? '' :
                    <h3 style={{ display: 'flex', alignItems: 'center', margin: '0 auto', fontFamily: 'Manrope' }}> <img src={logo} style={screenSize < 650 ? { width: '31px', margin: '0 10px' } : { width: '40px', margin: '0 10px' }} alt='logo' /> <span id="Blogname" style={screenSize < 650 ? { fontSize: '17px' } : { fontSize: '20px' }}> TechBitLife </span></h3>}
            </Middle>
            <Right>
                {userName ?
                    <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Manrope' }}>
                        <span style={{ display: 'flex', alignItems: 'center', margin: '0 10px', cursor: 'pointer', fontSize: '13px' }} title='Your Profile'>
                            <ImProfile size={20} style={{ margin: '0 10px' }} />
                            <NavLink to='/profile' style={{ textDecoration: 'none', color: 'black' }} onClick={() => sessionStorage.setItem('activeMenu', 'profile')}> {userName} </NavLink>
                        </span>
                        <MdOutlineLogout style={{ marginRight: '20px', cursor: 'pointer' }} onClick={logout} title='Logout' />
                    </div>
                    : <span className="btnHover" style={{
                        display: "flex", alignItems: 'center', justifyContent: 'center',
                        margin: '0', cursor: 'pointer', width: '9rem', height: '4rem',
                        textAlign: 'center',
                        padding: '0 12px',
                        fontFamily: 'Manrope',
                        background: '#102300',
                        color: 'white',
                    }} onClick={() => createAccount()}> <RiAccountPinCircleLine size={20} style={{ marginRight: '10px' }} />Signup | Login</span>}
            </Right>
            <SearchResults style={resultBox ? { bottom: '0', } : { bottom: '-20rem', }}>
                {searchData && searchData.map(val => (
                    <React.Fragment key={val._id}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '10px', maxWidth: '270px' }}>
                            <NavLink to={'/article/' + val.Type + '/' + val.Keyword + '/' + val.BlogNumber}>
                                <img src={process.env.REACT_APP_URL_API + '/Thumbnails/' + val.Thumbnail} style={{
                                    width: '270px', height: '170px', borderRadius: '10px',
                                    boxShadow: '0 0 5px gray',
                                }} alt='thumbnail' />
                            </NavLink>
                            <P>{val.About}</P>
                            <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0', position: 'relative' }}>
                                <img src={process.env.REACT_APP_URL_API + '/Profile/' + val.AuthorProfile} style={{ width: '2rem', height: '2rem', borderRadius: '5rem' }} alt='profile' />
                                <div style={{ margin: '0 10px', fontFamily: 'Manrope', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '12px' }}>By <span style={{ fontWeight: 'bold' }}> {val.AuthorName}</span></span>
                                    <span style={{ fontSize: '8px' }}>{val.Date}</span>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ))}
                {searchData == '' && <SearchError> <img src={Oops} style={{ width: '18rem' }} alt='Error' /> </SearchError>}
            </SearchResults>
        </Headers>
    )
}


export default Header;


