import React, { useState } from 'react'
import styled from 'styled-components';
import logo from '../Images/logo.png'
import axios from 'axios';
import date from 'date-and-time';
import Loader from '../subComponents/Loader'

// react icons 
import fb from '../Images/fb.png'
import insta from '../Images/insta.png'
import twitter from '../Images/twitter.png'
import { BsArrowReturnRight } from 'react-icons/bs'

import '../App.css'
import { useDispatch } from "react-redux";
import { alertActive } from '../Redux'
import { alertDeactive } from '../Redux'


const FooterPanel = styled.div`
   display: grid;
   grid-template-areas: 'left left right right'
                        'middle middle middle middle';
   background: #102300;
   color: white;
   font-family: Manrope;

   @media (max-width: 1050px){
    grid-template-areas: 'left left left left'
                         'right right right right'
                         'middle middle middle middle';
   }

   @media (max-width: 420px){
        padding-top: 20px;
   }
  

`
const Left = styled.div`
   display: flex;
   grid-area: left;
   justify-content: center;
   align-items: center;

   @media (max-width: 650px){
    flex-direction: column;
   }
  

`
const Middle = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   grid-area: middle;
   border-top: 0.5px solid white;
   font-size: 12px;
   letter-spacing: 0.5px;
   padding: 10px 0;
   
   @media (max-width: 1050px){
     padding: 10px 0;
   }

`
const Right = styled.div`
   display: flex;
   grid-area: right;
   justify-content: center;
   align-items: center;

   @media (max-width: 650px){
    flex-direction: column;
   }

`
const h3 = {
    margin: '7px 0',
    fontWeight: 'lighter',
    fontSize: '14px',
}

const Div = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 20px;
    justify-content: center;

    @media (max-width: 650px){
        width: 95%;
    }
    @media (max-width: 420px){
        width: 95%;
        padding: 5px 20px;
    }

`
const input = {
    padding: '8px',
    width: '12rem',
    border: 'none',
    outline: 'none',
    fontFamily: 'Manrope',
    borderRadius: '2px',
}

const button = {
    display: 'flex',
    justifyContent: 'center',
    padding: '7px',
    border: '1px solid #3b7d03',
    color: 'white',
    background: '#3b7d03',
    margin: ' 0 5px',
    cursor: 'pointer',
    borderRadius: '2px',
    width: '93px',
}

const socialIcons = {
    width: '2rem',
}

function Footer() {
    const now = new Date();
    const [loader, setLoader] = useState(false)
    const [email, setEmail] = useState('');
    const dispatch = useDispatch()

    const Subscribe = () => {
        setLoader(true)
        axios.post(process.env.REACT_APP_URL_API + '/sources/subscribe', {
            email: email,
            date: date.format(now, 'DD MMM YYYY')
        })
            .then(res => {
                setLoader(false)
                dispatch(alertActive('success', res.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)
            })
            .catch(err => {
                setLoader(false)
                dispatch(alertActive('error', err.response.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)
            })
    }

    return (
        <>
            <FooterPanel>
                <Left>
                    <Div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            <img style={{ width: '2rem' }} src={logo} alt='logo' />
                            <h3 style={{ fontFamily: 'Manrope', margin: '0 10px' }}>TechBitLife</h3>
                        </span>
                        <span style={{ fontSize: '12px', width: '15rem', margin: '10px 0' }}>Read your favourite blogs and subscribe to our daily newsletter ! </span>
                    </Div>
                    <Div>
                        <h3 style={h3}>Other links</h3>
                        <div>
                            <BsArrowReturnRight size={10} />
                            <span style={{
                                fontSize: '12px',
                                margin: '0 8px'
                            }}>hindi.techbitlife.com</span>
                        </div>
                        <div style={{ marginBottom: '1rem' }}>
                            <BsArrowReturnRight size={10} />
                            <span style={{
                                fontSize: '12px',
                                margin: '0 8px'
                            }}>web@techbitlife.com</span>
                        </div>
                    </Div>
                </Left>
                <Middle>
                    <span>2023 copyright © techbitlife.com</span>
                    <span style={{ color: 'gray', fontSize: '10px', }}>V ~ 1.0.0</span>
                </Middle>
                <Right>
                    <Div>
                        <h3 style={h3}>Social media handles</h3>
                        <div style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
                            {/* facebook  */}
                            <a style={{ marginRight: '8px', cursor: 'pointer' }}>
                                <img src={fb} style={socialIcons} />
                            </a>
                            {/* Instagram  */}
                            <a style={{ margin: '0 8px', cursor: 'pointer' }}>
                                <img src={insta} style={socialIcons} />
                            </a>
                            {/* Youtube  */}
                            <a style={{ margin: '0 8px', cursor: 'pointer' }}>
                                <img src={twitter} style={socialIcons} />
                            </a>

                        </div>
                    </Div>
                    <Div style={{ borderRight: 'none' }}>
                        <h3 style={h3}>Get the latest updates direct to your inbox.</h3>
                        <div style={{ margin: '10px 0', display: 'inline-flex' }}>
                            <input style={input} type="email" name='email' placeholder="YOUR-EMAIL" onChange={(e) => setEmail(e.target.value)} />
                            <button style={button} onClick={Subscribe}>{loader ? <Loader /> : <>SUBSCRIBE </>} </button>
                        </div>
                    </Div>
                </Right>
            </FooterPanel>
        </>
    )
}

export default Footer;