import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { NavLink } from "react-router-dom";




const SearchBlogsWindow = styled.div`
    // box-shadow: 0 5px 5px #e8e8e8;
    border-radius: 10px;
    margin: 10px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    max-height: 20rem;
    overflow: hidden;
    overflow-y: auto;

    @media (max-width: 550px) {
        width: 22rem;
    }
    @media (max-width: 360px) {
        width: 21rem;
    }
`
const BlogBox = {
    display: 'flex',
    margin: '8px 0',
    textDecoration: 'none',
    color: '#112300',
}
const BlogThumbnail = styled.img`
    width: 110px;
    height: 65px;
    border-radius: 8px;
`
const Authorprofile = styled.img`
    width: 32px;
    border-radius: 50px;

    @media (max-width: 420px) {
        width: 25px;
        font-size: 12px;
    }
`
const BlogInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px 16px;
    font-family: 'Roboto';
    padding: 6px 0;
`
const BlogName = styled.span`
    display: -webkit-box;
    font-weight: 500;
    overflow: hidden;
    width: 94%;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    @media (max-width: 550px) {
        height: 34px;
        width: 94%;
        font-size: 14px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
   
`

const AuthorDetails = styled.div`
    display: flex;
    align-items: center;
    margin: 8px 0; 

    @media (max-width: 550px) {
        font-size: 12px;
    }

`



function SearchBlogs(props) {
    const blogName = props.blogName;
    const [articles, setArticles] = useState(props.blogs)
    const [results, setResults] = useState([])

    useEffect(() => {
        let arr = [];
        articles.map(val => {
            if (val.Keyword.replace(/-/gi, "").match(blogName.toLowerCase().replace(/\s/g, '').replace(/[^a-zA-Z ]/g, '')) && blogName) {
                arr.push(val)
            }
        })
        setResults(arr);
    }, [blogName])

    return (
        <SearchBlogsWindow>
            {results && results.map(val => (
                <React.Fragment key={val._id}>
                    <NavLink style={BlogBox} to={'/article/' + val.Type + '/' + val.Keyword + '/' + val.BlogNumber}>
                        <BlogThumbnail src={process.env.REACT_APP_URL_API + '/Thumbnails/' + val.Thumbnail} />
                        <BlogInfo>
                            <BlogName>{val.Title}</BlogName>
                            <AuthorDetails>
                                <Authorprofile src={process.env.REACT_APP_URL_API + '/Profile/' + val.AuthorProfile} /> &nbsp; {val.AuthorName} <span style={{ fontSize: '10px', color: 'gray' }}> &nbsp; on {val.Date}</span>
                            </AuthorDetails>
                        </BlogInfo>
                    </NavLink>
                </React.Fragment>
            ))}

        </SearchBlogsWindow>
    )
}

export default SearchBlogs;