import { ALERT_ACTIVE } from "./alertType";
import { ALERT_DEACTIVE } from "./alertType";


const initialState = {
    call: false,
    status: '',
    msg: '',
}

export const alertReducer = (state = initialState, action) => {
    switch (action.type) {
        case ALERT_DEACTIVE: return {
            state
        }
        case ALERT_ACTIVE: return {
            call: true,
            status: action.status,
            msg: action.msg,
        }
        default: return state;
    }
}