import React from "react";
import styled from "styled-components";
// icons 
import { RiFacebookCircleLine } from 'react-icons/ri'
import { LuInstagram } from 'react-icons/lu'
import { FiTwitter } from 'react-icons/fi'
import { NavLink } from "react-router-dom";
import { RxEyeOpen } from 'react-icons/rx'
import { BsHeartFill } from 'react-icons/bs'

//
import topicTech from '../Images/topicTech.png'
import topicWeb from '../Images/topicWeb.png'
import topicProgramming from '../Images/topicProgramming.png'
import topicPhotography from '../Images/topicPhotography.png'
import topicFood from '../Images/topicFood.png'
import topicFitness from '../Images/topicFitness.png'
import topicTravel from '../Images/topicTravel.png'
import topicHealth from '../Images/topicHealth.png'



const RightContent = styled.div`
   display: flex;
   flex-direction: column;
   font-family: Manrope;
   width: 25%;
   margin-bottom: 2rem;

   @media (max-width: 1300px){
    flex-direction: column;
    width: 94%;
  } 
   @media (max-width: 900px){
    flex-direction: column;
    width: 90%;
  } 
`
const Author_Series_Container = styled.div`
   display: flex;
   flex-direction: column;

  @media (max-width: 1300px){
    flex-direction: row;
    justify-content: center;
  } 
   @media (max-width: 900px){
    flex-direction: column;
    width: 100%;
    margin: 10px;
  }  
`

const Author = styled.div`
   display: flex;
   position: relative;
   flex-direction: column;
   border: 1px solid lightgray;
   border-radius: 16px;
   max-height: 20rem;

   @media (max-width: 1300px){
    margin-right: 16px;
  } 
   @media (max-width: 900px){
    margin-right: 0;
  } 
`
const Series = styled.div`
   display: flex;
   position: relative;
   flex-direction: column;
   border: 1px solid lightgray;
   border-radius: 16px;
   margin-top: 24px;

   @media (max-width: 1300px){
    margin-top: 0;
    max-width: 26rem;
  } 
   @media (max-width: 900px){
    margin-top: 24px;
    max-width: 100%;
  } 
`

const H1 = styled.h1`
   font-weight: 600;
   padding: 10px 0;
   border-bottom: none;
   font-size: 24px;
   margin-top: 8px;
   margin-bottom: 6px;
   color: #112300;
   padding: 8px 14px;
   border-bottom: 1px solid lightgray;

   @media (max-width: 750px){
    font-size: 18px;
  } 
`
const H2 = styled.h2`
   font-weight: 500;
   border-bottom: 1px solid lightgray;
   margin: 0;
   font-size: 18px;
   padding: 8px 16px;
`
const Profile = styled.img`
    width: 4rem;
    height: 4rem;
    background: #ededed;
    border-radius: 8rem;
    padding: 4px;

`
const Authorinfo = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    margin: 16px 0;
`
const Author_SocialMediaHandles = styled.div`
    display: flex;
    margin: 8px 0;
    align-items: center;
`

const About = styled.div`
    display: flex;
    font-size: 13px;
    margin: 16px;
    font-weight: 500;
    max-width: 24rem;
`

const Thumbnail = styled.img`
    width: 100px;
    height: 64px;
    border-radius: 4px;
`

const SeriesBlogs = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 17rem;
    overflow: hidden;
    overflow-y: auto;
`
const BoxTitleEle = styled.div`
    display: flex;
    position: absolute;
    background: #3b7d05;
    color: white;
    font-size: 12px;
    right: 16px;
    top: -9px;
    padding: 2px 10px;
    border-radius: 2rem;
`
const OtherTopics = styled.div`
    display: flex;
    flex-direction : column;
    flex-wrap: wrap;
    margin-top: 24px;
    border: 1px solid lightgrey;
    border-radius: 20px;
    max-width: 50rem;
    width: 100%;


    @media (max-width: 1300px){
        margin: 0 auto;
        margin-top: 24px;
    } 
    @media (max-width: 900px){
        margin: 0 auto;
        margin-top: 24px;
        margin: 10px;
    } 
`
const TopicsImg = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 8px 14px;

    @media (max-width: 1300px){
        margin-right: 0;
        justify-content: left;
    } 
    @media (max-width: 385px){
        justify-content: center;
    } 
`
const OtherTopicImg = styled.a`
    display: flex;
    width: 48%;
    cursor: pointer;
    margin-right: 6px;
    margin-top: 6px;

    @media (max-width: 1320px){
        width: 46%;
    } 
    @media (max-width: 1300px){
        width: 24%;
    } 
    @media (max-width: 730px){
        width: 23%;
    } 
    @media (max-width: 600px){
        width: 30%;
    } 
    @media (max-width: 470px){
        width: 48%;
    } 
    @media (max-width: 385px){
        width: 45%;
    } 
    
`


function ArticlePageRightContent(props) {
    const authorInfo = props.authorInfo;
    const seriesData = props.seriesData;
    const seriesName = props.seriesName;
    return (
        <RightContent>
            <Author_Series_Container>
                <Author>
                    <H2>About author<BoxTitleEle>Author</BoxTitleEle></H2>
                    <Authorinfo>
                        <div style={{ display: 'flex', }}>
                            <Profile src={process.env.REACT_APP_URL_API + "/Profile/" + authorInfo.Profile} />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '16px', paddingTop: '4px' }}>
                                <strong>{authorInfo.Name}</strong>
                                <span style={{ fontSize: '12px' }}>from - {authorInfo.Country}</span>
                                <span style={{ fontSize: '10px', margin: '8px 0' }}>Member since - {authorInfo.DateOfJoinTBL}</span>
                                <Author_SocialMediaHandles>
                                    {authorInfo.Facebook &&
                                        <a href={authorInfo.Facebook}
                                            title="Facebook"
                                            target="_blank"><RiFacebookCircleLine size={24} style={{ marginRight: '16px', color: '#112300' }} /></a>}
                                    {authorInfo.Instagram &&
                                        <a href={authorInfo.Instagram}
                                            title="Instagram"
                                            target="_blank"> <LuInstagram size={20} style={{ marginRight: '16px', color: '#112300' }} /></a>}
                                    {authorInfo.Twitter &&
                                        <a href={authorInfo.Twitter}
                                            title="Twitter"
                                            target="_blank"><FiTwitter size={20} style={{ color: '#112300' }} /></a>}
                                </Author_SocialMediaHandles>
                            </div>
                        </div>
                        <About>
                            {authorInfo.PersonalInfo}
                        </About>
                    </Authorinfo>
                </Author>
                <Series>
                    <H2>{seriesName}<BoxTitleEle>Series</BoxTitleEle></H2>
                    <SeriesBlogs>
                        <br></br>
                        {seriesData.map(val => (
                            <NavLink key={Math.random() * 1000} to={"/article/" + val.Type + '/' + val.Keyword + '/' + val.BlogNumber} style={{ display: 'flex', padding: '0 16px', textDecoration: 'none', color: '#102300', marginBottom: '8px' }}>
                                <Thumbnail src={process.env.REACT_APP_URL_API + '/Thumbnails/' + val.Thumbnail} />
                                <div style={{ display: 'flex', flexDirection: 'column', margin: '0 16px' }}>
                                    <span style={{ fontSize: '13px', fontWeight: '600' }}>{val.Title} </span>
                                    <span style={{ fontSize: '10px', margin: '4px 0' }}>Published on : {val.Date}</span>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ display: 'flex', alignItems: 'center', fontSize: '13px', fontWeight: '600' }}>{val.TotalViews}  &nbsp; <RxEyeOpen size={16} />  </span>
                                        &nbsp; &nbsp; &nbsp;
                                        <span style={{ display: 'flex', alignItems: 'center', fontSize: '13px', margin: '4px 0', fontWeight: '600' }}> {val.TotalLikes.length} &nbsp; <BsHeartFill color="red" size={12} /></span>
                                    </div>
                                </div>

                            </NavLink>
                        ))}
                    </SeriesBlogs>
                </Series>
            </Author_Series_Container>
            <OtherTopics>
                <H1>Explore other topics on TechBitLife</H1>
                <TopicsImg>
                    <OtherTopicImg href='/tech'> <img src={topicTech} style={{ width: '100%' }} /> </OtherTopicImg>
                    <OtherTopicImg href='/web'> <img src={topicWeb} style={{ width: '100%' }} /> </OtherTopicImg>
                    <OtherTopicImg href='/programming'> <img src={topicProgramming} style={{ width: '100%' }} /> </OtherTopicImg>
                    <OtherTopicImg href='/photography'> <img src={topicPhotography} style={{ width: '100%' }} /> </OtherTopicImg>
                    <OtherTopicImg href='/food'> <img src={topicFood} style={{ width: '100%' }} /> </OtherTopicImg>
                    <OtherTopicImg href='/fitness'> <img src={topicFitness} style={{ width: '100%' }} /> </OtherTopicImg>
                    <OtherTopicImg href='/travel'> <img src={topicTravel} style={{ width: '100%' }} /> </OtherTopicImg>
                    <OtherTopicImg href='/health'> <img src={topicHealth} style={{ width: '100%' }} /> </OtherTopicImg>
                </TopicsImg>
            </OtherTopics>
        </RightContent >
    )
}

export default ArticlePageRightContent;