import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Alert from '../subComponents/Alert'
import NavMenu from "../subComponents/NavMenu";
import date from 'date-and-time';
import Loader from '../subComponents/Loader'
import axios from 'axios'
import SearchBlogs from "../subComponents/SearchBlogs";
import { useDispatch, useSelector } from "react-redux";
import { alertActive } from '../Redux'
import { alertDeactive } from '../Redux'

import { RiSearchLine } from 'react-icons/ri'
import { FiEye } from 'react-icons/fi'
import { BsFillBellFill } from 'react-icons/bs'
import { FiMail } from 'react-icons/fi'
import { IoMdArrowDropleft } from 'react-icons/io'
import { IoMdArrowDropright } from 'react-icons/io'
import { AiOutlineArrowRight } from 'react-icons/ai'
import { BsArrowRightCircle } from 'react-icons/bs'
import { TbWriting } from 'react-icons/tb'


// Images 
import Banner1 from '../Images/Banner1.png'
import Banner2 from '../Images/Banner2.png'
import Banner3 from '../Images/Banner3.png'
import squareBanner1 from '../Images/squareBanner1.png'
import squareBanner2 from '../Images/squareBanner2.png'
import squareBanner3 from '../Images/squareBanner3.png'
import squareBanner4 from '../Images/squareBanner4.png'
import logo from '../Images/logo.png'
import profile from '../Images/profile.png'
import fb from '../Images/fb.png'
import insta from '../Images/insta.png'
import twitter from '../Images/twitter.png'
import Reward from '../Images/reward.jpg'
import CertificatePNG from '../Images/certificate.png'
import WhyJoinHeadIMG from '../Images/whyJoinHead.png'
import WhyJoinHeadMobIMG from '../Images/WhyJoinHeadMob.png'

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/core';
import { NavLink } from "react-router-dom";
SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade]);



const HomePage = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
`

const HomePageHeader = styled.div`
   display: flex;
//    width: 100%;
   width: 1200px;
   margin-top: 140px;
   position: relative;
   height: 500px;
   align-items: center;
   justify-content: center;
   overflow: hidden;
   border-radius: 20px;

   @media (max-width: 1200px) {
    width: 95%;
    height: 480px;
  }
   @media (max-width: 1024px) {
    height: 436px;
  }
   @media (max-width: 950px) {
    height: 376px;
  }
   @media (max-width: 800px) {
    height: 332px;
  }
   @media (max-width: 650px) {
    width: 100%;
    border-radius: 0;
    height: 285px;
    margin-top: 98px;
  }
   @media (max-width: 550px) {
    height: 242px;
  }
   @media (max-width: 420px) {
    height: 186px;
  }

`
const ImageCover = styled.div`
   display: flex;
   position: relative;
   width: 100%;
   height: 100%;
`
const Image = styled.img`
   position: absolute;
   width: 100%;
   height: 100%;
`
const Button = styled.button`
   display: flex;
   align-items: center;
   position: absolute;
   right: 1%;
   top: 3%;
   z-index: +2;
   border: none;
   padding: 10px;
   border-radius: 30px;
   background: linear-gradient(183deg, #99E100, #0D8B02);
   font-weight: bold;
   color: white;
   cursor: pointer;

   @media (max-width: 650px) {
    padding: 5px;
    border-radius: 4rem;
  }
`
const IntroBox = styled.div`
   display: flex;
   flex-direction: column;
   position: relative;
   width: 556px;
   padding: 16px;
   margin: 8px;
   z-index: +2;
   border-radius: 12px;
   margin-top: -2%;
   background: white;

   @media (max-width: 950px) {
    width: 440px;
  }
   @media (max-width: 650px) {
    width: 372px;
  }
   @media (max-width: 550px) {
    width: 300px;
  }
`
const LOGO = styled.img`
   width: 36px;
   position: absolute;
   right: 2%;
   top: 5%;
   border-radius: 4px;

   @media (max-width: 650px) {
    width: 30px;
  }
`
const H1 = styled.h1`
   font-family: 'Roboto';
   font-size: 48px;
   width: 80%;
   margin: 10px;
   margin-top: 10px;
   margin-bottom: 5px;
   color: #3A4333;

   @media (max-width: 950px) {
    font-size: 34px;
  }

  @media (max-width: 650px) {
    font-size: 28px;
  }
  @media (max-width: 550px) {
    font-size: 24px;
  }
`

const SubTitle = styled.span`
    margin: 0 16px;
    font-family: Manrope;
    font-size: 14px;

    @media (max-width: 650px) {
        font-size: 12px;
    }
`

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid gray;
    width: 94%;
    margin: 14px auto;
    padding: 10px 0px;

    @media (max-width: 650px) {
        width: 90%;
    }
`

const Input = styled.input`
    border: none;
    font-family: Manrope;
    width: 95%;
    outline: none;
    font-size: 16px;
`

const SwiperBox = styled.div`
    display: flex;
    width: 800px;
    height: 450px;
    border-radius: 16px;
    margin: 16px 0;
    border: 1px solid #102300;
    overflow: hidden;

    @media (max-width: 850px) {
        width: 700px;
        height: 394px;
    }
    @media (max-width: 750px) {
        width: 600px;
        height: 338px;
    }
    @media (max-width: 650px) {
        width: 500px;
        height: 281px;
    }
    @media (max-width: 550px) {
        width: 400px;
        height: 225px;
    }
    @media (max-width: 450px) {
        width: 380px;
        height: 214px;
    }
    @media (max-width: 400px) {
        width: 340px;
        height: 191px;
    }
`

const WhyJoinHead = styled.img`
    width: 100%;
    margin-top: 100px;
    margin-bottom: 70px;

`

const TopRunning = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    border-radius: 10px;
    margin: 36px auto;
`

const TopRunning_AlertMsg = styled.div`
    margin: 0 auto;
    font-family: Manrope;
    color: #3b403b;
    font-weight: 500;
    font-size: 16px;

    @media (max-width: 420px) {
        font-size: 12px; 
        margin: 0;
    }
`


const SlideImgCover = styled.div`
    display: flex;
    width: 100%;
    height: 214px;
    border-radius: 4px;
    justify-content: center;

    @media (max-width: 1300px) {
        height: 200px;
    }
    @media (max-width: 900px) {
        height: 164px;
    }
    @media (max-width: 420px) {
        height: 156px;
    }


`
const ArticleInfo = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 8px;
    font-family: Manrope;
    margin-top: 24px;
    color: #3A4333;  
`

const P = styled.div`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Manrope; 
  font-size: 18px; 
  font-weight: 700; 
  height: 48px;
  margin: 8px 0px;
  margin-bottom: 20px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: 900px) {
    font-size: 16px;
    margin-bottom: 16px;
    
 }
  @media (max-width: 650px) {
    font-size: 14px;
    margin-bottom: 8px;
    height: 40px;
 }

`

const H2 = styled.h2`
   font-size: 32px;
   font-family: Manrope;
   color: #1e3d00;
   text-align: center;

   @media (max-width: 900px) {
    font-size: 24px;
  }
   @media (max-width: 650px) {
    font-size: 18px;
  }
`
const H3 = styled.h3`
   font-size: 24px;
   font-family: Manrope;
   color: #1e3d00;

   @media (max-width: 900px) {
    font-size: 18px;
  }
   @media (max-width: 650px) {
    font-size: 18px;
  }
`
const Subscription = styled.div`
   display: grid;
   grid-template-columns: 1fr 1fr;
   background: #102300;
   padding: 4rem 0;
   width: 100%;
   color: white;
   margin: 24px 0;

   @media (max-width: 650px) {
       padding: 1rem 0;
       grid-template-rows: 1fr 1fr !important;
       grid-template-columns: 1fr;
       height:18rem;
  }
`

const SubscribeBtn = styled.button`
    display: flex;
    justify-content: center;
    width: 8rem;
    margin: 16px 0px;
    border: none;
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    background: no-repeat;
    font-family: Manrope;
    font-weight: 700;
    border: 1px solid #6be912;
    color: white;
`

const H1_Subscription = styled.span`
   font-size: 56px;
   line-height: 56px;
   width: 90%;
   font-family: 'Manrope', sans-serif;
   font-weight: 400;

   @media (max-width: 1150px) {
    font-size: 48px;
  }
   @media (max-width: 840px) {
    font-size: 42px;
  }
   @media (max-width: 768px) {
    font-size: 36px;
  }
  
   @media (max-width: 700px) {
    font-size: 36px;
    width: 80%;
  }
   @media (max-width: 425px) {
    line-height: 40px;
    font-size: 32px;
  }
   @media (max-width: 370px) {
    font-size: 30px;
  }

`
const Text = styled.div`
   display: flex;
   align-items: center;
   font-family: manrope;
   justify-content: right;
   width: 80%;
   margin: 0 auto;

   @media (max-width: 700px) {
      justify-content: left;
  }
`
const Input_Subscription = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
`
const Rewards = styled.div`
   display: grid;
   flex-direction: column;
   font-family: manrope;

`

const RewardsGrid = styled.div`
   display: grid;
   grid-template-columns: 1fr 0.5fr 1fr;
   margin: 0px auto;
   width: 70%;
   align-items: center;

   @media (max-width: 1100px) {
    width: 90%;
  }
   @media (max-width: 800px) {
    width: 95%;
  }
   @media (max-width: 700px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  }
`
const Rewards_Text = styled.span`
   font-size: 14px;
   text-align: center;
   margin: 24px 0;
   font-weight: 500;
   width: 95%;
   color: #3A4333;

   @media (max-width: 420px) {
    font-size: 12px;
    width: 100%;
  }

`

const Cert_Elig = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;

`
const Count = styled.div`
   display: flex;
   align-items: center;
   font-family: Roboto;
   color: #3A4333;
`
const Certificate = styled.div`
   display: flex;
   justify-content: center;
   align-itenms: center;
`

const MiddleLine = styled.div`
   display: flex;
   position: relative;
   justify-content: center;

    @media (max-width: 700px) {
      display: none;
   }

`
const LeftArrow = styled.div`
   display: flex;
   position: absolute;
   top: -18px;
   margin-right: 14px;
`
const RightArrow = styled.div`
   display: flex;
   position: absolute;
   bottom: -18px;
   margin-left: 14px;
`
const SignupOpt = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   margin: 32px 0;
   font-family: manrope;
   margin-top: 4rem;
`
const SignUpButton = styled.button`
   font-size: 24px;
   color: white;
   border: none;
   outline: none;
   padding: 8px 0;
   width: 300px;
   background: linear-gradient(135.78deg,#288C04 6.63%,#99E100 136.26%);
   border-radius: 62px;
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;

`
const Social = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
`

const Redirection = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 33px;
   margin-bottom: 42px;
`

const SquarePosterImg = styled.img`
   width: 500px;
   margin: 8px 16px;

   @media (max-width: 1100px) {
    width: 400px;
    margin: 4px 8px;
 }
   @media (max-width: 900px) {
    width: 330px;
    margin: 4px 8px;
 }
   @media (max-width: 730px) {
    width: 300px;
    margin: 4px 8px;
 }
`
const SquarePosters = styled.div`
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
`

function Home(props) {
    const dispatch = useDispatch()
    const now = new Date();
    const username = sessionStorage.getItem('username')
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [loader, setLoader] = useState(false)
    const [email, setEmail] = useState('')
    const blogList = useSelector(state => state && state.blogs.blogs)


    // For Swiper Slider
    window.addEventListener('resize', () => {
        setScreenWidth(window.innerWidth)
    })

    const signIn = () => {
        props.signIn(true)
    }

    const slidesPerView = () => {
        if (screenWidth > 1300)
            return 4;
        else
            if (screenWidth <= 1300 && screenWidth > 650)
                return 3;
            else
                if (screenWidth <= 650 && screenWidth > 550)
                    return 2.5;
                else
                    if (screenWidth <= 550 && screenWidth > 450)
                        return 2;
                    else
                        if (screenWidth <= 450)
                            return 1.5;
    }
    const writeArticle = () => {
        sessionStorage.setItem('activeMenu', 'profile')
        if (username) {
            window.location.href = '/profile'
        } else {
            dispatch(alertActive('error', 'Please sign in to write articles.'))
            setTimeout(() => {
                dispatch(alertDeactive())
            }, 3000)
        }
    }

    const Subscribe = () => {
        setLoader(true)
        axios.post(process.env.REACT_APP_URL_API + '/sources/subscribe', {
            email: email,
            date: date.format(now, 'DD MMM YYYY')
        })
            .then(res => {
                setEmail('')
                setLoader(false)
                dispatch(alertActive('success', res.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)
            })
            .catch(err => {
                setLoader(false)
                dispatch(alertActive('error', err.response.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)
            })
    }

    const [searchBlogWindow, setSearchBlogWindow] = useState(false)
    const [blogName, setBlogName] = useState('')
    const searchBlog = (e) => {
        if (e.target.value) {
            setSearchBlogWindow(true)
            setBlogName(e.target.value)
        }
        if (!e.target.value) {
            setSearchBlogWindow(false)
        }
    }

    return (
        <>
            <NavMenu />
            <HomePage>
                <HomePageHeader>
                    <Button onClick={() => writeArticle()}> <TbWriting size={22} /></Button>
                    <ImageCover>
                        <Swiper
                            style={{ width: '100%', height: '100%' }}
                            spaceBetween={30}
                            effect={"fade"}
                            // navigation={true}
                            pagination={{
                                clickable: true
                            }}
                            autoplay={{
                                "delay": 2500,
                                "disableOnInteraction": false
                            }}
                            className="mySwiper"
                        >
                            <SwiperSlide>
                                <Image src={Banner1} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Image src={Banner2} />
                            </SwiperSlide>
                            <SwiperSlide>
                                <Image src={Banner3} />
                            </SwiperSlide>
                        </Swiper>
                    </ImageCover>
                </HomePageHeader>
                <IntroBox>
                    <LOGO src={logo} />
                    <H1>Welcome to TechBitLife Blogs.</H1>
                    <SubTitle>Reading | Writing | Sharing</SubTitle>
                    <SearchBar>
                        <Input type="text" placeholder="Search for a Blog.." onChange={(e) => searchBlog(e)} />
                        <RiSearchLine size={20} color="gray" />
                    </SearchBar>
                </IntroBox>
                {/* Search Blogs window  */}
                {searchBlogWindow && <SearchBlogs blogName={blogName} blogs={props.blogs} />}
                <WhyJoinHead src={WhyJoinHeadIMG} />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <SquarePosters>
                        <SquarePosterImg src={squareBanner1} />
                        <SquarePosterImg src={squareBanner2} />
                    </SquarePosters>
                    <SquarePosters>
                        <SquarePosterImg src={squareBanner3} />
                        <SquarePosterImg src={squareBanner4} />
                    </SquarePosters>
                </div>
                <TopRunning>
                    <H2 style={{ textAlign: 'left' }}>Top running...</H2>
                    {blogList != '' ?
                        <Swiper style={{ height: '100%', width: '100%', }}
                            slidesPerView={slidesPerView()}
                            // loop={true}
                            spaceBetween={20}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            className="mySwiper">
                            {props.blogs && props.blogs.map(val => (
                                <SwiperSlide key={val.BlogNumber} style={{ background: 'white', overflow: 'hidden', borderBottom: '3px solid #102300', height: '100%' }}>
                                    <SlideImgCover>
                                        <NavLink style={{ width: '100%', borderRadius: '8px', overflow: 'hidden' }} to={"/article/" + val.Type + '/' + val.Keyword + '/' + val.BlogNumber}>
                                            <img style={{ width: '100%', height: '100%' }} src={process.env.REACT_APP_URL_API + '/Thumbnails/' + val.Thumbnail} />
                                        </NavLink>
                                    </SlideImgCover>
                                    <ArticleInfo>
                                        <span style={{ display: 'flex', fontSize: '12px', alignItems: 'center', position: 'absolute', right: '4%' }}> <FiEye /> &nbsp; &nbsp;{val.TotalViews}</span>
                                        <span style={{ fontSize: '12px' }}>#{val.Type}</span>
                                        <P>{val.Title}</P>
                                    </ArticleInfo>
                                </SwiperSlide>
                            ))}

                        </Swiper>
                        : <TopRunning_AlertMsg>Sorry. No blogs are available at this time  :(</TopRunning_AlertMsg>
                    }
                </TopRunning>
                {/* Subscription  */}
                <Subscription>
                    <Text>
                        <H1_Subscription><span>Never miss daily</span> <span style={{color:'#9AF108'}}>updates...</span></H1_Subscription>
                    </Text>
                    <Input_Subscription>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
                            <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid white', padding: '4px 0' }}>
                                <FiMail size={20} color="#90D900"/> &nbsp; &nbsp;
                                <input type="text" value={email} placeholder="Enter your email here." style={{
                                    border: 'none', background: 'none', width: '100%', outline: 'none', color: 'white', padding: '4px 0', fontSize: '16px', fontFamily: 'Manrope', letterSpacing: '0.8px',
                                }} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <SubscribeBtn onClick={() => Subscribe()}> {loader ? <Loader /> : <>SUBSCRIBE </>}</SubscribeBtn>
                            <span style={{ fontSize: "14px", display: 'flex', alignItems: 'center', margin: '16px 0', fontFamily: 'Manrope' }}><BsFillBellFill size={18} color="#f5a742" />&nbsp;&nbsp;Subscribe for daily updates!</span>
                        </div>
                    </Input_Subscription>
                </Subscription>
                <Rewards>
                    <img src={Reward} style={{ width: '8rem', margin: '0 auto' }} />
                    <H2>Get rewarded for your milestone</H2>
                    <RewardsGrid>
                        <Cert_Elig>
                            <Count>
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '40px', fontWeight: 'bold' }}>1M</span>
                                    <span style={{ fontSize: '12px' }}>Views</span>
                                </div>
                                <span style={{ background: '#3a4332', height: '60px', width: '2px', margin: '0 16px', borderRadius: '5rem' }}></span>
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                    <span style={{ fontSize: '40px', fontWeight: 'bold' }}>1K</span>
                                    <span style={{ fontSize: '12px' }}>Likes</span>
                                </div>
                            </Count>
                            <Rewards_Text>When you reach 1 million total views and 1,000 likes,
                                You will get certificate from TechBitLife.</Rewards_Text>
                        </Cert_Elig>
                        <MiddleLine>
                            <LeftArrow>
                                <IoMdArrowDropleft size={64} color="#102300" />
                            </LeftArrow>
                            <span style={{ display: 'flex', width: '2px', height: '14rem', background: '#102300', margin: '0 auto', borderRadius: '5rem' }}></span>
                            <RightArrow>
                                <IoMdArrowDropright size={64} color="#102300" />
                            </RightArrow>
                        </MiddleLine>
                        <Certificate>
                            <img src={CertificatePNG} style={{ width: '320px' }} />
                        </Certificate>
                    </RewardsGrid>
                </Rewards>
                <SignupOpt>
                    <img src={profile} style={{ width: '5rem' }} />
                    <H2>Create your account and start writing.</H2>
                    <SignUpButton onClick={() => signIn()}>Signup Now <AiOutlineArrowRight size={20} style={{ marginLeft: '8px' }} /></SignUpButton>
                    <span style={{ fontFamily: 'Manrope', fontSize: '12px', margin: '8px 0', color: '#58654D' }}>Do you already have an account? <span onClick={() => signIn()} style={{ fontWeight: 'bold', cursor: 'pointer' }}>Sign-in.</span></span>
                </SignupOpt>
                <Social>
                    {/* <H2>Follow Us</H2> */}
                    <H3>Our Social Media Handles</H3>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={fb} style={{ width: '48px', margin: '0 10px' }} />
                        <img src={insta} style={{ width: '48px', margin: '0 10px' }} />
                        <img src={twitter} style={{ width: '48px', margin: '0 10px' }} />
                    </div>
                </Social>
                <Redirection>
                    {/* <H2 style={{ color: '#3A4333' }}>Let's get started.</H2> */}
                    <NavLink to='/tech' onClick={() => sessionStorage.setItem('activeMenu', 'tech')}>  <BsArrowRightCircle size={32} style={{ cursor: 'pointer', color: '#3c4832' }} /> </NavLink>
                </Redirection>
            </HomePage>
        </>
    )
}

export default Home;