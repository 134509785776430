import React, { useState, useEffect } from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import './App.css'

// Components
import Menu from "./subComponents/Menu";
import Headers from './subComponents/header'

import Favourites from './components/Favourites'
import Profile from "./components/Profile";
import Signup from "./subComponents/Signup";
import BugReport from './subComponents/BugReport'
import Connect from "./subComponents/Connect";
import ArticlePage from "./components/ArticlePage";
import About from "./components/About";
import Collection from "./subComponents/Collection";
import Tutorial from "./subComponents/Tutorial";
import Home from "./components/Home";
import Footer from "./subComponents/Footer";
import Alert from "./subComponents/Alert";


const headerDiv = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  position: 'fixed',

}

const contentDiv = {
  position: 'relative',
  transition: '0.5s all',
}



function App() {
  const [openNav, setOpenNav] = useState(false)
  const [signupForm, setSignupForm] = useState(false)
  const [ReportBugPanel, setReportBugPanel] = useState(false)
  const [ConnectWithUsPanel, setConnectWithUsPanel] = useState(false)
  const [aboutPanel, setAboutPanel] = useState(false)
  const [tutePanel, setTutePanel] = useState(false)
  const userName = sessionStorage.getItem('username')

  const alertCall = useSelector(state => state && state.alert.call)
  const alertStatus = useSelector(state => state && state.alert.status)
  const alertMsg = useSelector(state => state && state.alert.msg)

  // articles 
  const [blogs, setBlogs] = useState([])

  const openMenu = (Data) => {
    setOpenNav(Data)
  }
  const closefun = (Data) => {
    setOpenNav(Data)
  }

  const createAccount = (data) => {
    setOpenNav(false)
    setSignupForm(data)
  }

  const ReportBug = (data) => {
    setOpenNav(false)
    setReportBugPanel(data)
  }

  const closeBugReportPanel = (data) => {
    setOpenNav(false)
    setReportBugPanel(data)
  }
  const ConnectWithUs = (data) => {
    setOpenNav(false)
    setConnectWithUsPanel(data)
  }
  const closeConnectPanel = (data) => {
    setOpenNav(false)
    setConnectWithUsPanel(data)
  }
  const closeAboutPanel = (data) => {
    setOpenNav(false)
    setAboutPanel(data)
  }
  const tutorial = (data) => {
    setOpenNav(false)
    setTutePanel(data)
  }
  const closeTutePanel = (data) => {
    setOpenNav(false)
    setTutePanel(data)
  }

  const articles = (data) => {
    setBlogs(data)
  }


  return (
    <>
      {/* Alert  */}
      {alertCall &&
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'fixed', left: '0', bottom: '0', zIndex: +10 }}>
          {alertCall && <Alert alertMsg={alertMsg} alertStatus={alertStatus} />}
        </div>
      }

      <Menu openMenu={openNav} closefun={closefun} createAccount={createAccount} ReportBug={ReportBug} ConnectWithUs={ConnectWithUs} tutorial={tutorial} />
      <div style={openNav ? { ...contentDiv, left: '20rem' } : { ...contentDiv, left: '0' }}>
        <Headers fun={openMenu} createAccount={createAccount} articles={articles} style={headerDiv} />

        {signupForm ? <Signup createAccount={createAccount} /> : ''}
        {ReportBugPanel ? <BugReport closeBugReportPanel={closeBugReportPanel} /> : ''}
        {ConnectWithUsPanel ? <Connect closeConnectPanel={closeConnectPanel} /> : ''}
        {aboutPanel ? <About closeAboutPanel={closeAboutPanel} /> : ''}
        {tutePanel ? <Tutorial closeTutePanel={closeTutePanel} /> : ''}
        <Routes>
          <Route exact path="/" element={<Home signIn={createAccount} blogs={blogs} />} />
          <Route exact path="/tech" element={<Collection category={'technology articles.'} type={'tech'} />} />
          <Route path="web" element={<Collection category={'web articles'} type={'web'} />} />
          <Route path="programming" element={<Collection category={'programming articles'} type={'programming'} />} />
          <Route path="photography" element={<Collection category={'photography articles'} type={'photography'} />} />
          <Route path="food" element={<Collection category={'food articles'} type={'food'} />} />
          <Route path="fitness" element={<Collection category={'fitness articles'} type={'fitness'} />} />
          <Route path="travel" element={<Collection category={'travel articles'} type={'travel'} />} />
          <Route path="health" element={<Collection category={'health articles'} type={'health'} />} />
          <Route path="fashion" element={<Collection category={'fashion articles'} type={'fashion'} />} />
          <Route path="sport" element={<Collection category={'sport articles'} type={'sport'} />} />
          <Route path="fav" element={<Favourites />} />
          {userName && <Route path="profile" element={<Profile />} />}
          <Route path="about" element={<About />} />
          {/* Article Page  */}
          <Route path="/article/:type/:keyword/:blogNumber" element={<ArticlePage />} />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;
