import { ALERT_ACTIVE } from './alertType'
import { ALERT_DEACTIVE } from './alertType'


export const alertActive = (status, msg) => {
    console.log(status + msg)
    return {
        type: ALERT_ACTIVE,
        status: status,
        msg: msg
    }
}

export const alertDeactive = (status, msg) => {
    return {
        type: ALERT_DEACTIVE,
        status: status,
        msg: msg
    }
}