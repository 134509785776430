import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import axios from "axios";
import { useParams } from "react-router-dom";
import Alert from '../subComponents/Alert'
import date from 'date-and-time';
import shortNumber from 'short-number'
import Loader from '../subComponents/Loader'
import ArticlePageRightContent from '../subComponents/ArticlePageRightContent'
import Comments from "../subComponents/Comments";
import removeUndefinedObjects from 'remove-undefined-objects';
import { useDispatch } from "react-redux";
import { alertActive } from '../Redux'
import { alertDeactive } from '../Redux'

// REACT ICONS 
import { BsLink45Deg } from 'react-icons/bs'
import { BsBookmarkPlus } from 'react-icons/bs'
import { BsHeart } from 'react-icons/bs'
import { BsHeartFill } from 'react-icons/bs'
import { BiCategory } from 'react-icons/bi'
import { RxEyeOpen } from 'react-icons/rx'
import { BiSolidCommentDetail } from 'react-icons/bi'
import { GoCommentDiscussion } from 'react-icons/go'


import '../App.css'

const ArticlePageContent = styled.div`
   display: flex;
   margin-top: 5rem; 
   position: relative; 
   justify-content: center;

   @media (max-width: 650px){
    margin-top: 4rem; 
  } 
   @media (max-width: 1300px){
    flex-direction: column;
    align-items: center;
  } 
   @media (max-width: 750px){
    align-items: initial;
    margin-left: 16px; 
  } 
`

const ArticleContainer = styled.div`
   display: flex; 
   flex-direction: column;
   margin-bottom: 2rem;
   margin-right: 2rem; 
   min-height: 40rem; 
   width: 56%;
   font-family: Manrope;
   position: relative; 

   @media (max-width: 1300px){ 
     width: 64%; 
     margin-right: 0; 
   }
   @media (max-width: 1050px){ 
     width: 72%; 
   }

   @media (max-width: 900px){ 
     width: 80%; 
   }

   @media (max-width: 650px){
     width: 90%; 
     padding: 0 8px
   }
`
const Article = styled.div`
   display: flex; 
`
const Title = styled.div`
    font-size: 28px;
    font-weight: bold;
    color: #102300;

    @media (max-width: 420px){
        font-size: 24px; 
    }
`
const Reactions = styled.div`
   display: flex; 
   align-items: center;
   position: relative;
   border: 1px solid lightgray;
   border-radius: 5px;
   padding: 15px 20px;
   margin-top: 1rem;
`
const CommentSection = styled.div`
   display: flex;
   flex-direction: column;
`

function ArticlePage() {

    const now = new Date();
    var { type, keyword, blogNumber } = useParams();
    const [articleData, setArticleData] = useState([])
    const [articleId, setArticleId] = useState('');
    const [comments, setComments] = useState([]);
    const [likes, setLikes] = useState()
    const [currentUserLike, setCurrentUserLike] = useState()
    const username = sessionStorage.getItem('username')
    const token = sessionStorage.getItem('token')
    const dispatch = useDispatch()
    const [authorInfo, setAuthorInfo] = useState([])
    const [seriesData, setSeriesData] = useState([])
    const [seriesName, setSeriesName] = useState('')

    // Viewcount 
    useEffect(() => {
        let viewCount = [];
        let blogView = sessionStorage.getItem('blogView');
        if (!blogView || !blogView.includes(blogNumber)) {
            //   Counting view +1 
            viewCount.push(blogNumber)
            sessionStorage.setItem('blogView', JSON.stringify(viewCount))
            axios.post(process.env.REACT_APP_URL_API + '/blogView/' + blogNumber)
                .then(response => {
                    console.log()
                })
                .catch(error => {
                    console.log()
                })
        }
        else {
            console.log()
        }
    }, [blogNumber])


    // Scrolling top when new article request arrive
    useEffect(() => {
        // window.scrollTo(0, 0);
    }, [type, keyword, blogNumber])

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL_API + `/article/${type}/${keyword}/${blogNumber}`)
            .then(res => {
                setArticleData([res.data.result]);
                setArticleId(res.data.result._id);
                setAuthorInfo(res.data.authorInfo)
                setSeriesData(res.data.seriesData)
                setSeriesName(res.data.result.Series)
                // GET ALL COMMENTS OF ARTICLE 
                axios.get(process.env.REACT_APP_URL_API + '/comments/' + res.data.result._id)
                    .then(res => {
                        const cData = Object.assign({}, res.data.result)
                        const comments = removeUndefinedObjects(cData.Comments)
                        setComments(Object.assign([], comments))
                    })
                    .catch(error => {
                        console.log(error)

                    })

                // GET ALL REACTIONS (LIKES)
                axios.get(process.env.REACT_APP_URL_API + '/reactions/' + blogNumber)
                    .then(response => {
                        setLikes((response.data.result.TotalLikes).length)
                        if ((response.data.result.TotalLikes).includes(username)) {
                            setCurrentUserLike(true)
                        } else {
                            setCurrentUserLike(false)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            })
            .catch(error => {
                console.log(error)
            })
    }, [type, keyword, blogNumber])


    // ** Like and Unlike function 
    const LikeUnlike = (method, id) => {
        let likeCount;
        if (method == 'addLike' && username) {
            likeCount = likes + 1;
            setCurrentUserLike(true)
            setLikes(likeCount)
        }
        if (method == 'removeLike' && username) {
            likeCount = likes - 1;
            setCurrentUserLike(false)
            setLikes(likeCount)
        }

        axios.post(process.env.REACT_APP_URL_API + '/reaction', { articleId: id }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setLikes(likeCount)
                dispatch(alertActive('success', res.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 2000)
            }).catch(err => {
                dispatch(alertActive('error', err.response.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)
            })
    }


    // **Bookmark
    const Bookmark = (id) => {
        const now = new Date();
        axios.post(process.env.REACT_APP_URL_API + '/favourites', {
            articleId: id,
            date: date.format(now, 'DD MMM YYYY'),
        },{
            headers:{
                Authorization:`Bearer ${token}`
            }
        }).then(res => {
                dispatch(alertActive('success', res.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)

            }).catch(err => {
                dispatch(alertActive('error', err.response.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)
            })
    }


    // COPY LINK OF ARTICLE 
    const copyLink = () => {
        let url = document.location.href
        navigator.clipboard.writeText(url).then(function () {
            dispatch(alertActive('success', 'Link copied to clipboard !'))
            setTimeout(() => {
                dispatch(alertDeactive())
            }, 3000)
        }, function () {
            dispatch(alertActive('error', 'Please try again !'))
            setTimeout(() => {
                dispatch(alertDeactive())
            }, 3000)
        });
    }

    return (
        <ArticlePageContent>
            <ArticleContainer>
                {articleData && articleData.map(val => (
                    <React.Fragment key={val._id}>
                        <div style={{ display: 'flex', alignItems: 'center', padding: '10px 0', borderBottom: '1px solid lightgray' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={process.env.REACT_APP_URL_API + '/Profile/' + val.AuthorProfile} style={{ width: '3rem', height: '3rem', borderRadius: '5rem', background: '#ededed', padding: '4px' }} />
                                <div style={{ display: 'flex', flexDirection: 'column', margin: '0 1rem' }}>
                                    <span style={{ fontWeight: 'bold' }}>{val.AuthorName}</span>
                                    <span style={{ fontSize: '12px', fontWeight: 'lighter' }}>{val.AuthorProfession}</span>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: '0' }}>
                                <BsLink45Deg size={22} style={{ cursor: 'pointer', margin: '0' }} title='Copy Link' onClick={() => copyLink()} />
                                <BsBookmarkPlus size={18} style={{ cursor: 'pointer', margin: '0 10px' }} title='Bookmark' onClick={() => Bookmark(val._id)} />
                            </div>

                        </div>
                        {/* Article information  */}
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '24px' }}>
                            <BiCategory />
                            <span style={{ marginLeft: '8px' }}>{val.Type}</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '8px' }}>
                            <Title>{val.Title}</Title>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: '12px', fontWeight: 'lighter' }}>Published on {val.Date}</span>
                                <span style={{
                                    display: 'flex', alignItems: 'center', margin: '0 5px', color: '#586455', fontSize: '13px',
                                }}><RxEyeOpen size={16} style={{ margin: '0 5px' }} />{shortNumber(parseInt(val.TotalViews))}</span>
                            </div>
                            <div style={{ display: 'flex', marginTop: '8px', flexWrap: 'wrap' }}>
                                <span style={{ fontSize: '13px', fontWeight: 'bold' }}>Keywords:</span>
                                {val.Tags && JSON.parse(val.Tags).map(tag => {
                                    return <span key={Math.random()} style={{
                                        background: '#336b00',
                                        color: 'white',
                                        fontWeight: 500,
                                        fontSize: '12px',
                                        padding: '2px 10px',
                                        borderRadius: '2rem',
                                        margin: '0 2px',
                                        whiteSpace: 'nowrap',
                                        marginBottom: '4px'
                                    }}>{tag}</span>
                                })}
                            </div>
                        </div>

                        <Article>
                            <div className="ArticlePage" dangerouslySetInnerHTML={{ __html: val.Data }} style={{
                                overflow: 'hidden', borderTop: '1px solid lightgray',
                                marginTop: '24px',
                            }} />
                        </Article>
                        <Reactions>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{
                                    marginLeft: '6px', fontWeight: 'bold', fontSize: '16px', color: '#383f30', bottom: '1px', position: 'relative',
                                }}>  {shortNumber(parseInt(likes))}
                                </span> &nbsp; &nbsp;
                                {!currentUserLike && <BsHeart size={18} onClick={() => LikeUnlike('addLike', val._id)} />}
                                {currentUserLike && <BsHeartFill size={18} color="red" onClick={() => LikeUnlike('removeLike', val._id)} />}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', right: '4%', }}>
                                <span style={{ margin: '0 8px', color: '#383f30', fontWeight: 'bold' }}>{comments.length}</span>
                                <span style={{
                                    fontWeight: 'bold', color: '#383f30', top: '3px',
                                    position: 'relative',
                                }}>
                                    <BiSolidCommentDetail size={20} />
                                </span>
                            </div>
                        </Reactions>
                        <CommentSection>
                            <h3 style={{
                                display: 'flex', alignItems: 'center',
                                fontSize: '14px', fontWeight: 600, marginBottom: '8px', marginTop: '32px',
                            }}><GoCommentDiscussion size={20} /> &nbsp; Comments</h3>
                            {/* user comments  */}
                            <Comments articleId={articleId} />
                        </CommentSection>
                    </React.Fragment >
                ))
                }
            </ArticleContainer >
            {/* Right side content on page  */}
            < ArticlePageRightContent authorInfo={authorInfo} seriesData={seriesData} seriesName={seriesName} />
        </ArticlePageContent >
    )
}

export default ArticlePage;