import React, { useState } from 'react'
import styled from 'styled-components';
import date from 'date-and-time';
import { IoMdClose } from 'react-icons/io'
import { AiOutlineMail } from 'react-icons/ai'
import { BsFillArrowLeftCircleFill } from 'react-icons/bs'
import Alert from './Alert'
import axios from 'axios'
import Loader from './Loader'
import '../App.css'
import { useDispatch } from "react-redux";
import { alertActive } from '../Redux'
import { alertDeactive } from '../Redux'


const BugReportPanel = styled.div`
   display: flex;
   position: fixed;
   height: 100%;
   width: 100%;
   top: 0;
   background: #000000c4;
   justify-content: center;
   align-items: center;
   z-index: +4;
   overflow: hidden;
   overflow-y: auto;
   font-family: Manrope;

   @media (max-width: 650px){
   
   }
`
const Form = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 22rem;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    color: #2a3b28;
`

const button = {
    background: '#0d2700',
    color: 'white',
    fontSize: '15px',
    cursor: 'pointer',
    marginTop: '1rem',
    border: 'none',
    padding: '10px',
    fontFamily: 'Manrope',

}

const h3 = {
    margin: '0px auto',
    marginTop: '3rem',
}
const select = {
    padding: '10px',
    marginLeft: '10px',
    border: '1px solid lightgray',
    outline: 'none',
    fontFamily: 'Manrope',
    borderRadius: '5px',
}

const input = {
    padding: '10px',
    fontFamily: 'Manrope',
    border: '1px solid lightgray',
    outline: 'none',
    margin: '0 10px',
    borderRadius: '5px',
}

const input2 = {
    padding: '10px 16px',
    border: '1px solid lightgray',
    outline: 'none',
    width: '18rem',
    margin: '1rem 0',
    fontSize: '15px',
    fontFamily: 'Manrope',
    background: 'none',
    color: 'black',
    letterSpacing: '0.2px',
}

const textarea = {
    height: '10rem',
    resize: 'none',
    padding: '10px',
    fontFamily: 'Manrope',
    border: '1px solid lightgray',
    outline: 'none',
    margin: '0 10px',
    borderRadius: '5px',
}
const spanLable = {
    margin: '2px 10px',
    marginTop: '1rem',
    fontWeight: 'bold',
    fontSize: '13px',
}
const ShareConfirmationPanel = styled.div`
    display: flex;
    position: fixed;
    top: 0;
    background: #00000026;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: +2;
`

const form2 = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'white',
    width: '23rem',
    height: '32rem',
    textAlign: 'center',
    borderRadius: '8px',
}

const button2 = {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem',
    border: 'none',
    color: 'white',
    fontWeight: 'bold',
    padding: '10px',
    width: '40%',
    fontSize: '15px',
    cursor: 'pointer',
    background: '#3b7d03',
    borderRadius: '5px',
}

function BugReport(props) {
    const dispatch = useDispatch()
    const [option, setOption] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [confirmEmail, setConfirmEmail] = useState(false);
    const [email, setEmail] = useState();
    const [loader, setLoader] = useState(false);

    const closeBugReportPanel = () => {
        props.closeBugReportPanel(false)
    }

    // PROCEED REQUEST 
    const proceedRequest = () => {
        if (option === '')
            document.getElementById('option').style.border = '1px solid #e50000';

        if (title === '')
            document.getElementById('title').style.border = '1px solid #e50000';

        if (description === '')
            document.getElementById('description').style.border = '1px solid #e50000';

        if (option !== '' && title !== '' && description !== '')
            setConfirmEmail(true)
    }

    const proceedShare = () => {
        setLoader(true)
        const now = new Date();
        axios.post(process.env.REACT_APP_URL_API + '/sources/report/', {
            option: option,
            title: title,
            description: description,
            email: email,
            date: date.format(now, 'DD MMM YYYY')
        })
            .then(res => {
                setLoader(false)
                setConfirmEmail(false)
                dispatch(alertActive('success', res.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)
            })
            .catch(err => {
                setLoader(false)
                dispatch(alertActive('error', err.response.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)
            })
    }

    return (
        <>
            <BugReportPanel>
                <Form>
                    <h3 style={h3}>Report a bug or request a feature</h3>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '1rem auto',
                    }}>
                        <span> I would like to </span>
                        <select style={select} onChange={(e) => {
                            setOption(e.target.value)
                            document.getElementById('option').style.border = '1px solid lightgray'
                        }} id='option'>
                            <option value=''>Select an option</option>
                            <option value="bug">Report Bug</option>
                            <option value="feature">Request Feature</option>
                        </select>
                    </div>
                    <span style={spanLable}>Title</span>
                    <input style={input} type="text" placeholder='Enter a title' onChange={(e) => {
                        setTitle(e.target.value)
                        document.getElementById('title').style.border = '1px solid lightgray'
                    }} id='title' />
                    <span style={spanLable}>Description</span>
                    <textarea style={textarea} placeholder='Enter a description' onChange={(e) => {
                        setDescription(e.target.value)
                        document.getElementById('description').style.border = '1px solid lightgray'
                    }} id='description'></textarea>
                    <span style={{ fontSize: '10px', margin: '4px 10px' }}><span style={{ fontWeight: 'bold' }}>Note:</span> You can DM us at web@techbitlife.com.</span>
                    <button style={button} onClick={() => proceedRequest()} className='btnHover'>Proceed Request</button>
                    <div style={{
                        position: 'absolute',
                        right: '2%',
                        top: '1%',
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        color: '#0d2700',
                        fontFamily: 'Manrope',
                    }}>
                        <IoMdClose size={20} onClick={() => closeBugReportPanel()} />
                    </div>
                </Form>
                {
                    confirmEmail ?
                        <ShareConfirmationPanel>
                            <div style={form2}>
                                <AiOutlineMail size={100} style={{ margin: '1rem' }} />
                                <span style={{
                                    fontSize: '13px',
                                    margin: '1rem 0'
                                }}>Please enter your email and click continue to share your report or request.</span>
                                <input name="email" style={input2} type='email' placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
                                <button style={button2} onClick={proceedShare}> {loader ? <Loader /> : <>Continue</>} </button>
                                <span style={{ fontSize: '10px', margin: '1rem 0' }}>
                                    You will get response from our side within three working days if not
                                    please contact to web@techbitlife.com
                                </span>
                                <BsFillArrowLeftCircleFill size={25} style={{ cursor: 'pointer' }} onClick={() => setConfirmEmail(false)} />
                            </div>
                        </ShareConfirmationPanel>

                        : ''
                }
            </BugReportPanel>
        </>
    )
}

export default BugReport;