import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import date from 'date-and-time';
import axios from "axios";
import Loader from '../subComponents/Loader'
import Alert from '../subComponents/Alert'
import PreviewImg from '../Images/preview.png'
import ScreenSizeDetector from 'screen-size-detector';
import { Editor } from '@tinymce/tinymce-react'
import '../App.css'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { FaRegImages } from 'react-icons/fa'
import { MdDateRange } from 'react-icons/md'
import { HiOutlineArrowTopRightOnSquare } from 'react-icons/hi2'
import removeNoteIcon from '../Images/removeNoteIcon.png'

import UserUploads from "../components/UserUploads";


const CreateArticle = styled.div`
  display: flex;
  margin: 10px auto; 
  padding: 15px;
  margin: 10px auto;
  max-width: 1450px;
  margin: 1rem auto;
  width: 70%;

  @media(max-width: 900px) {
    width: 90%;
  }
`

const form = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontFamily: 'Manrope',
}

const Left = styled.div`
    display: flex;
    overflow: hidden;
    justify-content: center;
    margin-top: 24px;

    @media(max-width: 900px) {
        flex-direction: column;
    }
`
const Right = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;

    
    @media(max-width: 950px) {
        height: 30rem;
    }
`

const ThumbnailContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 0 10px;
    padding-top: 16px;
    margin: 0 16px;
    max-width: 22rem;
    max-height: 20rem;
    color: #303030;
    box-shadow: 0 0px 21px #e0e0e0;

    @media(max-width: 900px) {
        margin: 0 auto;
    }
    @media(max-width: 650px) {
        max-width: 18rem;
    }
`

const maxImg = {
    width: '310px',
    height: '207px',
    margin: 'auto 20px',
    borderRadius: '8px',
}
const minImg = {
    width: '95%',
    height: '170px',
    margin: '0px auto',
    borderRadius: '8px',
}
const input = {
    padding: '7px 2px',
    fontSize: '15px',
    border: 'none',
    borderBottom: '1px solid gray',
    fontFamily: 'Manrope',
    fontWeight: 500,
    outline: 'none',
    margin: '10px 0',
    background: 'none',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
}

const InputsBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0 16px;

    @media(max-width: 900px) {
        width: 100%;
    }

    @media(max-width: 650px) {
        display: flex;
        flex-direction: column;
        margin: 16px 0;
    }
    
`

const button = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#29351e',
    color: 'white',
    fontSize: '16px',
    cursor: 'pointer',
    margin: '10px 0',
    padding: '12px',
    border: 'none',
    borderRadius: '5px',
}

const uploadFilesBtn = {
    display: 'flex',
    alignItems: 'center',
    background: '#44a400',
    padding: '8px 10px',
    fontSize: '12px',
    letterSpacing: '1px',
    borderRadius: '3px',
    fontWeight: 'bold',
    cursor: 'pointer',
    border: 'none',
    color: 'white',
}

const Tag = styled.span`
    display: flex;
    align-items: center;
    background: #6e7769;
    color: white;
    padding: 2px 5px;
    font-weight: 400;
    font-family: manrope;
    border-radius: 4px;
    margin-right: 4px;
    font-size: 14px;
    white-space: nowrap;
    margin-top: 4px;
`

const AvailableSeriesWindow = styled.div`
   display: flex;
   position: fixed;
   top: 0;
   z-index: +4;
   background: #00000094;
   height: 100%;
   width: 100%;
   justify-content: center;
   align-items: center;

`
const AvailableSeriesbox = styled.div`
   display: flex;
   flex-direction: column;
   background: white;
   border-radius: 16px;
   margin: 4px;
   width: 24rem;
   height: 24rem;
   z-index: +1;
   box-shadow: 0 12px 14px #2a2a2a8c;
   border: 4px solid #112300;
   font-family: 'Manrope';
   overflow: hidden;
`
const SeriesBox = styled.div`
   display: flex;
   flex-direction: column;
   height: 100%;
   overflow: hidden;
   overflow-y: auto;
   padding: 16px 0;
`
const SeriesList = styled.div`
   display: flex;
   flex-direction: column;
   background: white;
   border-radius: 16px;
   margin: 4px;
   width: 55rem;
   height: 33rem;
   z-index: +1;
   box-shadow: 0 12px 14px #2a2a2a8c;
   font-family: 'Manrope';
   overflow: hidden;
   border: 4px solid #112300;
`

const SelectSeriesName = styled.select`
   width: auto;
   padding: 4px 0;
   font-size: 16px;
   border: none;
   border-bottom: 1px solid lightgray;
   margin: 12px;
   outline: none;

   @media(max-width: 400px) {
    width: 300px;
  }
`

const SeriesItemHolder = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;
   width: 250px;
   height: 160px;
   border-radius: 6px;
   margin-right: 16px;
   margin-bottom: 16px;
   overflow: hidden;
   cursor: pointer;

   @media(max-width: 900px) {
    width: 230px;
    height: 147px;
  }
   @media(max-width: 800px) {
    width: 210px;
    height: 134px;
  }
   @media(max-width: 600px) {
    width: 150px;
    height: 96px;
  }
   @media(max-width: 410px) {
    width: 132px;
    height: 85px;
    margin-right: 10px;
    margin-bottom: 10px
  }
`

function Create(props) {
    const editorRef = useRef()
    const [thumbnail, setThumbnail] = useState();
    const [editorData, setEditorData] = useState('');
    const [title, setTitle] = useState('');
    const [about, setAbout] = useState('');
    const [articleType, setArticleType] = useState('');
    const [series, setSeries] = useState('')
    const [tags, setTags] = useState([]);
    const [loader, setLoader] = useState('')
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    // Alert box
    const [alertCall, setAlertCall] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('');
    // Pictures 
    const [pictures, setPictures] = useState([]);
    const [userUploads, setUserUploads] = useState(false);
    // username 
    const username = sessionStorage.getItem('username')
    //token
    const token = sessionStorage.getItem('token');
    // Series 
    const [createSeriesWindow, setCreateSeriesWindow] = useState(false)
    const [availableSeries, setAvailableSeries] = useState([])
    const [seriesList, setSeriesList] = useState([]);
    const [seriesListWindow, setSeriesListWindow] = useState(false);
    //
    const [selectedSeries, setSelectedSeries] = useState('');

    // Alert Msg 
    const AlertMsg = (AlertMsg) => {
        setAlertCall(AlertMsg)
    }
    useEffect(() => {
        const handleResize = () => {
            const screen = new ScreenSizeDetector();
            setScreenSize(screen.width)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        };

    }, [])

    // fetch Series 
    useEffect(() => {
        axios.get(process.env.REACT_APP_URL_API + '/available_series/', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setAvailableSeries(res.data.result.SeriesNames)
                setSelectedSeries(res.data.result.SeriesNames[0])
            }).catch(err => {
                console.log(err)
            })
    }, [])


    // FORM SUBMISSION
    const Submit = (e) => {
        e.preventDefault();
        const now = new Date();
        setLoader('collection')
        const formdata = new FormData();
        formdata.append('thumbnail', thumbnail)
        formdata.append('title', title)
        formdata.append('about', about)
        formdata.append('date', date.format(now, 'DD MMM YYYY'))
        formdata.append('articletype', articleType)
        formdata.append('tags', JSON.stringify(tags))
        formdata.append('username', username)
        formdata.append('data', editorData)
        formdata.append('series', series)

        axios.post(process.env.REACT_APP_URL_API + '/collection', formdata, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then((response) => {
                setLoader('')
                setAlertCall(true)
                setAlertMsg(response.data.result)
                setAlertType(response.data.message)
                setTimeout(() => {
                    window.location.reload();
                }, 4000)
            })
            .catch((error) => {
                setLoader('')
                setAlertCall(true)
                if (error.response.status === 500) {
                    setAlertMsg('Please select thumbnail')
                    setAlertType('error')
                } else {
                    setAlertMsg(error.response.data.result)
                    setAlertType(error.response.data.message)
                }
                setTimeout(() => {
                    setAlertCall(false)
                }, 5000)
            })

    }

    // upload pictures 
    const uploadPictures = (e) => {
        e.preventDefault();
        const now = new Date();
        setLoader('picture')
        const formdata = new FormData();
        for (let i = 0; i < pictures.length; i++) {
            if (pictures[i].size > 5242880) {
                setLoader('')
                setAlertCall(true)
                setAlertMsg('The file size should be less than 5MB.')
                setAlertType('error')
                setTimeout(() => {
                    setAlertCall(false)
                }, 4000)
                return false;
            }
            formdata.append('pictures', pictures[i])
        }
        formdata.append('date', date.format(now, 'DD MMM YYYY'))
        axios.post(process.env.REACT_APP_URL_API + '/pictures', formdata, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            },
        })
            .then((response) => {
                setLoader('')
                setAlertCall(true)
                setAlertMsg(response.data.result)
                setAlertType(response.data.message)
                setTimeout(() => {
                    setAlertCall(false)
                }, 4000)
            })
            .catch((error) => {
                setLoader('')
                setAlertCall(true)
                if (error.response.status === 500) {
                    setAlertMsg('Please select image files.')
                    setAlertType('error')
                } else {
                    setAlertMsg(error.response.data.result)
                    setAlertType(error.response.data.message)
                }
                setTimeout(() => {
                    setAlertCall(false)
                }, 5000)
            })

    }

    const picDataPanel = (val) => {
        setUserUploads(val)
    }

    // FOR Editor 
    const Plugins = [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks codesample code fullscreen',
        'insertdatetime media table paste code help wordcount emoticons'
    ]
    const Toolbar = 'undo redo | alignleft  aligncenter alignright alignjustify|' +
        'fontsizeselect | fontselect | bold italic forecolor  backcolor | formatselect |' +
        'bullist numlist outdent indent | ' +
        'removeformat |' +
        'pagebreak | charmap emoticons | fullscreen preview | ' +
        'insertfile image media template link anchor codesample code | ltr rtl' +
        'useBrowserSpellcheck'


    // ADDING TAGS 
    const addTag = (e) => {
        if (e.key == 'Enter') {
            if (tags.includes(e.target.value)) {
                setAlertCall(true)
                setAlertMsg('Tag already exist!')
                setAlertType('error')
                setTimeout(() => {
                    setAlertCall(false)
                }, 2000)
            } else {
                setTags([...tags, e.target.value])
                e.target.value = ''
            }
        }
    }

    const removeTag = (tag) => {
        const newTags = tags.filter((tags) => {
            return tags !== tag
        })
        setTags(newTags)
    }



    return (
        <>
            {userUploads && <UserUploads picDataPanel={picDataPanel} />}
            <form onSubmit={uploadPictures} encType='multipart/form-data' id='PicForm'></form>
            <form onSubmit={Submit} encType='multipart/form-data' id='CollectionForm'></form>
            {/* Alert Window  */}
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'fixed', left: '0', bottom: '0', zIndex: '+10' }}>
                {alertCall && <Alert alertMsg={alertMsg} AlertMsg={AlertMsg} alertType={alertType} />}
            </div>
            <CreateArticle>
                <div style={form} form='CollectionForm'>
                    <Left>
                        <ThumbnailContainer>
                            <img style={screenSize <= 650 ? minImg : maxImg} src={thumbnail ? URL.createObjectURL(thumbnail) : PreviewImg} />
                            <input style={{ ...input, margin: '0 auto', marginTop: '16px', width: '90%', borderBottomColor: '#FAED26' }} type='file' onChange={(e) => setThumbnail(e.target.files[0])} form='CollectionForm' accept="image/png, image/jpg, image/jpeg" />
                            <span style={{
                                display: 'flex', fontSize: '8px', margin: '8px auto',
                                width: '90%',
                                fontFamily: 'Manrope',
                            }}>Only .png, .jpg and .jpeg format allowed!</span>
                        </ThumbnailContainer>
                        <InputsBox>
                            <input style={input} type="text" name="name" placeholder="Title" onChange={(e) => setTitle(e.target.value)} form='CollectionForm' />
                            <input style={input} type="text" name="about" placeholder="About topic" onChange={(e) => setAbout(e.target.value)} form='CollectionForm' />
                            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', fontFamily: 'Manrope', borderBottom: '1px solid gray', fontWeight: 600, margin: '10px 0', background: 'white' }}>
                                {tags && tags.map(tag => (
                                    <Tag key={tag}>{tag} &nbsp; <IoIosCloseCircleOutline size={14} onClick={() => removeTag(tag)} /> </Tag>
                                ))}
                                <input type="text" placeholder="Add tags" onKeyDown={(e) => addTag(e)} style={{ ...input, borderBottom: 'none', margin: '0' }} />
                            </div>
                            <select style={{ ...input, padding: '7px 0' }} onChange={(e) => setArticleType(e.target.value)} form='CollectionForm'>
                                <option value=''>Select blog type</option>
                                <option value='tech'>Tech</option>
                                <option value='web'>Web</option>
                                <option value='programming'>Programming</option>
                                <option value='photography'>Photography</option>
                                <option value='food'>Food</option>
                                <option value='fitness'>Fitness</option>
                                <option value='travel'>Travel</option>
                                <option value='health'>Health</option>
                                <option value='fashion'>Fashion</option>
                                <option value='sports'>Sports</option>
                            </select>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input type="text"
                                    placeholder="Make series(optional)"
                                    style={input}
                                    value={series}
                                    onChange={(e) => setSeries(e.target.value)} />
                                &nbsp;
                                <strong>Or</strong>
                                &nbsp;
                                <button onClick={() => setCreateSeriesWindow(true)} style={{ ...uploadFilesBtn, background: '#28351d', fontWeight: 500, padding: '10px', fontSize: '14px', margin: '4px', width: '14rem', justifyContent: 'center' }}>Choose series</button>
                            </div>
                            {/* uploading pictures  */}
                            <span style={{
                                fontSize: '10px',
                                marginBottom: '-15px',
                            }}>Max file size 5 MB</span>
                            <div style={{ marginTop: '1rem' }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '32px',
                                    background: '#29351e',
                                    padding: '5px',
                                    borderRadius: '5px',
                                    color: 'white',
                                    maxWidth: '21rem',

                                }}>
                                    <div style={{ display: 'flex', alignItems: 'center', position: 'relative', width: '100%' }} >
                                        <input type='file' multiple onChange={(e) => setPictures(e.target.files)} form='PicForm' accept="image/png, image/jpg, image/jpeg" />
                                        <button style={{ ...uploadFilesBtn, position: 'absolute', right: '2%' }} type='submit' form='PicForm'> {loader === 'picture' ? <Loader style={{ height: '15px' }} /> : <>UPLOAD</>}</button>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span onClick={() => setUserUploads(true)} style={{ ...uploadFilesBtn, margin: '16px 0', background: '#29351e', color: 'white', width: 'max-content', }}><FaRegImages size={22} /></span>
                                    <button style={{ ...uploadFilesBtn, background: '#28351d', fontWeight: 500, padding: '10px', fontSize: '15px', margin: '4px', }} onClick={() => setSeriesListWindow(true)}>Series List</button>
                                </div>
                            </div>
                        </InputsBox>
                    </Left>
                    <Right>
                        <Editor
                            form='CollectionForm'
                            apiKey="me91nhwamlrdq769kp9dcmpt4i7lrqodz6eyswsjpxevphss"
                            onInit={(evt, editor) => editorRef.current = editor}
                            init={{
                                width: '100%',
                                height: '40rem',
                                placeholder: 'Start writing [Spell Check : Ctrl+Right click.]',
                                menubar: true,
                                statusbar: false,
                                branding: false,
                                inline_boundaries: false,
                                skin: 'fabric',
                                icons: "thin",
                                plugins: Plugins,
                                toolbar: Toolbar,
                                browser_spellcheck: true,
                                codesample_global_prismjs: true,
                                content_style: "@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&family=Dancing+Script:wght@400;500;600;700&family=Dosis:wght@200;300;400;500;600;700&family=Exo+2:wght@300;500;600&family=Inconsolata:wght@200;300;400;500;600;700;800&family=Indie+Flower&family=Lobster+Two:wght@400;700&family=Lora:wght@400;500;600&family=Merriweather:wght@300;400;700;900&family=Mukta:wght@200;300;400;500;600;700&family=Nunito:wght@200;300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Oswald:wght@200;400;500;600;700&family=Playfair+Display:wght@400;500;600;700&family=Raleway:wght@100;200;300;400;500;600&family=Roboto:wght@100;300;400;500;700;900&family=Slabo+27px&family=Teko:wght@300;400;500;600&family=Titillium+Web:wght@200;300;400;600&display=swap');body {font-family:'Manrope', sans-serif;} @media (max-width:420px){img{width: 90%; height: auto;}};",
                                fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 36pt",
                                font_formats: "Manrope, sans-serif;Dancing Script, cursive;Dosis, sans-serif;Exo 2, sans-serif;Inconsolata, monospace;Indie Flower, cursive;Lobster Two, cursive;Lora, serif;Merriweather, serif;Mukta, sans-serif;Nunito, sans-serif;Open Sans, sans-serif;Oswald, sans- serif;Playfair Display, serif;Raleway, sans-serif;Roboto, sans-serif;Slabo 27px, serif;Teko, sans-serif;Titillium Web, sans-serif;",
                            }}
                            onChange={() => setEditorData(editorRef.current.getContent())}
                            style={{ background: 'white', border: 'none', }}
                        />
                    </Right>
                    <button style={button} type='submit' className="btnHover" form='CollectionForm'> {loader === 'collection' ? <Loader /> : <>UPLOAD ARTICLE</>} </button>
                </div>
            </CreateArticle>
            {/* CHOOSE SERIES WINDOW  */}
            {createSeriesWindow &&
                <AvailableSeriesWindow>
                    <AvailableSeriesbox>
                        <span style={{ fontSize: '18px', fontWeight: 600, margin: '0', padding: '16px', background: '#102300', color: 'white' }}>Your active series</span>
                        {availableSeries != '' ?
                            <SeriesBox>
                                {availableSeries && availableSeries.map(val => (
                                    <span key={Math.random() * 1000} style={{
                                        fontWeight: 600,
                                        margin: '8px',
                                        color: '#313730',
                                        cursor: 'pointer',
                                        padding: '5px 10px',
                                        borderRadius: '4px',
                                    }} onClick={() => setSeries(val)
                                        + setCreateSeriesWindow(false)} className="avai_SeriesName">{val}</span>
                                ))}
                            </SeriesBox> :
                            <span style={{ padding: '16px', fontSize: '13px' }}>No series found :(</span>
                        }
                    </AvailableSeriesbox>
                    {/* close box  */}
                    <div style={{ position: 'absolute', width: '100%', height: '100%' }}
                        onClick={() => setCreateSeriesWindow(false)}></div>
                </AvailableSeriesWindow >
            }
            {/* SERIES LIST WINDOW  */}
            {seriesListWindow &&
                <AvailableSeriesWindow>
                    <SeriesList>
                        <span style={{ fontSize: '24px', fontWeight: 600, margin: '0', padding: '16px 24px', background: '#102300', color: 'white' }}>Your blogs series.</span>
                        <SeriesBox>
                            {availableSeries != '' ?
                                <SelectSeriesName onChange={(e) => setSelectedSeries(e.target.value)}>
                                    {availableSeries && availableSeries.map(sName => (
                                        <option key={sName} value={sName}>{sName}</option>
                                    ))}
                                </SelectSeriesName> :
                                <span style={{ padding: '16px' }}>No series found :(</span>
                            }
                            <div style={{ display: 'flex', flexWrap: 'wrap', overflow: 'hidden', overflowY: 'auto', padding: '12px' }}>
                                {props.userArticles && props.userArticles.map(val => {
                                    {
                                        if (val.Series == selectedSeries) {
                                            return (
                                                <SeriesItemHolder className="seriesHolder">
                                                    <div className="seriesBoxBottomDiv" style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: '0', transition: '0.5s all', opacity: 0, background: '#3b3b3b42', width: '100%', height: '36px' }}>
                                                        <span style={{ display: 'flex', alignItems: 'center', position: 'absolute', left: '5%', }}>
                                                            <MdDateRange size={20} color="white" style={{
                                                                color: 'white',
                                                            }} title="Published date" /> &nbsp;
                                                            <span style={{ color: 'white', fontSize: '12px' }}>{val.Date}</span>
                                                        </span>
                                                    </div>

                                                    <img className="seriesBlogImg" style={{ width: '100%', height: '100%' }}
                                                        src={process.env.REACT_APP_URL_API + '/Thumbnails/' + val.Thumbnail} />
                                                    <div className="seriesBoxBottomDiv" style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: '0', transition: '0.5s all', opacity: 0, background: '#3b3b3b42', width: '100%', height: '36px' }}>
                                                        <span style={{ display: 'flex', alignItems: 'center', color: 'white', fontSize: '12px', position: 'absolute', right: '5%', cursor: 'pointer' }} title="Remove from list"><img src={removeNoteIcon} style={{ width: '16px' }} />&nbsp; Remove </span>
                                                        <HiOutlineArrowTopRightOnSquare size={20} color="white" style={{
                                                            color: 'white',
                                                            position: 'absolute',
                                                            left: '5%',
                                                            cursor: 'pointer',
                                                        }} title="Visit article" />
                                                    </div>
                                                </SeriesItemHolder>
                                            )
                                        }
                                    }
                                })}
                            </div>

                        </SeriesBox>
                    </SeriesList>
                    {/* close box  */}
                    <div style={{ position: 'absolute', width: '100%', height: '100%' }}
                        onClick={() => setSeriesListWindow(false) + setSelectedSeries(availableSeries[0])}></div>
                </AvailableSeriesWindow >
            }
        </>
    )
}

export default Create;

