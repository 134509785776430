import React, { useState, useEffect, useRef } from 'react'
import styled from "styled-components";
import axios from 'axios';
import Alert from '../subComponents/Alert'
import Loader from '../subComponents/Loader'
import ScreenSizeDetector from 'screen-size-detector';
import profileImg from '../Images/profile.png'
import { Country, State, City } from 'country-state-city';
import NavMenu from '../subComponents/NavMenu';
import '../App.css'
// cropper 
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { FiMapPin } from 'react-icons/fi'
import { BsCheckLg, BsGenderAmbiguous } from 'react-icons/bs'
import { FaBirthdayCake, FaFacebookF } from 'react-icons/fa'
import { BiMessageSquareEdit } from 'react-icons/bi'
import { RiImageAddLine } from 'react-icons/ri'
import { FiEdit } from 'react-icons/fi'
import { MdClose } from 'react-icons/md'
import { HiOutlineUserCircle, HiDotsVertical } from 'react-icons/hi'
import { IoRocketOutline } from 'react-icons/io5'
import { AiOutlineInstagram, AiOutlineTwitter, AiOutlineMail } from 'react-icons/ai'
import { IoShareSocial } from 'react-icons/io5'
import { TiSocialLinkedin } from 'react-icons/ti'
import { VscEyeClosed } from 'react-icons/vsc'
import { SiSimpleanalytics } from 'react-icons/si'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { GoCheckCircle } from 'react-icons/go'
import { GoCheckCircleFill } from 'react-icons/go'


import Create from '../subComponents/Create';
import PrvPost from '../subComponents/PrvPost';
import RemoveAccount from './RemoveAccount';
import Analytics from '../subComponents/Analytics';

import caligraphyImg from '../Images/caligraphy.jpg'
import AvatarPNG from '../Images/avatar.png'

import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'
loadProgressBar()

const ProfileContainer = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: 7rem;
   min-height: 30rem;
   font-family: Manrope;

   @media (max-width: 650px){
      margin-top: 5rem;
   }
`
const Cover = styled.div`
   display: flex;
   position: relative;
   justify-content: center;
   background: #000000e8;
   height: 412px;
   width: 1000px;
   position: relative;
   overflow: hidden;
   margin: 24px auto;
   border-radius: 16px;
//    box-shadow: 0 0px 16px #8b8b8b;

   @media (max-width: 1200px){
    width: 900px;
    height: 370px;
   }

   @media (max-width: 950px){
    width: 800px;
    height: 329px;
   }
   @media (max-width: 850px){
    width: 700px;
    height: 288px;
   }
   @media (max-width: 750px){
    width: 600px;
    height: 246px;
   }

   @media (max-width: 650px){
    width: 500px;
    height: 205px;
   }
   @media (max-width: 550px){
    width: 420px;
    height: 172px;
   }
   @media (max-width: 450px){
    width: 370px;
    height: 152px;
   }
   @media (max-width: 400px){
    width: 350px;
    height: 143px;
   }
   @media (max-width: 350px){
    width: 320px;
    height: 132px;
   }
`

const ProfileIMG = styled.img`
    width: 10rem;
    height: 10rem;
    border-radius: 25rem;
    margin-top: -5rem;
    margin-bottom: 10px;

    @media (max-width: 550px){
        width: 7rem;
        height: 7rem;
        margin-top: -4rem;
    }
    @media (max-width: 400px){
        width: 6rem;
        height: 6rem;
        margin-top: -4rem;
    }
`
const opt = {
    position: 'absolute',
    right: '1%',
    bottom: '3%',
    padding: '7px',
    borderRadius: '1rem',
    background: 'white',
    color: 'black',
    zIndex: '+1',
    cursor: 'pointer',
}

const optBtnDiv = {
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    position: 'absolute',
    right: '-15rem',
    bottom: '3px',
    fontFamily: 'Manrope',
    border: 'none',
    background: 'green',
    borderRadius: '40px',
    zIndex: '+1',
    transition: '0.5s all',
}
const optBtn = {
    padding: '8px 8px',
    margin: '0 8px',
    fontFamily: 'Manrope',
    border: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    color: '#37353a',
    borderRadius: '5rem',
}

const Userinfo = styled.div`
    display: flex;
    max-width: 56rem;
    margin: 0 auto;

    @media (max-width: 1200px){
        max-width: 48rem;
    }
    @media (max-width: 950px){
        max-width: 40rem;
    }
    @media (max-width: 750px){
        max-width: 32rem;
    }
    @media (max-width: 650px){
        flex-direction: column;
        max-width: 28rem;
    }
    @media (max-width: 550px){
        max-width: 24rem;
    }
    @media (max-width: 450px){
        max-width: 20rem;
    }
    @media (max-width: 400px){
        max-width: 22rem;
    }
`
const LeftPanel = styled.div`
    display: flex;
    flex-direction: column;
    width: 20rem;
    z-index: +1;
    color: #092900;

    @media (max-width: 420px){
        margin: 0 auto;
    }
`
const RightPanel = styled.div`
    display: flex;
    flex-direction: column;
    width: 65%;
    margin: 1rem auto;
    // background: #102300;
    // background: #29351e;
    background: white;
    color: #363636;
    padding: 10px;
    border-radius: 5px;
    height: max-content;
    padding-bottom: 16px;


    @media (max-width: 650px){
        width: 95%;
    }
    @media (max-width: 420px){
        width: 90%;
    }
`
const span = {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
}
const p = {
    margin: 0,
    background: 'white',
    color: 'black',
    fontSize: '14px',
    padding: '1rem',
    borderRadius: '8px',
    border: '1px solid lightgray',
}

const ProfileForm = styled.div`
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    background: #000000a3;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: +4;
`
// Profile Form 
const Form = styled.form`
    display: flex;
    flex-direction: column;
    background: white;
    width: 700px;
    height: 95%;
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    @media (max-width: 650px){
        height: 100%;
        width: 100%;
        border-radius: 0px;
    }

`
const input = {
    border: '1px solid lightgray',
    margin: '5px 0',
    padding: '7px',
    fontSize: '14px',
    fontFamily: 'Manrope',
    fontWeight: 500,
    outline: 'none',
    borderRadius: '4px',
}
const textarea = {
    fontFamily: 'Manrope',
    height: '8rem',
    padding: '7px',
    margin: '5px 0',
    fontSize: '15px',
    resize: 'none',
    border: '1px solid lightgray',
    outline: 'none',
    width: '50%',
    fontWeight: 500,
    borderRadius: '4px',
}
const select = {
    height: '37px',
    border: '1px solid lightgray',
    borderRadius: '4px',
    fontFamily: 'Manrope',
    fontSize: '15px',
    width: '50%',
    marginRight: '2px',
    outline: 'none',
    boxShadow: '0 0 2px lightgrey',
}
const formBtn = {
    display: 'flex',
    justifyContent: 'center',
    padding: '8px',
    marginTop: '24px',
    border: 'none',
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    fontSize: '16px',
    background: '#29351e',
    color: 'white',
    cursor: 'pointer',
    borderRadius: '4px',
}

const UserInfoForm = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 3rem;
    padding: 10px 8px;
    width: 80%;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom:24px;

    @media (max-width: 650px){
        width: 90%;
    }
`
const closeFormDiv = {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    alignItems: 'center',
    fontFamily: 'Manrope',
    position: 'absolute',
    right: '1%',
    top: '1%',
    fontSize: '10px',
    cursor: 'pointer',
    zIndex: '+2',
}


const ProfileDiv = styled.div`
     display: flex;
     position: absolute;
     width: 6rem;
     height: 6rem;
     border-radius: 10rem;
     justify-content: center;
     align-items: center;
     overflow: hidden;
     bottom: -28px;

`

const HeaderPlate = styled.div`
    display: flex; 
    align-items: center; 
    height: 12rem; 
    width: 60%; 
    padding: 1rem;
    margin: 2rem auto;
    border-radius: 16px;
    overflow: hidden; 
    position: relative; 
    background: linear-gradient(45deg, #379401, #4a8601, transparent); 
    color: white;

    @media (max-width: 1200px){
        height: 10rem; 
    }
    @media (max-width: 650px){
        height: 9rem; 
    }
    @media (max-width: 800px){
        width: 80%; 
    }
    @media (max-width: 420px){
        width: 85%; 
        height: 6rem; 
    }

`

const H2 = styled.h2`
    font-size: 32px;
    margin: 2px 0;

    @media (max-width: 1024px){
        font-size: 24px;
    }
    @media (max-width: 550px){
        font-size: 20px;
        width: 80%;
    }
`

const HeaderPlateSubHeading = styled.div`
    display: flex;
    width: 70%;
    font-size: 12px;

    @media (max-width: 900px){
        font-size: 9px;  
    }
    @media (max-width: 420px){
        font-size: 7px;  
    }
`

const Avatar = styled.img`
    width: 25%;
    position: absolute;
    right: 2%;
    bottom: 0;

    @media (max-width: 1024px){
        width: 28%;
    }
    @media (max-width: 550px){
        width: 35%;
    }
`

const UpdateProfileBtn = styled.button`
    width: 10rem;
    font-family: Manrope;
    padding: 5px;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    margin: 0 0;
    font-size: 16px;
    background: #FAED26;
    color: #37353a;
    cursor: pointer;
    margin-top: 16px;

    @media (max-width: 900px){
        width: 8rem;
        font-size: 14px;
    }
`
const caligraphyImgStyle = {
    height: '100%',
    width: '60%',
    position: 'absolute',
    top: '0',
    right: '0',
    opacity: '0.4',
    boxSizing: 'border-box',
    zIndex: '-1',
}

const AnalyticsTag = styled.span`
   display: flex;
   font-family: Roboto;
   

   @media (max-width: 650px){
       font-size: 12px;
    }
`

// social media of user 
const SocialMedia = styled.div`
    display: flex;
    align-items: center;
    margin: 8px 10px;
`

const PostsAndAnalyticsOpts = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid gray;
    padding: 0 10px;
    margin: 2rem;
    font-family: Manrope;
`

const ImageCropper = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: +5;
    background: #000;
`

const CropBtns = styled.div`
    display: flex;
    align-items: center;
    margin: 24px 0;
`


function Profile() {

    const [refresh, setRefresh] = useState();
    const [profileForm, setProfileForm] = useState(false)
    const [alertCall, setAlertCall] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [gender, setGender] = useState('');
    const [dob, setDOB] = useState('');
    const [profession, setProfession] = useState('');
    const [personalInfo, setPersoanlInfo] = useState('');
    const [interest, setInterest] = useState('');
    const [fb, setFB] = useState('');
    const [insta, setInsta] = useState('');
    const [twitter, setTwitter] = useState('');
    const [linkedIn, setLinkedIn] = useState('');
    const [profile, setProfile] = useState('');
    const [fetchedProfile, setFetchedProfile] = useState('');
    const [cover, setCover] = useState('');
    const [fetchedCover, setFetchedCover] = useState('');
    const [profileStatus, setProfileStatus] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [loader, setLoader] = useState(false)
    const [removeAccountDiv, setRemoveAccountDiv] = useState(false)
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const [userArticles, setUserArticles] = useState([])
    const [croper, setCroper] = useState(false)
    const [posts, setPosts] = useState(true)
    const [analytics, setAnalytics] = useState(false)

    const username = sessionStorage.getItem('username')
    const token = sessionStorage.getItem('token')
    const cropperRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            const screen = new ScreenSizeDetector();
            setScreenSize(screen.width)
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize)
        };

    }, [])

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL_API + "/articles/user/", {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setUserArticles(response.data.result)
            })
            .catch(error => {
                console.log(error)
            })
    }, [refresh])

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL_API + '/account/profile_details/', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setFetchedProfile(res.data.result.Profile)
                setFetchedCover(res.data.result.Cover)
                setName(res.data.result.Name)
                setPhone(res.data.result.Phone)
                setGender(res.data.result.Gender)
                setState(res.data.result.State)
                setCountry(res.data.result.Country)
                setDOB(res.data.result.DOB)
                setProfession(res.data.result.Profession)
                setPersoanlInfo(res.data.result.PersonalInfo)
                setInterest(res.data.result.Interest)
                setFB(res.data.result.Facebook)
                setInsta(res.data.result.Instagram)
                setTwitter(res.data.result.Twitter)
                setLinkedIn(res.data.result.LinkedIn)
            })
            .catch(error => {
                setAlertCall(true)
                setAlertMsg('Failed to fetch the information.')
                setTimeout(() => {
                    setAlertCall(false)
                }, 5000)
            })
    }, [refresh])

    useEffect(() => {
        if (fetchedProfile && profession && name && gender && country && state && dob && personalInfo && interest) {
            setProfileStatus('Complete')
        } else {
            setProfileStatus('Incomplete')
        }
    }, [refresh, fetchedProfile, profession, name, gender, country, state, dob, personalInfo, interest])


    // Submit Profile Form
    const submit = (e) => {
        e.preventDefault();
        setLoader(true)
        // Save Profile 
        const profileForm = new FormData();
        profileForm.append('profile', profile)
        axios.post(process.env.REACT_APP_URL_API + '/account/profile_details/save_profile/', profileForm, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                console.log('')
            }).catch(error => {
                setLoader(false)
                setAlertCall(true)
                setAlertMsg('Error while uploading profile')
                setAlertType('error')
                setTimeout(() => {
                    setAlertCall(false)
                }, 5000)
            })
        // Save Cover
        const coverForm = new FormData();
        coverForm.append('cover', cover)
        axios.post(process.env.REACT_APP_URL_API + '/account/profile_details/save_cover/', coverForm, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                console.log('')
            }).catch(error => {
                setLoader(false)
                setAlertCall(true)
                setAlertMsg('Error while uploading cover image')
                setAlertType('error')
                setTimeout(() => {
                    setAlertCall(false)
                }, 5000)
            })

        // ** Text Data Form 
        axios.post(process.env.REACT_APP_URL_API + '/account/profile_details/', {
            name: name,
            phone: phone,
            state: state,
            country: country,
            gender: gender,
            dob: dob,
            profession: profession,
            personalInfo: personalInfo,
            interest: interest,
            fb: fb,
            insta: insta,
            twitter: twitter,
            linkedIn: linkedIn,
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(res => {
                setLoader(false)
                setProfileForm(false)
                setAlertCall(true)
                setAlertMsg(res.data.result)
                setAlertType('success')
                setTimeout(() => {
                    setRefresh(Math.random())
                }, 2000)
                setTimeout(() => {
                    setAlertCall(false)
                }, 5000)
            }).catch(error => {
                setLoader(false)
                setAlertCall(true)
                setAlertMsg(error.response.data.result)
                setAlertType('error')
                setTimeout(() => {
                    setAlertCall(false)
                }, 5000)
            })
    }


    // Alert Message 
    const AlertMsg = (AlertMsg) => {
        setAlertCall(AlertMsg)
    }

    const MyCountry = (inputString) => {
        document.getElementById('countryOption').style.display = 'flex';
        const countries = [];
        Country.getAllCountries().map(country => {
            if ((country.name).toLowerCase().match(inputString.toLowerCase()))
                countries.push(country.name)
        })
        setCountryData(countries)
    }
    const MyState = (inputString) => {
        document.getElementById('stateOption').style.display = 'flex';
        const states = [];
        State.getAllStates().map(state => {
            if ((state.name).toLowerCase().match(inputString.toLowerCase()))
                states.push(state.name)
        })
        setStateData(states)
    }
    const CountryOption = (country) => {
        setCountry(country)
        document.getElementById('countryInput').value = country;
        document.getElementById('countryOption').style.display = 'none';
    }
    const StateOption = (state) => {
        setState(state)
        document.getElementById('stateInput').value = state;
        document.getElementById('stateOption').style.display = 'none';
    }

    const openOpt = () => {
        document.getElementById('optBtnDiv').style.right = '1px'
    }
    const closeOpt = () => {
        document.getElementById('optBtnDiv').style.right = '-15rem'
    }

    // Remove account window 
    const RemoveAccountDiv = (data) => {
        setRemoveAccountDiv(data)
    }


    const [cropCoverImg, setCropCoverImg] = useState();
    const [cropProfileImg, setCropProfileImg] = useState();

    //   Crop Cover Image
    const cropCover = (e) => {
        setCroper(true)
        setCropCoverImg(e.target.files[0])
    };
    function urlTofile_cover(url, filename, mimeType) {
        return (fetch(url).then(function (res) {
            return res.arrayBuffer();

        }).then(function (buf) {
            return new File([buf], filename, { type: mimeType })
        }))
    }

    const getCroppedCover = () => {
        setCroper(false)
        setCropCoverImg('')
        if (cropperRef.current?.cropper !== "undefined") {
            const str = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
            const type = str.split(';')[0].split('/')[1];
            urlTofile_cover(cropperRef.current?.cropper.getCroppedCanvas().toDataURL(), 'cover.png', `image/${type}`).then(function (file) {
                setCover(file)
            })
        }
    };

    // Crop Profile Image
    const cropProfile = (e) => {
        setCroper(true)
        setCropProfileImg(e.target.files[0])
    };

    function urlTofile_profile(url, filename, mimeType) {
        return (fetch(url).then(function (res) {
            return res.arrayBuffer();

        }).then(function (buf) {
            return new File([buf], filename, { type: mimeType })
        }))
    }

    const getCroppedProfile = () => {
        setCroper(false)
        setCropProfileImg('')
        if (cropperRef.current?.cropper !== "undefined") {
            const str = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
            const type = str.split(';')[0].split('/')[1];
            urlTofile_profile(cropperRef.current?.cropper.getCroppedCanvas().toDataURL(), 'profile.png', `image/${type}`).then(function (file) {
                setProfile(file)
            })
        }
    };

    const refreshPrvPost = (val) => {
        setRefresh(val)
    }

    return (
        <>
            {cropCoverImg && croper &&
                <ImageCropper>
                    <Cropper
                        style={{ height: 400, width: "90%" }}
                        aspectRatio={1200 / 412}
                        preview=".img-preview"
                        src={URL.createObjectURL(cropCoverImg)}
                        ref={cropperRef}
                        viewMode={1}
                        guides={true}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        checkOrientation={false}
                    />
                    <CropBtns >
                        <IoCloseCircleOutline color='white' size={36} cursor='pointer' onClick={() => setCroper(false)} title='Close' /> &nbsp; &nbsp;
                        <GoCheckCircleFill onClick={getCroppedCover} color='white' size={32} cursor='pointer' title='Select Cropped Image' />
                    </CropBtns>
                </ImageCropper>
            }
            {cropProfileImg && croper &&
                <ImageCropper>
                    <Cropper
                        style={{ height: 400, width: "90%", }}
                        aspectRatio={500 / 500}
                        preview=".img-preview"
                        src={URL.createObjectURL(cropProfileImg)}
                        ref={cropperRef}
                        viewMode={1}
                        guides={true}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        checkOrientation={false}
                    />
                    <CropBtns >
                        <IoCloseCircleOutline color='white' size={36} cursor='pointer' onClick={() => setCroper(false)} title='Close' /> &nbsp; &nbsp;
                        <GoCheckCircleFill onClick={getCroppedProfile} color='white' size={32} cursor='pointer' title='Select Cropped Image' />
                    </CropBtns>
                </ImageCropper>
            }
            <NavMenu />
            <ProfileContainer>
                <Cover>
                    <img src={fetchedCover ? process.env.REACT_APP_URL_API + '/Cover/' + fetchedCover : ''} style={{ position: 'absolute', filter: 'blur(50px)', width: '100%', height: '100%' }} />
                    <img src={fetchedCover ? process.env.REACT_APP_URL_API + '/Cover/' + fetchedCover : ''} style={{ width: 'auto', height: '100%', zIndex: '+1' }} />
                    {/* Options  */}
                    <HiDotsVertical style={opt} onClick={() => openOpt()} />
                    <div style={optBtnDiv} id='optBtnDiv'>
                        <BiMessageSquareEdit size={16} onClick={() => setProfileForm(true)} style={{ ...optBtn, background: 'white' }} title='Edit Profile' />
                        <VscEyeClosed style={{ ...optBtn, background: 'white' }} title='Close Account' onClick={() => setRemoveAccountDiv(true)} />
                        <MdClose onClick={() => closeOpt()} style={{ ...optBtn, color: 'white', background: 'black' }} />
                    </div>
                </Cover>
                <Userinfo>
                    <LeftPanel>
                        <ProfileIMG src={fetchedProfile ? process.env.REACT_APP_URL_API + '/Profile/' + fetchedProfile : profileImg}></ProfileIMG>
                        <div style={{ margin: '10px' }}>
                            <h3 style={{ display: 'flex', margin: '0' }}>{name ? name : <span style={{ color: '#c0c0c0' }}>Your Name</span>}</h3>
                            <span style={{ display: 'flex', fontSize: '10px', margin: '0 2px' }}>{profession ? profession : <span style={{ color: '#c0c0c0' }}>Your Profession</span>}</span>
                        </div>
                        <div style={{ fontSize: '10px', margin: '0 12px', }}><span style={{ fontWeight: 'bold' }}>Profile status :</span> <span style={profileStatus === 'Incomplete' ? { color: 'red' } : { color: 'green' }}>{profileStatus}</span> </div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: '1rem 0', fontSize: '12px', fontWeight: 'bold' }}>
                            {gender ? <span style={span}><BsGenderAmbiguous size={16} style={{ margin: '0 10px' }} /> {gender}</span> :
                                <span style={span}><BsGenderAmbiguous size={16} style={{ margin: '0 10px' }} /> <span style={{ color: '#c0c0c0' }}> M/F </span></span>}
                            {(country || state) ? <span style={span}><FiMapPin size={16} style={{ margin: '0 10px' }} />{country}{country && ','} {state}</span> : <span style={span}> <FiMapPin size={16} style={{ margin: '0 10px' }} /> <span style={{ color: '#c0c0c0' }}> County, State </span></span>}
                            {dob ? <span style={span}><FaBirthdayCake size={16} style={{ margin: '0 10px' }} />{dob}</span> :
                                <span style={span}><FaBirthdayCake size={16} style={{ margin: '0 10px' }} /><span style={{ color: '#c0c0c0' }}>Date of birth</span></span>}
                            {username && <span style={span}><AiOutlineMail size={16} style={{ margin: '0 10px' }} /> {username}</span>}
                            <SocialMedia>
                                <IoShareSocial size={16} />
                                {fb || insta || twitter ?
                                    <div style={{ display: 'flex', padding: '8px', alignItems: 'center', marginLeft: '8px', background: '#29351e', borderRadius: '20px' }}>
                                        {fb && <a href={fb} style={{ display: 'flex', margin: '0 2px', color: 'black' }}><FaFacebookF size={16} style={{ color: 'white' }} /></a>}
                                        {insta && <a href={insta} style={{ display: 'flex', margin: '0 8px', color: 'black' }}><AiOutlineInstagram size={16} style={{ color: 'white' }} /></a>}
                                        {twitter && <a href={twitter} style={{ display: 'flex', margin: '0 8px', color: 'black' }}><AiOutlineTwitter size={16} style={{ color: 'white' }} /></a>}
                                        {linkedIn && <a href={linkedIn} style={{ display: 'flex', margin: '0 2px', color: 'black' }}><TiSocialLinkedin size={16} color='white' /></a>}
                                    </div> :
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <FaFacebookF size={14} style={{ color: '#c0c0c0', margin: '0 6px' }} />
                                        <AiOutlineInstagram size={16} style={{ color: '#c0c0c0', margin: '0 6px' }} />
                                        <AiOutlineTwitter size={18} style={{ color: '#c0c0c0', margin: '0 6px' }} />
                                    </div>}
                            </SocialMedia>

                        </div>
                    </LeftPanel>
                    <RightPanel>
                        <h2 style={{ display: 'flex', margin: '0', alignItems: 'center' }}><HiOutlineUserCircle /> <span style={{ margin: '0 10px', fontSize: '20px' }}> Profile </span></h2>
                        {/* Personal Information  */}
                        <div>
                            <h2 style={{ fontSize: '16px', padding: '10px 0', margin: 0, marginTop: '1rem' }}>About yourself</h2>
                            <p style={p}>{personalInfo}</p>
                        </div>
                        <div>
                            <h2 style={{ fontSize: '16px', padding: '10px 0', margin: 0, marginTop: '1rem' }}>Interest & Hobbies</h2>
                            <p style={p}>{interest}</p>
                        </div>
                    </RightPanel>
                </Userinfo>
            </ProfileContainer>
            {profileForm &&
                <ProfileForm>
                    <Form className='profileform' onSubmit={submit} encType='multipart/form-data'>
                        {/* cover  */}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', position: 'relative' }}>
                            <div style={screenSize > 650 ? { height: '288px', width: '100%', background: '#000000e8' } : { height: '154px', width: '100%', background: '#000000e8' }}>
                                <div style={{ display: 'flex', position: 'relative', justifyContent: 'center', height: '100%', overflow: 'hidden', background: '#000000e8' }}>
                                    {/* This is cover background -> filter blur */}
                                    <img src={fetchedCover && !cover ? process.env.REACT_APP_URL_API + '/Cover/' + fetchedCover : cover ? URL.createObjectURL(cover) : ''} style={{ position: 'absolute', filter: 'blur(50px)', width: '100%', height: '100%' }} />
                                    {/* This is orignal cover Image  */}
                                    <img style={{ width: 'auto', height: '100%', zIndex: '+1' }} src={fetchedCover && !cover ? process.env.REACT_APP_URL_API + '/Cover/' + fetchedCover : cover ? URL.createObjectURL(cover) : ''} />
                                </div>
                                <div style={{ display: 'flex', color: 'white', position: 'absolute', bottom: '5%', right: '2%', cursor: 'pointer', width: '1rem', height: '1rem', overflow: 'hidden', zIndex: '+2' }}>
                                    <FiEdit size={16} style={{ color: 'lime', }} />
                                    <input type='file' style={{ opacity: '0', position: 'absolute' }} accept="image/*" onChange={cropCover} />

                                </div>
                                <span style={{
                                    color: 'gray',
                                    fontSize: '10px',
                                    fontFamily: 'Manrope',
                                    margin: '4px',
                                }}>Size (1200 x 412px)</span>
                            </div>
                            {/* profile  */}
                            <ProfileDiv >
                                <img style={{ width: '6rem', height: '6rem', borderRadius: '10rem', zIndex: '+2' }}
                                    src={fetchedProfile && !profile ? process.env.REACT_APP_URL_API + '/Profile/' + fetchedProfile : profile ? URL.createObjectURL(profile) : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'} />
                                <div style={{
                                    display: 'flex',
                                    position: 'absolute',
                                    height: '2rem',
                                    overflow: 'hidden',
                                    justifyContent: 'center',
                                    width: '100%',
                                    padding: '5px 0',
                                    background: 'rgb(0 0 0 / 55%)',
                                    color: 'white',
                                    bottom: '-15%',
                                    zIndex: '+2',
                                }}>
                                    <RiImageAddLine />
                                    <input type='file' style={{ position: 'absolute', opacity: '0' }} onChange={cropProfile} accept="image/png, image/jpg, image/jpeg" />
                                </div>
                            </ProfileDiv>
                        </div>
                        <UserInfoForm>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input defaultValue={name} style={{ ...input, width: '50%' }} type='text' placeholder='Full Name' onChange={(e) => setName(e.target.value)} />
                                &nbsp;
                                <input defaultValue={phone} style={{ ...input, width: '50%' }} type='text' placeholder='Phone/Mob' onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <input style={{ ...input, marginRight: '2px' }} placeholder='Country' onChange={(e) => MyCountry(e.target.value)} defaultValue={country} id='countryInput' />
                                    <div id='countryOption' style={{
                                        display: 'flex', flexDirection: 'column', background: '#29351e', maxHeight: '4rem',
                                        overflow: 'hidden',
                                        overflowY: 'auto',
                                        fontFamily: 'Manrope',
                                        fontSize: '12px',
                                        borderRadius: '2px',
                                        color: 'white',
                                    }}>
                                        {countryData && countryData.map(country => {
                                            return <span key={country} style={{ cursor: 'pointer', margin: '3px 5px' }} onClick={() => CountryOption(country)}>{country}</span>
                                        })}
                                    </div>
                                </div>
                                &nbsp;
                                <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                                    <input style={{ ...input, marginRight: '2px' }} placeholder='State' onChange={(e) => MyState(e.target.value)} defaultValue={state} id='stateInput' />
                                    <div id='stateOption' style={{
                                        display: 'flex', flexDirection: 'column', background: '#29351e', maxHeight: '4rem',
                                        overflow: 'hidden',
                                        overflowY: 'auto',
                                        fontFamily: 'Manrope',
                                        fontSize: '12px',
                                        borderRadius: '2px',
                                        color: 'white',
                                    }}>
                                        {stateData && stateData.map(state => {
                                            return <span key={Math.random()} style={{ cursor: 'pointer', margin: '3px 5px' }} onClick={() => StateOption(state)}>{state}</span>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <select defaultValue={gender} style={select} onChange={(e) => setGender(e.target.value)}>
                                    <option value=''> ⚥ Select gender</option>
                                    <option value='Male'>🚹 Male</option>
                                    <option value='Female'>🚺 Female</option>
                                </select>
                                <input defaultValue={dob} style={{ ...input, width: '48%', marginLeft: '2px', }} type='text' placeholder='Date of Birth' onFocus={(e) => e.target.type = 'date'} onChange={(e) => setDOB(e.target.value)} />
                            </div>
                            <input defaultValue={profession} style={input} type='text' placeholder='Profession' onChange={(e) => setProfession(e.target.value)} />

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <textarea defaultValue={personalInfo} style={textarea} placeholder='Persoanl Info/ Describe Yourself' onChange={(e) => setPersoanlInfo(e.target.value)}></textarea>
                                &nbsp;
                                <textarea defaultValue={interest} style={textarea} placeholder='Area of Interest/Interest in topics' onChange={(e) => setInterest(e.target.value)}></textarea>
                            </div>

                            <div>
                                <span style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontFamily: 'Manrope',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    marginTop: '24px',
                                    marginBottom: '8px',
                                }}> Link your social media handles</span>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <input defaultValue={fb} style={{ ...input, marginRight: '2px', width: '50%' }} type='text' placeholder='https://facebook.com/your-fb-username' onChange={(e) => setFB(e.target.value)} />
                                    <input defaultValue={insta} style={{ ...input, marginLeft: '2px', width: '50%' }} type='text' placeholder='https://instagram.com/your-insta-username' onChange={(e) => setInsta(e.target.value)} />
                                </div>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <input defaultValue={twitter} style={{ ...input, marginRight: '2px', width: '50%' }} type='text' placeholder='https://twitter.com/your-twitter-username' onChange={(e) => setTwitter(e.target.value)} />
                                    <input defaultValue={linkedIn} style={{ ...input, marginLeft: '2px', width: '50%' }} type='text' placeholder='https://linkedin.com/your-linked-username' onChange={(e) => setLinkedIn(e.target.value)} />
                                </div>
                            </div>
                            <button style={formBtn} type='submit' className='btnHover'>{loader ? <Loader /> : <>Save Profile</>}</button>
                        </UserInfoForm>
                        {/* close Form  */}
                        <div style={closeFormDiv} onClick={() => setProfileForm(false)}>
                            <IoCloseCircleOutline size={20} />
                        </div>
                    </Form>
                </ProfileForm>

            }
            <HeaderPlate>
                <div style={{ display: 'flex', flexDirection: 'column', fontFamily: 'Manrope', }}>
                    <H2>POST YOUR CONTENT</H2>
                    <HeaderPlateSubHeading >Before starting make sure you complete your profile including Name, Country & Profession. It will be visible on your uploaded articles.</HeaderPlateSubHeading>
                    <UpdateProfileBtn onClick={() => setProfileForm(true)}>Update Profile</UpdateProfileBtn>
                </div>
                <Avatar src={AvatarPNG} />
            </HeaderPlate>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                width: '60%',
                margin: '0 auto',
                justifyContent: 'center',
                fontFamily: 'Manrope',
                marginTop: '-24px',
                color: '#5A5560'
            }}><IoRocketOutline /><span style={{ fontSize: '12px', margin: '0 4px', }}>Now you are ready to go!</span> </div>
            {/* create article  */}
            <Create userArticles={userArticles} />
            {/* User Posts  */}
            <HeaderPlate >
                <div style={{ display: 'flex', flexDirection: 'column', fontFamily: 'Manrope', }}>
                    <img src={caligraphyImg} alt='caligraphy' style={caligraphyImgStyle} />
                    <AnalyticsTag>#analytics &nbsp;<SiSimpleanalytics /></AnalyticsTag>
                    <H2>YOUR UPLOADED ARTICLES</H2>
                    <HeaderPlateSubHeading style={{ alignItems: 'center', padding: '2px' }}>
                        You can improve your article from time to time. Your audience will benefit from regular updates to your content !
                    </HeaderPlateSubHeading>
                </div>
                <Avatar src={AvatarPNG} />
            </HeaderPlate>
            {/* Uploaded blogs and analytics  */}
            <PostsAndAnalyticsOpts>
                <span
                    style={posts ? {
                        border: '1px solid',
                        borderBottom: 'none',
                        margin: '-1px 16px',
                        background: 'white',
                        padding: '5px 10px',
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '4px',
                        cursor: 'pointer',
                    } : { border: 'none', cursor: 'pointer', margin: '-1px 16px', }}
                    onClick={() => setPosts(true) + setAnalytics(false)}>Posts</span>
                <span
                    style={analytics ? {
                        border: '1px solid',
                        borderBottom: 'none',
                        margin: '-1px 0',
                        background: 'white',
                        padding: '5px 10px',
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '4px',
                        cursor: 'pointer',
                    } : { border: 'none', cursor: 'pointer', }}
                    onClick={() => setPosts(false) + setAnalytics(true)}>Analytics</span>
            </PostsAndAnalyticsOpts>
            {posts && <PrvPost userArticles={userArticles} refresh={refreshPrvPost} />}
            {analytics && <Analytics />}
            {/* Remove Account window  */}
            {removeAccountDiv && <RemoveAccount RemoveAccountDiv={RemoveAccountDiv} />}
            {/* Alert Message  */}
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'fixed', left: '0', bottom: '0' }}>
                {alertCall && <Alert alertMsg={alertMsg} alertType={alertType} AlertMsg={AlertMsg} />}
            </div>
        </>
    )
}

export default Profile;