import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Loader from '../subComponents/Loader'
import Alert from '../subComponents/Alert'
import '../App.css'
import { IoIosCloseCircle } from 'react-icons/io'
import { IoImagesSharp } from 'react-icons/io5'
import { MdDeleteOutline } from 'react-icons/md'

const PanelHeader = styled.div`
   display: flex;
   flex-direction: column;
   font-size: 20px;
   font-weight: bold;
   background: #3e8908;
   color: white;
   padding: 16px;
   margin-bottom: 24px;
`
const H2 = styled.h2`
    display: flex; 
    align-items: center; 
    font-size: 16px;
    margin: 2px;
`
const Uploads = styled.div`
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    height: 100%;
    width: 100%;
    background: #000800b8;
    z-index: +3;
`
const PicDataPanel = styled.div`
    display: flex; 
    flex-direction: column; 
    position: relative; 
    background: white;
    border-radius: 16px;
    font-family: Manrope;
    width: 1000px;
    height: 80%;
    overflow: hidden;
    margin: 8px;
}

    @media (max-width: 420px){
        min-width: 22rem;
        max-width: 20rem;
    }
    @media (max-width: 340px){
        max-width: 18rem;
        min-width: 16rem;
    }
`

const PicData = styled.div`
    display: flex;
    flex-direction: column;
    
`

const deleteIcon = {
    position: 'absolute',
    bottom: '8px',
    right: '8px',
    color: '#6c6c6c',
    background: '#efefef',
    padding: '4px',
    borderRadius: '1rem',
    cursor: 'pointer',
}

const ImgBox = styled.div`
    display: flex;
    position: relative;
    width: 212px;
    height: 140px;
    margin: 0 8px;
    justify-content: center;
    border-radius: 8px;
    align-items: center;
    box-shadow: 0 0 5px lightgray;
    overflow: hidden;

    @media (max-width: 420px){
        width: 175px;
        height: 116px;
    }

`
const UploadDate = styled.span`
    margin: 8px 8px 0px;
    font-size: 12px;
    color: dimgray;
    font-family: 'Roboto';
    padding: 2px;
`
const ImgContainer = styled.div`
    display: -webkit-box;
    overflow: auto hidden;
    padding: 4px 0px;
`


function UserUploads(props) {
    const [refresh, setRefresh] = useState();
    const [picData, setPicData] = useState([])
    const [alertCall, setAlertCall] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('');
    const userName = sessionStorage.getItem('username');

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL_API + '/pictures/' + userName)
            .then(response => {
                setPicData(response.data.result)
            })
            .catch(error => {
                console.log(error)
            })
    }, [refresh])

    const closePicData = () => {
        props.picDataPanel(false);
    }

    // COPY LINK OF IMAGES 
    const copyLink = (pic) => {
        let url = process.env.REACT_APP_URL_API + '/Pictures/' + pic
        navigator.clipboard.writeText(url).then(function () {
            setAlertCall(true)
            setAlertType('success')
            setAlertMsg('Link copied to clipboard !')
            setTimeout(() => {
                setAlertCall(false)
            }, 5000)
        }, function () {
            setAlertCall(true)
            setAlertMsg('Please try again !')
            setAlertType('error')
            setTimeout(() => {
                setAlertCall(false)
            }, 5000)
        });
    }

    // ALERT CALL MESSAGE PROPS 
    const AlertMsg = (AlertMsg) => {
        setAlertCall(AlertMsg)
    }

    const deletePic = (id, pic) => {
        axios.post(process.env.REACT_APP_URL_API + '/picture/remove/', {
            id: id,
            pic: pic,
            username: userName,
        })
            .then(response => {
                setAlertCall(true)
                setAlertType('success')
                setAlertMsg(response.data.result)
                setRefresh(Math.random())
                setTimeout(() => {
                    setAlertCall(false)
                }, 2000)
            })
            .catch(error => {
                alert('Something went wrong!')
            })
    }
    return (
        <Uploads>
            <PicDataPanel>
                <PanelHeader>
                    <H2> <IoImagesSharp size={18} /> &nbsp; Choose image files for the article. </H2>
                    <span style={{
                        fontSize: '11px',
                        fontWeight: 200,
                        marginLeft: '27px',
                        fontFamily: 'Roboto',
                        letterSpacing: '0.5px',
                    }}>Click on image to copy !</span>
                </PanelHeader>
                <div style={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', overflowY: 'auto', }}>
                    {
                        picData.map(val => (
                            <PicData key={Math.random() * 1000}>
                                <UploadDate>UPLOADED ON {val.Date}</UploadDate>
                                <ImgContainer>
                                    {val.Picture.map(pic => (
                                        <ImgBox>
                                            <img src={process.env.REACT_APP_URL_API + '/Pictures/' + pic} style={{ height: '100%', cursor: 'pointer' }} onClick={() => copyLink(pic)} alt='' />
                                            <MdDeleteOutline style={deleteIcon} onClick={() => deletePic(val._id, pic)} />
                                        </ImgBox>
                                    ))}
                                </ImgContainer>
                            </PicData>
                        ))
                    }
                </div>
                <IoIosCloseCircle size={22} style={{
                    position: 'absolute',
                    right: '8px',
                    top: '8px',
                    color: 'white',
                    cursor: 'pointer',
                }} onClick={() => closePicData()} />
            </PicDataPanel>
            {/* Alert Window  */}
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'fixed', left: '0', bottom: '0' }}>
                {alertCall && <Alert alertMsg={alertMsg} alertType={alertType} AlertMsg={AlertMsg} />}
            </div>
        </Uploads>
    )
}


export default UserUploads