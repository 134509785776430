import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ScreenSizeDetector from 'screen-size-detector';
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { IoCreateOutline } from 'react-icons/io5'
import { FaShareSquare } from 'react-icons/fa'
import { IoBugSharp } from 'react-icons/io5'
import { MdConnectWithoutContact } from 'react-icons/md'
import { AiOutlineMail } from 'react-icons/ai'
import { FaUserCheck } from 'react-icons/fa'
import { MdOutlineLogout } from 'react-icons/md'
import { AiOutlineHome } from 'react-icons/ai'
import { GoComment } from 'react-icons/go'
import { TfiWrite } from 'react-icons/tfi'
import { NavLink } from "react-router-dom";



const Menubar = styled.div`
   display: flex;
   flex-direction: column;
   background: #102300;
   color: white;
   width: 20rem;
   height: 100%;
   position: fixed;
   z-index: +1;
   transition: 0.5s all;
   font-family: Manrope;
   
`

const h3 = {
    display: 'flex',
    color: '#092900',
    background: 'white',
    padding: '10px',
    position: 'relative',
    alignItems: 'center',
}
const Mobh3 = {
    display: 'flex',
    color: '#092900',
    background: 'white',
    padding: '5px 10px',
    position: 'relative',
    alignItems: 'center',
    margin: '12px 0px',
}
const closeMenu = {
    position: 'absolute',
    right: '10px',
    cursor: 'pointer'

}
const icons = {
    color: '#6be912',
    margin: '0 10px'
}
const li = {
    display: 'flex',
    listStyle: 'none',
    margin: '32px 0',
    cursor: 'pointer',
    alignItems: 'center',
    fontSize: '14px',
}
const navlink = {
    textDecoration: 'none',
    color: 'white',
}

function Menu(props) {

    const [screenSize, setScreenSize] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            const screen = new ScreenSizeDetector();
            setScreenSize(screen.width)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        };

    }, [])



    const close = () => {
        props.closefun(false)
    }
    const createAccount = () => {
        props.createAccount(true);
    }
    const ReportBug = () => {
        props.ReportBug(true)
    }
    const ConnectWithUs = () => {
        props.ConnectWithUs(true)
    }
    const tutorial = () => {
        props.tutorial(true)
    }

    const userName = sessionStorage.getItem('username')
    const logout = () => {
        sessionStorage.removeItem('username')
        window.location.reload(false);
    }
    return (
        <>
            <Menubar style={props.openMenu ? { left: '0', top: '0' } : { left: '-20rem', top: '0' }}>
                <h3 style={screenSize < 650 ? Mobh3 : h3}>MENU <AiOutlineCloseCircle style={closeMenu} onClick={() => close()} /></h3>
                <ul style={{ padding: '0px' }}>
                    <li style={li} onClick={() =>  sessionStorage.setItem('activeMenu', 'home')}>  <AiOutlineHome size={20} style={icons} /><NavLink to='/' style={navlink} onClick={close}> Home </NavLink>   </li>
                    <li style={li} onClick={() =>  sessionStorage.setItem('activeMenu', 'about')}>  <GoComment size={20} style={icons} /> <NavLink to='/about' style={navlink} onClick={close}> About </NavLink>  </li>
                    {userName ?
                        <li style={li}>  <FaUserCheck size={20} style={icons} /> <NavLink to='/profile' style={navlink} onClick={close}> {userName} </NavLink> <MdOutlineLogout size={20} style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={logout} title='Logout' />
                        </li> :
                        <li style={li} onClick={() => createAccount()}>  <IoCreateOutline size={20} style={icons} /> Create Account / Login </li>
                    }
                    <li style={li} onClick={() => ReportBug()}> <IoBugSharp size={20} style={icons} /> Report Bugs </li>
                    <li style={li} onClick={() => ConnectWithUs()}>  <MdConnectWithoutContact size={20} style={icons} />Connect with us </li>
                    <li style={li} onClick={() => tutorial()}>  <TfiWrite size={20} style={icons} />How to write article</li>
                    <li style={li}>  <AiOutlineMail size={20} style={icons} />Contact email : web@techbitlife.com </li>
                </ul>
            </Menubar>

        </>
    )
}
export default Menu;