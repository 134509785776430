import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Loader from '../subComponents/Loader'
import { ScaleLoader } from "react-spinners";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { alertActive } from '../Redux'
import { alertDeactive } from '../Redux'
//
import { BsFillReplyFill } from 'react-icons/bs'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import { IoIosRemoveCircleOutline } from 'react-icons/io'
import { Editor } from '@tinymce/tinymce-react'
import { BiSolidEdit } from 'react-icons/bi'
//
import defaultProfile from '../Images/defaultProfile.png'



const Li = styled.li`
    display: flex;
    flex-direction: column;
    font-size: 14px;
`

const C_Options = styled.div`
    display: flex;
    align-items: center;
`
const Input = styled.input`
    padding: 8px;
    font-size: 14px;
    font-family: Manrope;
    max-width: 400px;
    border: 1px solid lightgrey;
    border-radius: 4px;
    margin: 8px 0;
`
const Btn = styled.button`
    background: #3b7d05;
    color: white;
    border-radius: 4px;
    border: none;
    padding: 4px 13px;
    margin-left: 8px;
    cursor: pointer;
`
const RemoveCommentPanel = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 5%;
`

const UserProfile = styled.img`
    display: flex;
    width: 28px;
    height: 28px;
    border-radius: 2rem;
    padding: 2px;
    border: 1px solid #e5e5e5;
    background: white;
`
const confirmBtn = {
    background: '#3b7d05',
    border: '1px solid #3b7d05',
    color: 'white',
    padding: '4px 8px',
    borderRadius: '2px',
    cursor: 'pointer',
    width: '40px',
}

const cOpts = {
    background: '#e7e7e7',
    padding: '5px',
    borderRadius: '2rem',
    cursor: 'pointer',
}

const Uploaded_Date = styled.p`
    font-size: 10px;
    color: gray;
    margin: 2px 0;
    font-family: Manrope;
    font-style: italic;
`

function NewComments(props) {
    const [showReplyBox, setShowReplyBox] = useState(false)
    const [showEditBox, setShowEditBox] = useState(false)
    const username = sessionStorage.getItem('username')
    const editorRef = useRef()
    const [textEditor, setTextEditor] = useState(true);
    // editordata for comment 
    const [editorData, setEditorData] = useState();
    const [confirmation, SetConfirmation] = useState(false);
    const [loader, setLoader] = useState(false)
    const dispatch = useDispatch()

    //
    const Editorplugins = [' image charmap ', 'searchreplace visualblocks code fullscreen',
        'insertdatetime paste codesample code help wordcount'
    ]
    const EditorToolbar = 'undo redo | alignleft  aligncenter alignright |' +
        'bold italic forecolor  backcolor |' +
        'bullist numlist| ' +
        'removeformat |' +
        'pagebreak | charmap emoticons | fullscreen | ' +
        'insertfile image template codesample | ltr rtl'

    useEffect(() => {
        setShowReplyBox(props.showReplyBox)
        setShowEditBox(props.showEditBox)
    }, [props])

    const replyBox = () => {
        if (username) {
            setShowReplyBox(true)
        } else {
            dispatch(alertActive('error', 'Please sign in to reply.'))
            setTimeout(() => {
                dispatch(alertDeactive())
            }, 3000)
        }
    }

    return (
        <>
            <Li key={props.comment.id}>
                <div style={{ display: 'flex', alignItems: 'flex-start', margin: '8px 0' }}>
                    <UserProfile src={props.comment.AuthorProfile ? process.env.REACT_APP_URL_API + '/Profile/' + props.comment.AuthorProfile : defaultProfile} /> &nbsp; &nbsp;
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ fontWeight: 600, color: '#393b36' }}>{props.comment.AuthorName ? props.comment.AuthorName : props.comment.Username}</span>
                        <Uploaded_Date>On {props.comment.Date}</Uploaded_Date>
                        <div style={{
                            fontSize: '13px',
                            fontWeight: 500,
                            textAlign: 'justify',
                        }}
                            dangerouslySetInnerHTML={{ __html: props.comment.Comment }} />

                        {!showReplyBox && !showEditBox &&
                            <C_Options >
                                <BsFillReplyFill color="gray" style={cOpts} title="Reply" onClick={() => replyBox()} />
                                &nbsp; &nbsp; &nbsp;
                                {props.comment.Username === username && !confirmation &&
                                    <BiSolidEdit color="gray" style={cOpts} title="Edit" onClick={() => {
                                        setShowEditBox(true)
                                        setEditorData(props.comment.Comment)
                                    }} />
                                } &nbsp; &nbsp; &nbsp;
                                {props.comment.Username === username && !confirmation &&
                                    <span style={{ ...cOpts, color: 'red', display: 'flex', alignItems: 'center' }}>
                                        <IoIosRemoveCircleOutline onClick={() => SetConfirmation(true)} title="Remove" />
                                    </span>
                                }

                                {confirmation &&
                                    <RemoveCommentPanel>
                                        <span style={{ fontWeight: 600, color: '#d90000', }}>Confirm Remove ?</span> &nbsp; &nbsp; &nbsp;
                                        <button style={{ ...confirmBtn, background: 'white', color: 'black', border: '1px solid' }} onClick={() => SetConfirmation(false)}>No</button>
                                        <Btn style={confirmBtn} onClick={() => {
                                            props.removeComment(props.comment.id)
                                        }}> {loader == 'removeComment' ? <ScaleLoader color="white" /> : <>Yes </>} </Btn>
                                    </RemoveCommentPanel>
                                }
                            </C_Options>}
                    </div>
                </div>
                {showReplyBox &&
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {textEditor &&
                            <Editor
                                apiKey="me91nhwamlrdq769kp9dcmpt4i7lrqodz6eyswsjpxevphss"
                                onInit={(evt, editor) => editorRef.current = editor}
                                init={{
                                    height: '8rem',
                                    menubar: false,
                                    statusbar: false,
                                    branding: false,
                                    skin: 'fabric',
                                    icons: "thin",
                                    placeholder: 'Write your comment.',
                                    inline_boundaries: true,
                                    plugins: Editorplugins,
                                    toolbar: EditorToolbar,
                                    content_style: "@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');body {font - family:'Manrope', sans-serif; font-size: 15px; line-height: 0.7;}",
                                    font_formats: "'Manrope', sans-serif;",
                                }}
                                onChange={() => setEditorData(editorRef.current.getContent())}
                            />
                        }

                        <div style={{
                            display: 'flex', alignItems: 'center', margin: '8px 0',
                            justifyContent: 'right',
                        }}>
                            <IoIosCloseCircleOutline onClick={() => setShowReplyBox(false)} size={24} cursor='pointer' />
                            <Btn onClick={() => {
                                props.addReply(props.comment.id, editorData);
                            }}> {loader == 'reply' ? <ScaleLoader color="white" /> : <>Done </>} </Btn>
                        </div>
                    </div>
                }
                {showEditBox &&
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {textEditor &&
                            <Editor
                                apiKey="me91nhwamlrdq769kp9dcmpt4i7lrqodz6eyswsjpxevphss"
                                initialValue={props.comment.Comment}
                                onInit={(evt, editor) => editorRef.current = editor}
                                init={{
                                    height: '8rem',
                                    menubar: false,
                                    statusbar: false,
                                    branding: false,
                                    skin: 'fabric',
                                    icons: "thin",
                                    placeholder: 'Write your comment.',
                                    inline_boundaries: true,
                                    plugins: Editorplugins,
                                    toolbar: EditorToolbar,
                                    content_style: "@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');body {font - family:'Manrope', sans-serif; font-size: 15px; line-height: 0.7;}",
                                    font_formats: "'Manrope', sans-serif;",
                                }}
                                onChange={() => setEditorData(editorRef.current.getContent())}
                            />
                        }

                        <div style={{
                            display: 'flex', alignItems: 'center', margin: '8px 0',
                            justifyContent: 'right',
                        }}>
                            <IoIosCloseCircleOutline onClick={() => setShowEditBox(false)} size={24} cursor='pointer' />
                            <Btn onClick={() => {
                                props.editComment({ commentText: editorData, id: props.comment.id });
                            }}> {loader == 'editComment' ? <ScaleLoader color="white" /> : <>Done </>} </Btn>
                        </div>
                    </div>
                }
                {props.comment.Children && props.comment.Children.length > 0 && (
                    <ul >
                        {props.comment.Children.map((childComment) => (
                            <NewComments
                                key={childComment.id}
                                comment={childComment}
                                addReply={props.addReply}
                                removeComment={props.removeComment}
                                editComment={props.editComment}
                            />
                        ))}
                    </ul>
                )}
            </Li>
        </>
    )
}
export default NewComments;