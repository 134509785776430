import React, { useState, useEffect } from "react";
import axios from 'axios'
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { ImBookmarks } from 'react-icons/im'
import errorImg from '../Images/error.png'
import NavMenu from "../subComponents/NavMenu";
import { useDispatch } from "react-redux";
import { alertActive } from '../Redux'
import { alertDeactive } from '../Redux'


const FavContainer = styled.div`
   display: flex;
   flex-direction: column;
   margin-top: 8rem;
   min-height: 35rem;
   font-family: Manrope;
`

const Fav = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const UserFav = styled.div`
  display: flex;
  flex-direction: column;
  width: 320px;
  margin: 20px;
  background: #f6f8f6;
  border-radius: 8px;
  overflow: hidden;
  
`
const button = {
  border: 'none',
  padding: '10px',
  color: 'white',
  width: '50%',
  cursor: 'pointer',
  margin: '2px',
  borderRadius: '4px',
}

const P = styled.div`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Manrope; 
  font-size: 13px; 
  font-weight: bold; 
  margin: 10px 5px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

`

const RemoveFavContainer = styled.div`
   display: flex;
   position: fixed;
   top: 0;
   width: 100%;
   height: 100%;
   background: #000000d1;
   z-index: +4;
   justify-content: center;
   align-items: center;
`

function Favourites(props) {


  const [article, setArticle] = useState([])
  const [removeFav, setRemoveFav] = useState('')
  const [alertCall, setAlertCall] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [alertType, setAlertType] = useState('');
  const dispatch = useDispatch()
  const username = sessionStorage.getItem('username')
  const token = sessionStorage.getItem('token')

  useEffect(() => {
    let arr = [];
    axios.get(process.env.REACT_APP_URL_API + '/favourites/', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        response.data.result.map(val => {
          val.ArticleData.map(newval => {
            arr.push(newval)
            setArticle(arr)
          })
        })
      }).catch(error => {
        console.log(error)
      })
  }, [])

  // Remove Favorites 
  const removeUserFav = () => {
    axios.post(process.env.REACT_APP_URL_API + '/remove_fav', {
      articleId: removeFav,
    },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    ).then(res => {
      dispatch(alertActive('success', res.data.result))
      setTimeout(() => {
        dispatch(alertDeactive())
        window.location.reload();
      }, 3000)

    }).catch(err => {
      dispatch(alertActive('error', err.response.data.result))
      setTimeout(() => {
        dispatch(alertDeactive())
      }, 3000)
    })
  }

  // COPY LINK OF ARTICLE 
  const copyLink = (type, keyword, blogNumber) => {
    let url = window.location.host + '/article/' + type + '/' + keyword + '/' + blogNumber;
    navigator.clipboard.writeText(url).then(function () {

      dispatch(alertActive('success', 'Link copied to clipboard !'))
      setTimeout(() => {
        dispatch(alertDeactive())
      }, 3000)
    }, function () {
      dispatch(alertActive('error', 'Please try again !'))
      setTimeout(() => {
        dispatch(alertDeactive())
      }, 3000)
    });
  }
  const RemoveFav = () => {
    return (
      <>
        <RemoveFavContainer>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', background: 'white', textAlign: 'center', maxWidth: '375px', borderRadius: '5px' }}>
            <img src={errorImg} style={{ width: '4rem', marginTop: '1rem' }} />
            <span style={{ color: 'red', margin: '10px 0', fontWeight: 'bold' }}> WARNING </span>
            <span style={{ margin: '0', fontSize: '15px', fontWeight: 'bold' }}>Do you really want to remove current article from your favourites ?</span>
            <div style={{ display: 'flex', marginTop: '2rem', width: '100%' }}>
              <button style={{ ...button, background: '#102300', color: 'white' }} onClick={() => removeUserFav()}>Proceed</button>
              <button style={{ ...button, background: 'red', color: 'white' }} onClick={() => setRemoveFav(false)}>Decline</button>
            </div>
          </div>
        </RemoveFavContainer>
      </>
    )
  }

  return (
    <>
      <NavMenu />
      <FavContainer>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1rem 0' }}>
          <ImBookmarks size={16} />
          <span style={{ fontSize: '14px', margin: '0 5px' }}>Your favourite articles</span>
          <span style={{ fontSize: '14px', margin: '0 5px', fontWeight: 'bold' }}>{article.length} results are found</span>
        </div>
        <Fav>
          {article && article.map(val => (
            <UserFav>
              <NavLink to={"/article/" + val.Type + '/' + val.Keyword + '/' + val.BlogNumber}>
                <img src={process.env.REACT_APP_URL_API + '/Thumbnails/' + val.Thumbnail} style={{ width: '320px', height: '200px' }} />
              </NavLink>
              <P>{val.About}</P>
              <div style={{ display: 'flex', alignItems: 'center', position: 'relative', margin: "10px 5px" }}>
                <img src={process.env.REACT_APP_URL_API + '/Profile/' + val.AuthorProfile} style={{ height: '2rem', width: '2rem', borderRadius: '5rem' }} />
                <span style={{ fontSize: '14px', margin: '0 5px', fontWeight: 700, }}>{val.AuthorName}</span>
                <span style={{ fontSize: '13px', fontWeight: 'bold', position: 'absolute', right: '0' }}>{val.Date}</span>
              </div>
              <div style={{ display: 'flex', padding: '10px 0' }}>
                <button className="btnHover" style={{ ...button, background: '#102300' }} onClick={() => copyLink(val.Type, val.Keyword, val.BlogNumber)}>SHARE</button>
                <button style={{ ...button, background: 'red' }} onClick={() => setRemoveFav(val._id)}>REMOVE</button>
              </div>
            </UserFav>
          ))}
        </Fav>
        {removeFav && <RemoveFav />}
      </FavContainer>
    </>
  )
}

export default Favourites;