import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import ScreenSizeDetector from 'screen-size-detector';
import date from 'date-and-time';
import axios from 'axios'
import Alert from '../subComponents/Alert'
import Loader from '../subComponents/Loader'
import { Editor } from '@tinymce/tinymce-react'
import '../App.css'
import { MdUpdate } from 'react-icons/md'


// UPDATE ARTICLE 


const UpdateArticleContainer = {
    display: 'flex',
    position: 'fixed',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    background: '#00000073',
    top: '0',
    zIndex: +4,
    fontFamily: 'Manrope',
    overflow: 'hidden',
    overflowY: 'auto',
}


const Form = styled.div`
  display: grid;
  grid-template-areas: 'left left right right';
  grid-gap: 20px;
  padding: 20px;
  background: white;
  max-width: 1400px;
  overflow: hidden;
  overflowY: auto;
  border-radius: 5px;
  
  @media (max-width: 1000px) {
    grid-template-areas: 'left left left left'
                        'right right right right';
  }

`
const Left = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  grid-area: left;
  min-height: 40rem;
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  grid-area: right;

  @media (max-width: 1000px) {
    height: 35rem;
  }
`

const maxImg = {
    width: '280px',
    height: '177px',
    margin: '20px auto',
    borderRadius: '5px',
}
const minImg = {
    width: '260px',
    height: '160px',
    margin: '20px auto',
    borderRadius: '5px',
}
const input = {
    padding: '7px',
    fontSize: '15px',
    border: '1px solid #acacac',
    fontFamily: 'Manrope',
    fontWeight: 500,
    outline: 'none',
    margin: '10px 0',
    borderRadius: '5px',
    textOverflow: 'ellipsis',
    color: '#152d00',
    whiteSpace: 'nowrap',
}

const button2 = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#102300',
    color: 'white',
    fontSize: '15px',
    cursor: 'pointer',
    margin: '10px 2px',
    padding: '12px',
    border: 'none',
    borderRadius: '5px',
    width: '50%',
}


const CountryOriginAndArticleType_div = {
    display: 'grid',
    position: 'relative',
    gridGap: '5px '
}


//  UPDATE ARTICLE WORK *Start 

function UpdateArticle(props) {

    const [screenSize, setScreenSize] = useState(window.innerWidth);

    const [fetchedThumbnail, setFetchedThumbnail] = useState();
    const [thumbnail, setThumbnail] = useState();
    const [editorData, setEditorData] = useState('');
    const [title, setTitle] = useState('');
    const [about, setAbout] = useState('');
    const [articleType, setArticleType] = useState();
    const editorRef = useRef()

    const [loader, setLoader] = useState(false)

    const [alertCall, setAlertCall] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [alertType, setAlertType] = useState('');



    // Alert Msg 
    const AlertMsg = (AlertMsg) => {
        setAlertCall(AlertMsg)
    }


    // #Screensize
    useEffect(() => {
        const handleResize = () => {
            const screen = new ScreenSizeDetector();
            setScreenSize(screen.width)
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        };

    }, [])
    // #Screensize

    useEffect(() => {
        axios.get(process.env.REACT_APP_URL_API + '/article/' + props.articleId)
            .then(response => {
                // set fetched data for use 
                setFetchedThumbnail(response.data.result.Thumbnail)
                setTitle(response.data.result.Title)
                setAbout(response.data.result.About)
                setArticleType(response.data.result.Type)
                setEditorData(response.data.result.Data)
            }).catch(error => {
                console.log(error)
            })
    }, [props.articleId])

    // updating article 
    const Submit = (e) => {
        e.preventDefault();
        // let thumbnail = document.getElementById('thumbnail').files[0];
        let title = document.getElementById('title').value;
        let about = document.getElementById('about').value;
        let articleType = document.getElementById('articleType').value;
        const now = new Date();

        setLoader(true)
        const formdata = new FormData();
        formdata.append('thumbnail', thumbnail)
        formdata.append('title', title)
        formdata.append('about', about)
        formdata.append('articleType', articleType)
        formdata.append('date', date.format(now, 'DD MMM YYYY'))
        formdata.append('data', editorRef.current.getContent())

        let xhr = new XMLHttpRequest();
        xhr.open('POST', process.env.REACT_APP_URL_API + '/update/' + props.articleId, true);
        xhr.getResponseHeader('Content-type', 'multipart/form-data')
        xhr.onload = () => {
            if (xhr.status === 200) {
                let response = JSON.parse(xhr.responseText)
                setLoader(false)
                setAlertCall(true)
                setAlertType('success')
                setAlertMsg(response.result)
                setTimeout(() => {
                    window.location.reload();
                }, 2000)
            } else {
                let response = JSON.parse(xhr.responseText)
                setLoader(false)
                setAlertCall(true)
                setAlertType('error')
                setAlertMsg(response.result)
                setTimeout(() => {
                    setAlertCall(false)
                }, 2000)
            }
        }
        xhr.send(formdata)

    }


    // close update panel 
    const editPanel = () => {
        props.editPanel(false);
    }

    // FOR Editor 
    const Plugins = [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks codesample code fullscreen',
        'insertdatetime media table paste code help wordcount'
    ]
    const Toolbar = 'undo redo | alignleft  aligncenter alignright alignjustify|' +
        'fontsizeselect | fontselect | bold italic forecolor  backcolor | formatselect |' +
        'bullist numlist outdent indent | ' +
        'removeformat |' +
        'pagebreak | charmap emoticons | fullscreen preview | ' +
        'insertfile image media template link anchor codesample code | ltr rtl' +
        'useBrowserSpellcheck'

    return (
        <>

            <div style={UpdateArticleContainer}>
                <div style={{ display: 'inline-flex', height: 'max-content', margin: 'auto' }}>
                    <Form >
                        <Left>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                position:'relative',
                                height: '5rem',
                                borderRadius: '5px',
                                background: 'linear-gradient(313deg, rgb(41, 53, 30), rgb(71 134 13))',
                                color: 'white',
                                marginBottom: '1rem',
                                justifyContent: 'center',
                            }}>
                                <MdUpdate size={50} style={{
                                    position: 'absolute',
                                    right: '5%',
                                    color: '#64974d',
                                }} />
                                <span style={{
                                    fontWeight: 'bold', margin: '0 16px', fontSize: '20px'
                                }}>REPUBLISH ARTICLE</span>
                                <span style={{ fontSize: '10px', margin: '2px 16px', width:'60%' }}>
                                    Don't forget to add the best-matched title for your article!
                                </span>
                            </div>

                            <div style={{
                                display: 'flex', flexDirection: 'column',
                                color: 'white',
                                borderRadius: '5px',
                                padding: '0 10px',
                                paddingTop: '16px',
                                margin: '0 16px',
                                color: '#303030',
                                boxShadow: '0 0px 21px #e0e0e0',
                                maxWidth: '332px',
                                margin: '0 auto',
                            }}>
                                {!thumbnail && <img style={screenSize <= 900 ? minImg : maxImg} src={process.env.REACT_APP_URL_API + '/Thumbnails/' + fetchedThumbnail} alt='thumbnail' />}
                                {thumbnail && <img style={screenSize <= 900 ? minImg : maxImg} src={URL.createObjectURL(thumbnail)} alt='thumbnail' />}

                                <input style={{ ...input, margin: '0 auto', width: '90%', border: 'none', borderBottom: '1px solid #FAED26', borderRadius: '0' }} type='file' onChange={e => setThumbnail(e.target.files[0])} id='thumbnail' />

                                <span style={{
                                    display: 'flex', fontSize: '8px', margin: '8px auto',
                                    width: '90%',
                                    fontFamily: 'Manrope',
                                }}>Only .png, .jpg and .jpeg format allowed!</span>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '24px' }}>
                                <input style={input} type="text" defaultValue={title} placeholder="Title" id="title" />
                                <input style={input} type="text" defaultValue={about} placeholder="About topic" id="about" />
                                <div style={screenSize > 900 ? { ...CountryOriginAndArticleType_div, gridTemplateColumns: '1fr 1fr' } : { ...CountryOriginAndArticleType_div, gridTemplateRows: '1fr 1fr' }}>
                                    <select style={{ ...input }} id='articleType'>
                                        <option value={articleType}>{articleType} (existing)</option>
                                        <option value='tech'>Tech</option>
                                        <option value='web'>Web</option>
                                        <option value='programming'>Programming</option>
                                        <option value='photography'>Photography</option>
                                        <option value='food'>Food</option>
                                        <option value='fitness'>Fitness</option>
                                        <option value='travel'>Travel</option>
                                        <option value='health'>Health</option>
                                        <option value='fashion'>Fashion</option>
                                        <option value='sports'>Sports</option>
                                    </select>
                                </div>

                            </div>
                        </Left>
                        <Right>
                            <Editor
                                apiKey="me91nhwamlrdq769kp9dcmpt4i7lrqodz6eyswsjpxevphss"
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={editorData}
                                init={{
                                    height: '91%',
                                    menubar: true,
                                    statusbar: false,
                                    branding: false,
                                    inline_boundaries: false,
                                    skin: 'fabric',
                                    icons: "thin",
                                    plugins: Plugins,
                                    toolbar: Toolbar,
                                    browser_spellcheck: true,
                                    codesample_global_prismjs: false,
                                    content_style: "@import url('https://fonts.googleapis.com/css2?family=Manrope&family=Dancing+Script:wght@400;500;600;700&family=Dosis:wght@200;300;400;500;600;700&family=Exo+2:wght@300;500;600&family=Inconsolata:wght@200;300;400;500;600;700;800&family=Indie+Flower&family=Lobster+Two:wght@400;700&family=Lora:wght@400;500;600&family=Merriweather:wght@300;400;700;900&family=Mukta:wght@200;300;400;500;600;700&family=Nunito:wght@200;300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&family=Oswald:wght@200;400;500;600;700&family=Playfair+Display:wght@400;500;600;700&family=Raleway:wght@100;200;300;400;500;600&family=Roboto:wght@100;300;400;500;700;900&family=Slabo+27px&family=Teko:wght@300;400;500;600&family=Titillium+Web:wght@200;300;400;600&display=swap');body {font - family:'Manrope', sans-serif;}",
                                    fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 36pt",
                                    font_formats: "Manrope, sans-serif;Dancing Script, cursive;Dosis, sans-serif;Exo 2, sans-serif;Inconsolata, monospace;Indie Flower, cursive;Lobster Two, cursive;Lora, serif;Merriweather, serif;Mukta, sans-serif;Nunito, sans-serif;Open Sans, sans-serif;Oswald, sans- serif;Playfair Display, serif;Raleway, sans-serif;Roboto, sans-serif;Slabo 27px, serif;Teko, sans-serif;Titillium Web, sans-serif;",
                                }}
                            />
                            <div style={{ display: 'flex' }}>
                                <button style={button2} type='submit' onClick={(e) => Submit(e)} className='btnHover' > {loader ? <Loader /> : <> UPDATE ARTICLE </>} </button>
                                <button style={{ ...button2, background: 'hwb(0deg 0% 10%)' }} type='submit' onClick={editPanel} >CANCLE </button>
                            </div>

                        </Right>
                    </Form>
                </div>
                {/* Alert Window  */}
                <div style={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'fixed', left: '0', bottom: '0' }}>
                    {alertCall && <Alert alertMsg={alertMsg} alertType={alertType} AlertMsg={AlertMsg} />}
                </div>
            </div>

        </>
    )
}

export default UpdateArticle;
