import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { applyMiddleware } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { blogsReducer } from './Blogs/blogReducer'
import { alertReducer } from './Alert/alertReducer'

const rootReducer = combineReducers({
    blogs: blogsReducer,
    alert: alertReducer,
})

const store = configureStore({ reducer: rootReducer }, applyMiddleware(thunk))
export default store;