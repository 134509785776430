import React from "react";
import styled, { keyframes } from "styled-components";
import errorImg from '../Images/error.png'
import successImg from '../Images/success.png'
import { IoMdClose } from 'react-icons/io'

// Redux 
import { useDispatch, useSelector } from "react-redux";
import { alertDeactive } from '../Redux'

const breatheAnimation = keyframes`
    0% {
        bottom:-5rem;
     }
    100% { 
        bottom:0;
    }
`

const AlertMSG = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  bottom:-5rem;
  min-width: 22rem;
  color: #e7f3d8;
  background: #354a34;
  padding: 10px 0;
  font-family: Manrope;
  box-shadow: 0 0 5px gray;
  border-radius: 5px;
  animation-name: ${breatheAnimation};
  animation-duration: 0.5s;
  animation-iteration-count: normal;
  animation-fill-mode: forwards;
  animation-delay: 0.2s;
  animation-timing-function: ease;
`

const escDiv = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    fontFamily: 'Manrope',
    position: 'absolute',
    right: '2%',
    cursor: 'pointer',
    color: '#e7f3d8',
}
const span = {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    padding: '0 10px',
    fontSize: '14px',
}

function Alert() {
    const dispatch = useDispatch()
    const alertStatus = useSelector(state => state && state.alert.status)
    const alertMsg = useSelector(state => state && state.alert.msg)
    const AlertMsg = () => {
        dispatch(alertDeactive())
    }
    return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', position: 'fixed', left: '0', bottom: '0', zIndex: +11 }}>
            <AlertMSG>
                <span style={span}>{alertStatus === 'success' ? <img src={successImg} style={{ width: '1.5rem' }} /> : <img src={errorImg} style={{ width: '1.5rem' }} />}<span style={{ margin: '0 10px', marginRight: '24px' }}> {alertMsg} </span></span>
                <div style={escDiv} onClick={() => AlertMsg()}>
                    <IoMdClose size={15} />
                    <span style={{ fontSize: '10px' }}>ESC</span>
                </div>
            </AlertMSG>
        </div>

    )
}
export default Alert;