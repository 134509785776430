import React, { useState } from "react";
import styled from "styled-components";
import ForgotPass from "../components/ForgotPass";
import date from 'date-and-time';
import axios from "axios";
import Loader from '../subComponents/Loader'
import '../App.css'
//icons
import { IoMdClose } from 'react-icons/io'
import { BsBookmarkPlusFill } from 'react-icons/bs'
import { MdTipsAndUpdates } from 'react-icons/md'
// images 
import profile from '../Images/profile.png'
import logo from '../Images/logo.png'
//Redux
import { useDispatch } from "react-redux";
import { alertActive } from '../Redux'
import { alertDeactive } from '../Redux'



const SignupDiv = styled.div`
   display: flex;
   position: fixed;
   height: 100%;
   width: 100%;
   background: #000000c4;
   justify-content: center;
   align-items: center;
   z-index: +4;
   overflow: hidden;
   overflow-y: auto;
   top: 0;
`
const form = {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '20rem',
    background: 'white',
    padding: '20px 12px',
    fontFamily: 'Manrope',
    color: '#102300',
    borderRadius: '4px',
}
const img = {
    width: '75px',
    height: '75px',
    borderRadius: '10rem',
    margin: '0 auto',
}
const spanBtn = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid black',
    background: '#102300',
    color: 'white',
    padding: '9px 2px',
    fontSize: '17px',
    margin: '0 0px',
    width: '51%',
    cursor: 'pointer',
}
const input = {
    border: '1px solid gray',
    padding: '10px',
    margin: '10px 0',
    fontFamily: 'Manrope',
    fontWeight: '400',
    outline: 'none',

}
const button = {
    display: 'flex',
    justifyContent: 'center',
    padding: '8px',
    background: '#102300',
    color: 'white',
    fontSize: '16px',
    margin: '10px 0',
    cursor: 'pointer',
    fontFamily: 'Manrope',
    fontWeight: 'bold',
    letterSpacing: '0.5px',
    border: 'none',
}
function Signup(props) {
    const [signupForm, setsignupForm] = useState(false)
    const [loginForm, setLoginForm] = useState(true)
    const [forgotPass, setforgotPass] = useState(false)

    const [loader, setLoader] = useState(false);

    const createAccount = () => {
        props.createAccount(false);
    }
    const signup = () => {
        setsignupForm(true)
        setLoginForm(false)
        setforgotPass(false)
    }
    const login = () => {
        setsignupForm(false)
        setLoginForm(true)
        setforgotPass(false)
    }

    const forgotpass = () => {
        setsignupForm(false)
        setLoginForm(false)
        setforgotPass(true)
    }


    // SignupUser form submission 
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [dailyNewsLetter, setDailyNewsLetter] = useState(true);

    const [alertCall, setAlertCall] = useState(false);
    const [emailValidation, setEmailValidation] = useState(false)
    const [vCode, setVcode] = useState('');
    const dispatch = useDispatch()

    const EmailVarify = (e) => {
        e.preventDefault();
        setLoader(true)
        axios.post(process.env.REACT_APP_URL_API + '/account/verify_email/' + email)
            .then(res => {
                setEmailValidation(true) // Confrimation (OTP window)
                setLoader(false)
                dispatch(alertActive('success', res.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 2000)
            })
            .catch(err => {
                setLoader(false)
                dispatch(alertActive('error', err.response.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)
            })
    }

    const signupUser = (e) => {
        e.preventDefault();
        setLoader(true)
        const now = new Date();
        axios.post(process.env.REACT_APP_URL_API + '/account/signup', {
            email: email,
            password: password,
            dailynewsletter: dailyNewsLetter,
            vCode: vCode,
            date: date.format(now, 'DD MMM YYYY'),
        })
            .then((res) => {
                setLoader(false)

                dispatch(alertActive('success', res.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 2000)
                setTimeout(() => {
                    window.location.href = '/'
                }, 2000)
            })
            .catch((err) => {
                setLoader(false)
                dispatch(alertActive('error', err.response.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 3000)

            })
    }

    // User Login Form
    const userlogin = (e) => {
        e.preventDefault();
        setLoader(true)
        axios.post(process.env.REACT_APP_URL_API + '/account/login', {
            email: email,
            password: password
        })
            .then((response) => {
                setLoader(false)
                sessionStorage.setItem('username', response.data.result.Username)
                sessionStorage.setItem('token', response.data.token)
                sessionStorage.setItem('session', 'active')
                window.location.reload();
            })
            .catch((err) => {
                setLoader(false)
                dispatch(alertActive('error', err.response.data.result))
                setTimeout(() => {
                    dispatch(alertDeactive())
                }, 4000)
            })
    }

    // SHOW OR HIDE PASSWORD 
    const showPassword = (e) => {
        if (e.target.checked) {
            document.getElementById('password').type = 'text';
        } else {
            document.getElementById('password').type = 'password';
        }
    }
    const showLoginPassword = (e) => {
        if (e.target.checked) {
            document.getElementById('login-password').type = 'text';
        } else {
            document.getElementById('login-password').type = 'password';
        }
    }


    return (
        <>
            <SignupDiv>
                {signupForm ?
                    <div>
                        {emailValidation ? '' :
                            <form style={form} onSubmit={EmailVarify}>
                                <img style={img} src={profile} alt="profile" />
                                <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem 0', width: '100%' }}>
                                    <span style={{ ...spanBtn, color: 'black', background: 'white' }} onClick={() => login()}>Login</span>
                                    <span style={spanBtn}>Signup</span>
                                </div>
                                <span style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontSize: '11px',
                                    margin: '10px 0px'
                                }}>Create an account and get the latest updates on techbitlife.<MdTipsAndUpdates size={17} style={{ margin: '0 5px' }} /></span>
                                <input style={input} name='email' type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                <span style={{ display: 'flex', width: '100%', position: 'relative', alignItems: 'center' }}>
                                    <input id='password' style={{ ...input, width: '100%' }} type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                                    <input style={{ position: 'absolute', right: '1%' }} type='checkbox' onChange={showPassword} />
                                </span>
                                <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                                    <span style={{ fontSize: '12px' }}> Assign me to the daily newsletter. </span>
                                    <input type="checkbox" defaultChecked onChange={(e) => setDailyNewsLetter(e.target.checked)} />
                                </div>
                                <button style={button} type='submit'>{loader ? <Loader /> : <>Signup</>}</button>
                                <div style={{
                                    display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '12px'
                                }}> Do you already have an account? <span style={{ margin: '0 5px', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => login()}>Login now</span></div>
                                <IoMdClose size={20} onClick={() => createAccount()} style={{
                                    position: 'absolute',
                                    right: '2%',
                                    top: '2%',
                                    cursor: 'pointer',
                                    color: '#102300',
                                }} />
                            </form>}
                        {emailValidation &&
                            <form style={form} onSubmit={signupUser}>
                                <img style={{
                                    width: '40px',
                                    height: '39px',
                                    borderRadius: '10rem',
                                    margin: '0px auto',
                                    position: 'absolute',
                                    right: '3%',
                                    top: '3%',
                                }} src={profile} alt="profile" />
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img src={logo} style={{ width: '16px', height: '16px' }} />
                                    <h3 style={{ marginLeft: '8px', fontSize: '16px' }}>TechBitLife</h3>
                                </div>
                                <h3 style={{ margin: '0px', marginTop: '16px' }}>Verify your email</h3>
                                <span style={{ fontSize: '10px' }}>an OTP has been sent to your email address.</span>
                                <input type='text' placeholder='Enter OTP' style={{ ...input, marginTop: '24px' }} onChange={(e) => setVcode(e.target.value)} />
                                <button type="submit" style={button}> {loader ? <Loader /> : <>Proceed</>}</button>
                            </form>
                        }
                    </div>

                    : ''
                }
                {loginForm ?
                    <form style={form}>
                        <img style={img} src={profile} alt="profile" />
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '1rem 0', width: '100%' }}>
                            <span style={spanBtn}>Login</span>
                            <span style={{ ...spanBtn, color: 'black', background: 'white' }} onClick={() => signup()}>Signup</span>
                        </div>
                        <span style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '10px 0',
                            fontSize: '12px',
                        }}>You can access your favourites by logging into your account!<BsBookmarkPlusFill size={16} style={{ margin: '0 10px' }} /></span>
                        <input style={input} name='email' type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                        <span style={{ display: 'flex', width: '100%', position: 'relative', alignItems: 'center' }}>
                            <input id='login-password' style={{ ...input, width: '100%' }} type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                            <input style={{ position: 'absolute', right: '1%' }} type='checkbox' onChange={showLoginPassword} />
                        </span>
                        <span style={{
                            fontSize: '12px',
                            fontWeight: 'bold',
                            marginBottom: '1rem',
                            cursor: 'pointer',
                        }} onClick={() => forgotpass()}>forgot password ? </span>

                        <button style={button} onClick={userlogin}>{loader ? <Loader /> : <>Login</>}</button>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '12px'
                        }}>Not a member ?  <span style={{ margin: '0 5px', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => signup()}>Signup now</span></div>
                        <IoMdClose size={20} onClick={() => createAccount()} style={{
                            position: 'absolute',
                            right: '2%',
                            top: '2%',
                            cursor: 'pointer',
                            color: '#102300',
                        }} />
                    </form> : ''}
                {/* FORGOT PASSWORD FORM  */}
                {forgotPass ? <ForgotPass createAccount={createAccount} /> : ''}
            </SignupDiv>
        </>
    )
}

export default Signup;